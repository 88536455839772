import { jsx as _jsx } from "react/jsx-runtime";
import { AlertBlock, AlertBlockError, AlertBlockSuccess, AlertBlockWarning } from '@klarna/bubble-ui';
import { useCallback, useEffect, useState } from 'react';
import { FadingDiv } from './styled';
const getAlertBlockByType = (type) => {
    switch (type) {
        case 'error':
            return AlertBlockError;
        case 'warning':
            return AlertBlockWarning;
        case 'plain':
            return AlertBlock;
        case 'success':
        default:
            return AlertBlockSuccess;
    }
};
/** Transition length in ms */
const TRANSITION_MS = 500;
const Toast = ({ toastId, title, message, iconType, listItems, type = 'success', autoHide = true, hideOnClick = true, linkLabel, onLinkClick, onRemoved, onHidden = () => null, hideAfterMs = 5000 }) => {
    const [isHidden, setHidden] = useState(false);
    const [isDismissed, setDismissed] = useState(false);
    const AlertBlockComponent = getAlertBlockByType(type);
    const onHide = useCallback(() => setHidden(true), []);
    const onDismiss = useCallback(() => setDismissed(true), []);
    const onClickToast = useCallback(() => {
        if (hideOnClick) {
            onDismiss();
        }
    }, [hideOnClick, onDismiss]);
    // Add transition to visiblity duration
    const visibleMs = hideAfterMs + TRANSITION_MS;
    // Trigger disssmisal automatically
    useEffect(() => {
        if (autoHide) {
            const timer = setTimeout(onDismiss, visibleMs);
            return () => clearTimeout(timer);
        }
    }, [onDismiss, autoHide, visibleMs]);
    // Hide completely after transition
    useEffect(() => {
        if (isDismissed) {
            const timer = setTimeout(onHide, TRANSITION_MS);
            return () => clearTimeout(timer);
        }
    }, [onHide, isDismissed]);
    useEffect(() => {
        if (isHidden) {
            onHidden();
            onRemoved(toastId);
        }
    }, [toastId, isHidden, onRemoved, onHidden]);
    // Hide completely after transition
    if (isHidden) {
        return null;
    }
    return (_jsx(FadingDiv, { hideOnClick: hideOnClick, onClick: onClickToast, fadeOut: isDismissed, durationMs: TRANSITION_MS, "data-testid": "mpui-toast-notification", children: _jsx(AlertBlockComponent, { title: title, iconType: iconType, listItems: listItems, content: [].concat(message), linkLabel: linkLabel, onLinkClick: onLinkClick }) }));
};
export default Toast;
