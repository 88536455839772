interface UserCreationResponse {
  data: { uuid: string }
}
export interface FreschatUserResponse {
  success: boolean
  data: { alias: string }
}
interface FreshchatWidget {
  init: (opts: any) => void,
  isLoaded: () => boolean,
  open: () => void,
  setTags: (tags:string[]) => void,
  on: (evt: string, cb: (res: any) => void) => void,
  off: (evt: string) => void,
  user: {
    setProperties: (props: any) => void
    getUUID: () => Promise<UserCreationResponse>
    get: (next: (resp: FreschatUserResponse) => void) => void
  },
  destroy: () => void
  authenticate: (token: any) => void
}

// @ts-ignore
export const isFreschatWidgetReady = (): boolean => Boolean(window.fcWidget)

// @ts-ignore
// Must be called only if isFreschatWidgetReady() return true
export const getFreshchatWidget = (): FreshchatWidget => window.fcWidget
