import frontendEvents from '@klarna/frontend-events';
import { getConfig } from '../config';
import filteredEventCategories from './filteredEventCategories';
const getEnvironment = () => {
    const { region, type: environment } = getConfig('environment', {});
    return { region, environment };
};
const stateSingleton = (() => {
    let state = {};
    function getUserId() {
        return state === null || state === void 0 ? void 0 : state.userId;
    }
    function setUserId(userId) {
        state = Object.assign(Object.assign({}, state), { userId: userId });
    }
    function getLoginSource() {
        return state === null || state === void 0 ? void 0 : state.loginSource;
    }
    function setLoginSource(loginSource) {
        state = Object.assign(Object.assign({}, state), { loginSource });
    }
    function getRealm() {
        return state === null || state === void 0 ? void 0 : state.realm;
    }
    function setRealm(realm) {
        state = Object.assign(Object.assign({}, state), { realm });
    }
    return {
        getUserId,
        setUserId,
        getRealm,
        setRealm,
        getLoginSource,
        setLoginSource
    };
})();
const tracker = frontendEvents({
    client: 'merchant-portal',
    // TODO use git tag or commit hash
    clientVersion: '0.0.1',
    // eslint-disable-next-line n/no-unsupported-features/node-builtins
    sessionId: sessionStorage.getItem('mage-app.sessionId') || ''
});
const getAdditionalEventData = () => {
    return Object.assign({ userId: stateSingleton.getUserId(), realm: stateSingleton.getRealm(), isDeepLinkUser: typeof stateSingleton.getLoginSource() !== 'undefined', 
        // eslint-disable-next-line n/no-unsupported-features/node-builtins
        currentMid: sessionStorage.getItem('mid') || '' }, getEnvironment());
};
export const pageview = (pathname = '/', search = '', title) => {
    if (pathname === null || pathname === void 0 ? void 0 : pathname.startsWith('/boost/')) {
        tracker.event('pageview', Object.assign({ pathname, search, title }, getAdditionalEventData()));
    }
};
export const events = (eventData) => {
    if (filteredEventCategories.includes(eventData.category)) {
        tracker.event('event', Object.assign(Object.assign(Object.assign({}, eventData), getAdditionalEventData()), { 
            // eslint-disable-next-line n/no-unsupported-features/node-builtins
            sessionId: sessionStorage.getItem('mage-app.sessionId') || '' }));
    }
};
export function setUser(userData, tokenHelper) {
    stateSingleton.setUserId(userData === null || userData === void 0 ? void 0 : userData.user_id);
    stateSingleton.setLoginSource(tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getLoginSource());
}
export const setRealm = stateSingleton.setRealm;
