import { jsx as _jsx } from "react/jsx-runtime";
import { ColoredIllustrationProblem, ColoredIllustrationSecurity, ColoredIllustrationSuccess } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
export const IllustrationTypes = PropTypes.oneOf(['problem', 'success', 'security']);
Illustration.propTypes = {
    type: IllustrationTypes
};
function Illustration({ type }) {
    switch (type) {
        case 'problem':
            return _jsx(ColoredIllustrationProblem, {});
        case 'success':
            return _jsx(ColoredIllustrationSuccess, {});
        case 'security':
            return _jsx(ColoredIllustrationSecurity, {});
    }
}
export default Illustration;
