import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { useWhenClickOutside } from '../../hooks';
import HeaderMenuButton from '../HeaderMenuButton';
import Menu from './Menu';
import UserMenuDialogSwitcher, { types as UserMenuDialogSwitcherTypes } from './UserMenuDialogSwitcher';
import UserMenuIcon from './UserMenuIcon';
const menuStyles = {
    right: 0,
    position: 'absolute',
    zIndex: 101
};
function UserMenu({ onLogoutClick }) {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);
    const [dialog, setDialog] = useState(null);
    const ref = useRef();
    const preventScroll = () => {
        document.body.style.overflow = 'hidden';
        window.scrollTo({ top: 0, left: 0 });
    };
    const openLanguageDialog = useCallback(() => {
        preventScroll();
        setDialog(UserMenuDialogSwitcherTypes.language);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserMenuDialogSwitcherTypes.language]);
    const openProfileDialog = useCallback(() => {
        preventScroll();
        setDialog(UserMenuDialogSwitcherTypes.profile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserMenuDialogSwitcherTypes.language]);
    const hideUserMenu = useCallback(() => setShow(false), []);
    const clearDialog = useCallback(() => {
        document.body.style.overflow = 'unset';
        setDialog(null);
    }, []);
    useWhenClickOutside(ref, hideUserMenu);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { ref: ref, style: { position: 'relative', maxHeight: '40px' }, children: [_jsx(HeaderMenuButton, { id: "header-usermenu-button", onClick: toggle, children: _jsx(UserMenuIcon, { isTooltipEnabled: !show, toggled: show }) }), show && (_jsx("div", { style: menuStyles, children: _jsx(Menu, { onLogoutClick: onLogoutClick, onLanguageClick: openLanguageDialog, onProfileClick: openProfileDialog }) }))] }), _jsx(UserMenuDialogSwitcher, { type: dialog, onChooseDialog: hideUserMenu, onClose: clearDialog })] }));
}
UserMenu.propTypes = {
    onLogoutClick: PropTypes.func.isRequired
};
export default UserMenu;
