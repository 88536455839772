import React from 'react'
import { Radio } from '@klarna/bubble-ui'
import { hooks } from 'mage-i18n'
import { useChannelDataState } from '../../context/ChannelDataStateProvider'
import CountryFlag from '../Chat/CountryFlag'
import MarketChannels from './MarketChannels'
import { Market } from '../SupportHelpBot/types'

const getMarketOptions = (markets: Market[], t) =>
  markets.map(market => ({
    label: t(`core.countries.${market.countryCode.toLowerCase()}`),
    value: market.countryCode,
    aside: <CountryFlag countryCode={market.countryCode} />,
    content: <MarketChannels marketCountryCode={market.countryCode} />
  }))

const ChannelSelector = () => {
  const { data: { markets } } = useChannelDataState()
  const t = hooks.useTranslator()

  return (
    <Radio options={getMarketOptions(markets, t)} />
  )
}

export default ChannelSelector
