import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './CodeSnippet.css';
import { ButtonQuaternary } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import Clipboard from 'react-clipboard.js';
import Code from './Code';
function CodeSnippet({ code, options = {
    mode: 'htmlmixed',
    lineNumbers: true,
    readOnly: true,
    viewportMargin: Infinity,
    lineWrapping: true
} }) {
    const [copied, setCopied] = React.useState(false);
    const t = i18nHooks.useTranslator();
    const onSuccess = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };
    return (_jsxs("div", { className: "code-snippet", children: [_jsx(Code, { code: code, options: {
                    mode: (options === null || options === void 0 ? void 0 : options.mode) || 'htmlmixed',
                    lineNumbers: !!(options === null || options === void 0 ? void 0 : options.lineNumbers),
                    readOnly: !!(options === null || options === void 0 ? void 0 : options.readOnly),
                    viewportMargin: (options === null || options === void 0 ? void 0 : options.viewportMargin) || Infinity,
                    lineWrapping: !!(options === null || options === void 0 ? void 0 : options.lineWrapping)
                } }), _jsx("div", { className: "button", children: _jsx(Clipboard, { component: "span", "data-clipboard-text": code, onSuccess: onSuccess, children: _jsx(ButtonQuaternary, { size: "small", children: copied
                            ? t('core.codeSnippet.clipboardButtonLabelSuccess')
                            : t('core.codeSnippet.clipboardButtonLabel') }) }) })] }));
}
export default CodeSnippet;
