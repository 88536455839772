import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { KlarnaLogoFull } from '@klarna/bubble-ui';
import { Flex, useGetToken } from '@klarna/mp-ui';
import React from 'react';
import styled, { css } from 'styled-components';
import { useDesktop, useTablet } from '../../../hooks/useMediaQuery';
import { zIndex } from '../constants';
import CustomActionClose from './customActionClose';
import PartnerLogo from './PartnerLogo';
const TopBarContainerStyled = styled.div(() => {
    const tabletMediaQuery = useTablet();
    const desktopMediaQuery = useDesktop();
    const height = useGetToken('space/800').value();
    const horizontalPaddingMobile = useGetToken('space/200').value();
    const horizontalPadding = useGetToken('space/300').value();
    return css({
        display: 'flex',
        gridArea: 'topbar',
        height,
        padding: `0 ${horizontalPaddingMobile}px`,
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: zIndex.topbar,
        [tabletMediaQuery]: {
            padding: `0 ${horizontalPadding}px`,
            gridArea: 'topbar/topbar/topbar/panel'
        },
        [desktopMediaQuery]: {
            gridArea: 'topbar'
        }
    });
});
const LogoContainer = styled.div(({ showDistributionPartnerLogo, showOnMobile, isVisible }) => {
    const tabletMediaQuery = useTablet();
    const logoHeight = useGetToken('space/200').value();
    return css(Object.assign(Object.assign({ visibility: showOnMobile && isVisible ? 'visible' : 'hidden', maxHeight: logoHeight }, (showDistributionPartnerLogo && {
        display: 'flex',
        alignItems: 'baseline',
        gap: '8px'
    })), { [tabletMediaQuery]: {
            visibility: isVisible ? 'visible' : 'hidden'
        } }));
});
const CollaborationMarkerAndPartnerLogo = ({ distributionPartner }) => {
    return distributionPartner && distributionPartner.logo_url ? (_jsxs(_Fragment, { children: [_jsx(CustomActionClose, {}), _jsx(PartnerLogo, { src: distributionPartner.logo_url, alt: distributionPartner.name, redirectUrl: distributionPartner.redirect_url })] })) : null;
};
function TopBarLogo({ distributionPartner, showOnMobile }) {
    return (_jsxs(LogoContainer, { showDistributionPartnerLogo: !!distributionPartner, showOnMobile: showOnMobile, isVisible: distributionPartner !== undefined, children: [_jsx(KlarnaLogoFull, { UNSAFE_svgProps: { 'data-testid': 'mpui-fw-topbar-klarna-logo' } }), _jsx(CollaborationMarkerAndPartnerLogo, { distributionPartner: distributionPartner })] }));
}
const TopBar = ({ id, actionButtons = [], distributionPartner, showLogoOnMobile } = {}) => {
    return (_jsxs(TopBarContainerStyled, { id: id, "data-testid": "header-appsmenu-button", children: [_jsx(TopBarLogo, { distributionPartner: distributionPartner, showOnMobile: showLogoOnMobile }), _jsx(Flex, { spacing: "space/200", children: actionButtons.map((button, index) => (_jsx(React.Fragment, { children: button }, `mpui-fw-topbar-action-button-${index}`))) })] }));
};
export default TopBar;
// Allows storybook to get props
export const TopBarPropsDoc = () => null;
