import { hooks } from 'mage-i18n'
import React from 'react'
// @ts-ignore
import { View, Typography } from '@klarna/bubble-ui'

import { AnswerOption } from '../types'
import { useHelpbotState } from '../contexts/HelpbotStateProvider'

type HelpbotOptionProps = {
  option: AnswerOption
}

const styles = {
  optionContainer: {
    marginBottom: '10px',
    alignItems: 'flex-end'
  },
  optionChip: {
    border: '1px solid rgb(227, 229, 233)',
    borderRadius: '9999px',
    padding: '9px 15px',
    width: 'fit-content',
    cursor: 'pointer'
  }
}

const HelpbotOption: React.FC<HelpbotOptionProps> =
  ({ option: { value } }: HelpbotOptionProps) => {
    const { handleAnswerForActiveStep } = useHelpbotState()
    const t = hooks.useTranslator()
    const [hovered, setHovered] = React.useState(false)

    const handleOnPress = () => {
      handleAnswerForActiveStep(value)
    }
    const chipCustomStyle = hovered ? { backgroundColor: 'rgb(241, 241, 241)' } : {}

    return (
      <View style={styles.optionContainer}>
        <View
          style={{ ...styles.optionChip, ...chipCustomStyle }}
          onClick={handleOnPress}
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          <Typography textToken='text-style/text/paragraphs/body/regular'>
            {t(value)}
          </Typography>
        </View>
      </View>
    )
  }

export { HelpbotOption }
