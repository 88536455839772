import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColoredIllustrationAutomaticPayments, ColoredIllustrationAutomaticUpdates, Typography } from '@klarna/bubble-ui';
import { Modal } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import insights, { TrackImpression } from 'mage-insights';
import ModalButton from './ModalButton';
function wrapHandlerInWithEventTracking(handler, event) {
    return () => {
        insights.event(event);
        handler();
    };
}
const IntegrationTypeModal = ({ productName, isVisible, onClose, onPluginType, onWebSdkType }) => {
    const t = i18nHooks.useTranslator();
    const event = {
        category: 'integration-type-modal',
        action: 'impression'
    };
    const closeHandler = wrapHandlerInWithEventTracking(onClose, {
        category: 'integration-type-modal/close',
        action: 'click'
    });
    const pluginHandler = wrapHandlerInWithEventTracking(onPluginType, {
        category: 'integration-type-modal/platform-plugin',
        action: 'click'
    });
    const webSdkHandler = wrapHandlerInWithEventTracking(onWebSdkType, {
        category: 'integration-type-modal/direct-integration',
        action: 'click'
    });
    if (!isVisible)
        return null;
    return (_jsx(TrackImpression, { event: event, children: _jsxs(Modal, { onClose: closeHandler, children: [_jsx(Typography, { textToken: "text-style/headings/titles/bold/huge", children: t('boost.integrationTypeModal.title', { productName }) }), _jsx(ModalButton, { onClick: pluginHandler, label: t('boost.integrationTypeModal.labels.plugin'), children: _jsx(ColoredIllustrationAutomaticUpdates, { size: 50 }) }), _jsx(ModalButton, { onClick: webSdkHandler, label: t('boost.integrationTypeModal.labels.web'), children: _jsx(ColoredIllustrationAutomaticPayments, { size: 50 }) })] }) }));
};
export default IntegrationTypeModal;
