import * as R from 'ramda';
import Cookies from 'universal-cookie';
const getCookies = R.memoizeWith(() => '', () => {
    return new Cookies(document.cookie, {
        secure: true
    });
});
const getConsentGroups = ({ cookies = getCookies() } = {}) => {
    const consentGroups = {};
    const optanonConsentCookie = cookies.get('OptanonConsent') || '';
    const match = optanonConsentCookie.match(/groups=([\w:,]*)/);
    if (match) {
        const groupStrings = match[1].split(',').filter(Boolean);
        for (const groupString of groupStrings) {
            const [key, value] = groupString.split(':');
            consentGroups[key] = value === '1';
        }
    }
    return consentGroups;
};
const hasCookieConsentBeenGiven = ({ cookies = getCookies() } = {}) => cookies.get('OptanonAlertBoxClosed') !== undefined;
const hasPerformanceCookiesConsent = ({ cookies = getCookies() } = {}) => {
    const optanonConsentCookie = cookies.get('OptanonConsent') || '';
    return /groups=[\w:,]*C0002:1/.test(optanonConsentCookie);
};
export { getConsentGroups, getCookies, hasCookieConsentBeenGiven, hasPerformanceCookiesConsent };
