import debounce from 'lodash.debounce';
import { selectors as configSelectors } from 'mage-config';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import { pick } from 'ramda';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { event } from '../insights';
export default function useAnalytics({ category, action, label = '', trackOnMount = false, skip = false, debounce: debounceTimeout = 0 }) {
    const app = useSelector(coreSelectors.clientId);
    const region = useSelector(configSelectors.region);
    const sessionId = useSelector(coreSelectors.sessionId);
    const isMerchantsRealm = useSelector(coreSelectors.isMerchantsRealm);
    const realm = useSelector(coreSelectors.keycloakRealm);
    const lang = i18nHooks.useLanguage();
    const trackWithGoogleAnalytics = useCallback(extraData => event(Object.assign({ customData: {
            user_language: lang,
            user_realm: realm
        }, category,
        action,
        label }, extraData)), [action, category, label, lang, realm]);
    const submitDataDebounced = useMemo(() => (debounceTimeout === 0 ? submitData : debounce(submitData, debounceTimeout)), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounceTimeout, submitData]);
    useEffect(() => {
        if (trackOnMount) {
            track();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackOnMount, skip, label]);
    return track;
    function track(extraData = {}) {
        const data = Object.assign({ eventAt: new Date(), app, path: window.location.pathname, region,
            lang,
            sessionId,
            category,
            action,
            label }, extraData);
        return submitDataDebounced(trackWithGoogleAnalytics, data, isMerchantsRealm, skip);
    }
}
function submitData(trackWithGoogleAnalytics, data, isMerchantsRealm, skip) {
    if (isMerchantsRealm && !skip) {
        const analyticsData = pick(['category', 'action', 'label'], data);
        trackWithGoogleAnalytics(analyticsData);
    }
}
