import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useLabelTranslation from '../hooks/useLabelTranslation';
ReadOnlyField.propTypes = {
    name: PropTypes.string.isRequired
};
export default function ReadOnlyField(_a) {
    var { name } = _a, props = __rest(_a, ["name"]);
    const label = useLabelTranslation(name);
    const [field] = useField(name);
    return (_jsxs("div", Object.assign({ "data-testid": `settings.readonlyfield.${name}` }, props, { children: [_jsx(Typography, { textToken: "text-style/text/labels/default/regular", colorToken: "colors/text/neutral", children: label }), _jsx(Typography, { textToken: "text-style/text/paragraphs/body/medium", children: field.value }), _jsx(SpacerVertical, { spaceToken: "space/100" })] })));
}
