import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loader, SpacerVertical } from '@klarna/bubble-ui';
import { selectors as coreSelectors } from 'mage-core';
import { node } from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthenticationContext } from '../AuthenticationContext';
import CenteredContent from '../CenteredContent';
AuthenticationRequired.propTypes = { children: node };
function AuthenticationRequired({ children }) {
    const isAuthenticated = useSelector(coreSelectors.isAuthenticated);
    const { setAuthenticationRequired, isAuthenticationRequired } = useAuthenticationContext();
    useEffect(() => {
        if (!isAuthenticationRequired) {
            setAuthenticationRequired(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticationRequired]);
    return isAuthenticated ? (children) : (_jsxs(_Fragment, { children: [_jsx(SpacerVertical, { large: true }), _jsx(CenteredContent, { illustration: _jsx(Loader, {}) })] }));
}
export default AuthenticationRequired;
