import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Dropdown } from '@klarna/bubble-ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useLabelTranslation from '../hooks/useLabelTranslation';
import useValidationErrorLabel from '../hooks/useValidationErrorLabel';
import formatErrorLabel from '../utils/formatErrorLabel';
SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default function SelectField(_a) {
    var { name, options } = _a, props = __rest(_a, ["name", "options"]);
    const label = useLabelTranslation(name);
    const errorMessage = useValidationErrorLabel(name);
    const [field, , { setValue }] = useField(name);
    const optionsWithBlank = [{ key: '', hidden: true }, ...options];
    return (_jsx(Dropdown, Object.assign({}, field, props, { error: errorMessage !== undefined, label: errorMessage ? formatErrorLabel(label, errorMessage) : label, onChange: setValue, options: optionsWithBlank })));
}
