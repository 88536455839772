import MarketSelector from '../../components/MarketSelector'
import { HelpbotFlow, BotNodeId, UxEvent } from '../../types'
import * as BotMessages from '../botMessages'
import disputesFlow from './disputesFlow'
import ordersFlow from './ordersFlow'
import settlementsFlow from './settlementsFlow'

const newHelpFlow: HelpbotFlow = [
  {
    id: BotNodeId.WHAT_HELP_WITH,
    botMessage: BotMessages.WhatDoYouNeedHelpWith,
    options: () => [
      {
        value: 'helpbot.options.orders',
        next: BotNodeId.ORDERS
      },
      {
        value: 'helpbot.options.settlements',
        next: BotNodeId.SETTLEMENTS
      },
      {
        value: 'helpbot.options.disputes',
        next: BotNodeId.DISPUTES
      },
      {
        value: 'helpbot.options.otherIssue',
        next: UxEvent.GO_TO_CHANNEL_SELECTION
      }
    ]
  },
  {
    id: BotNodeId.THAT_HELPED,
    botMessage: BotMessages.ThatHelpedBotMessage,
    options: () => []
  },
  {
    id: UxEvent.GO_TO_CHANNEL_SELECTION,
    botMessage: BotMessages.SelectSupportChannelBotMessage,
    OptionsComponent: MarketSelector,
    options: () => []
  },
  ...ordersFlow,
  ...settlementsFlow,
  ...disputesFlow
]

export default newHelpFlow
