import { usePageContext } from '@merchant-portal/framework'
import { hooks as i18nHooks } from 'mage-i18n'
import * as PropTypes from 'prop-types'
import * as React from 'react'

const StaticAppWrapper = ({ id, children, document }) => {
  const { setPageContext = () => {} } = usePageContext()
  const t = i18nHooks.useTranslator()

  document.title = t(`static-pages.${id}.pageTitle`)

  React.useEffect(() => {
    setPageContext({
      title: t(`static-pages.${id}.pageTitle`)
    })
    document.title = t(`static-pages.${id}.pageTitle`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  return <div id={`static-pages_${id}`}>{children}</div>
}

StaticAppWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  document: PropTypes.shape({
    title: PropTypes.string
  })
}

StaticAppWrapper.defaultProps = {
  document: window.document
}

export default StaticAppWrapper
