import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { calculateSpaceWithFactor, getValueFromObject, getValueWithBreakpointFallback } from './utils/responsive';
import { withResponsive } from './utils/withResponsive';
const getVerticalRhythmStyles = ({ breakpoint, display: _display, spacing }) => {
    const display = getValueFromObject(_display, breakpoint, 'flex');
    const spaceFactor = getValueWithBreakpointFallback(spacing, breakpoint, 2);
    const height = calculateSpaceWithFactor(spaceFactor);
    return {
        display,
        height
    };
};
const VerticalRhythmContainer = ({ breakpoint, display, spacing }) => {
    return (_jsx("div", { className: "mp-grid-vertical-rhythm", style: getVerticalRhythmStyles({
            breakpoint,
            display,
            spacing
        }) }));
};
VerticalRhythmContainer.propTypes = {
    breakpoint: PropTypes.string.isRequired,
    display: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    spacing: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};
export const VerticalRhythm = withResponsive(VerticalRhythmContainer);
