import { jsx as _jsx } from "react/jsx-runtime";
import { IconAppLauncher, IconAssets, IconAssistant, IconDebt, IconDelivery, IconEdit, IconGroceries, IconGuidebook, IconKlarnaK, IconOrder, IconOther, IconProfile, IconProfileAlternative, IconSettings, IconSpeechBubble, IconStores, IconSustainability, IconTransfers, Typography } from '@klarna/bubble-ui';
import { constants as commonConstants } from 'mage-common';
import PropTypes from 'prop-types';
const { CLIENT_IDS } = commonConstants;
function renderIcon(clientId, color) {
    switch (clientId) {
        case CLIENT_IDS.NKO_APP:
            return _jsx(IconKlarnaK, { color: color });
        case CLIENT_IDS.ORDERS:
        case CLIENT_IDS.NKO_ORDERS:
            return _jsx(IconOrder, { color: color });
        case CLIENT_IDS.SETTLEMENTS:
        case CLIENT_IDS.NKO_SETTLEMENTS:
            return _jsx(IconAssets, { color: color });
        case CLIENT_IDS.LOGS:
            return _jsx(IconTransfers, { color: color });
        case CLIENT_IDS.USERS:
            return _jsx(IconProfile, { color: color });
        case CLIENT_IDS.SETTINGS:
        case CLIENT_IDS.NKO_SETTINGS:
            return _jsx(IconSettings, { color: color });
        case CLIENT_IDS.SELLER:
            return _jsx(IconStores, { color: color });
        case CLIENT_IDS.UPSTREAM:
            return _jsx(IconSpeechBubble, { color: color });
        case CLIENT_IDS.HOME:
            return _jsx(IconAppLauncher, { color: color });
        case CLIENT_IDS.LENDING:
            return _jsx(IconSustainability, { color: color });
        case CLIENT_IDS.BRANDING:
            return _jsx(IconOther, { color: color });
        case CLIENT_IDS.PRODUCTS:
            return _jsx(IconKlarnaK, { color: color });
        case CLIENT_IDS.INTEGRATION_GUIDES:
            return _jsx(IconGuidebook, { color: color });
        case CLIENT_IDS.DISPUTES:
            return _jsx(IconDebt, { color: color });
        case CLIENT_IDS.LOGISTICS:
            return _jsx(IconDelivery, { color: color });
        case CLIENT_IDS.SUPPORT:
            return _jsx(IconAssistant, { color: color });
        case CLIENT_IDS.KEC:
            return _jsx(IconGroceries, { color: color });
        case CLIENT_IDS.SIWK:
            return _jsx(IconProfileAlternative, { color: color });
        case CLIENT_IDS.SELF_SERVICE:
            return _jsx(IconEdit, { color: color });
        default:
            return clientId ? (_jsx("div", { style: { position: 'relative', top: -3, opacity: 0.7 }, children: _jsx(Typography, { textToken: "text-style/headings/titles/bold/primary", textAlignToken: "text-alignment/center", children: clientId.substr(0, 1).toLocaleLowerCase() }) })) : null;
    }
}
export function AppIcon({ clientId, color }) {
    return _jsx("div", { children: renderIcon(clientId, color) });
}
AppIcon.defaultProps = {
    color: 'black'
};
AppIcon.propTypes = {
    clientId: PropTypes.oneOf(Object.values(CLIENT_IDS)).isRequired,
    color: PropTypes.string
};
export default AppIcon;
