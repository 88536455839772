"use strict";
module.exports = {
    apps: {
        'branding-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'branding',
            category: 'Preferences',
            enabled: true,
            icon: 'IconOther',
            tabs: ['boosters'],
            shouldMakeTabVisible: true,
            sortOrder: 5,
            url: '/branding',
            requires2fa: false
        },
        'disputes-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'disputes',
            category: 'For your day-to-day',
            enabled: true,
            icon: 'IconDebt',
            shouldMakeTabVisible: true,
            tabs: ['store-manager'],
            sortOrder: 4,
            url: '/disputes',
            requires2fa: false
        },
        'home-fe': {
            backendName: 'users',
            category: '',
            config: {
                insights: {
                    mParticle: {
                        enabled: false
                    }
                }
            },
            enabled: true,
            icon: 'IconHome',
            sortOrder: -1,
            url: '/',
            requires2fa: false
        },
        'integration-guides-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'integration-guides',
            enabled: true,
            icon: 'IconGuidebook',
            sortOrder: 11,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/integration-guides',
            requires2fa: false
        },
        'klarna-docs': {
            enabled: true,
            icon: 'IconKeyboard',
            isBottomItem: true,
            isExternalLink: true,
            sortOrder: 9,
            tabs: ['boosters', 'growth', 'store-manager'],
            url: 'https://docs.klarna.com/?utm_source=Merchant%20Portal&utm_medium=Sidebar%20Menu&utm_campaign=external_referral',
            requires2fa: false
        },
        'lending-fe': {
            additionalRequirements: ['hasPermission', 'isNkoAdminIfNkoUser'],
            backendName: 'business-financing',
            category: 'Tools',
            enabled: false,
            icon: 'IconSustainability',
            sortOrder: 8,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/business-financing',
            requires2fa: false
        },
        'logistics-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'logistics',
            category: 'Tools',
            enabled: true,
            icon: 'IconDelivery',
            sortOrder: 6,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/logistics',
            requires2fa: false
        },
        'logs-app-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'logs',
            category: 'Tools',
            enabled: true,
            icon: 'IconTransfers',
            sortOrder: 7,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/logs',
            requires2fa: false
        },
        'account-information-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'products',
            category: 'Preferences',
            enabled: true,
            icon: 'IconProfileAlternative',
            isExternalLink: false,
            sortOrder: 10,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/account-information',
            requires2fa: false
        },
        'nko-orders': {
            additionalRequirements: ['hasPermission'],
            category: 'Klarna Online',
            config: {
                isNkoApp: true
            },
            enabled: true,
            icon: 'IconOrder',
            isExternalLink: true,
            shouldMakeTabVisible: true,
            sortOrder: 1,
            tabs: ['store-manager'],
            url: '/stores/{estoreId}/orders/incoming',
            requires2fa: false
        },
        'nko-settings': {
            additionalRequirements: ['hasPermission'],
            category: 'Klarna Online',
            config: {
                isNkoApp: true
            },
            enabled: true,
            icon: 'IconSettings',
            isExternalLink: true,
            shouldMakeTabVisible: true,
            sortOrder: 8,
            tabs: ['store-manager'],
            url: '/settings/store_show_store_view',
            requires2fa: false
        },
        'nko-settlements': {
            additionalRequirements: ['hasPermission'],
            category: 'Klarna Online',
            config: {
                isNkoApp: true
            },
            enabled: true,
            icon: 'IconAssets',
            isExternalLink: true,
            shouldMakeTabVisible: true,
            sortOrder: 2,
            tabs: ['store-manager'],
            url: '/reports/store_costs_list',
            requires2fa: false
        },
        'orders-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'orders',
            category: 'For your day-to-day',
            enabled: true,
            icon: 'IconOrder',
            sortOrder: 1,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/orders',
            requires2fa: false
        },
        'products-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'products',
            category: 'Preferences',
            enabled: true,
            icon: 'IconKlarnaK',
            sortOrder: 10,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/products',
            requires2fa: false
        },
        'seller-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'seller',
            category: 'For your day-to-day',
            enabled: true,
            icon: 'IconStores',
            sortOrder: 3,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/seller',
            requires2fa: false
        },
        'settings-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'merchant-portal-settings',
            category: 'Preferences',
            enabled: true,
            icon: 'IconSettings',
            isBottomItem: true,
            sortOrder: 7,
            tabs: ['boosters', 'store-manager'],
            url: '/settings',
            requires2fa: false
        },
        'settlements-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'settlements',
            category: 'For your day-to-day',
            enabled: true,
            icon: 'IconAssets',
            sortOrder: 2,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/settlements',
            requires2fa: false
        },
        'shopping-data-fe': {
            additionalRequirements: [],
            category: 'Tools',
            enabled: true,
            icon: 'IconShop',
            sortOrder: 10,
            tabs: ['boosters'],
            shouldMakeTabVisible: true,
            url: '/supplementary-purchase-data',
            requires2fa: false
        },
        support: {
            additionalRequirements: ['isTransactingUser', 'isNotDeepLinkUser'],
            category: 'Tools',
            enabled: true,
            icon: 'IconAssistant',
            isBottomItem: true,
            sortOrder: 5,
            tabs: ['growth', 'store-manager'],
            url: '/support',
            requires2fa: false
        },
        'upstream-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'on-site-messaging',
            category: 'Tools',
            enabled: true,
            icon: 'IconSpeechBubble',
            sortOrder: 9,
            tabs: ['boosters'],
            shouldMakeTabVisible: true,
            url: '/on-site-messaging',
            requires2fa: false
        },
        'users-fe': {
            additionalRequirements: ['hasPermission'],
            backendName: 'users',
            category: 'Preferences',
            enabled: true,
            icon: 'IconProfile',
            sortOrder: 12,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/users',
            requires2fa: false
        },
        'siwk-fe': {
            additionalRequirements: ['hasPermission'],
            category: 'Tools',
            enabled: true,
            url: '/siwk',
            icon: 'IconProfileAlternative',
            shouldMakeTabVisible: true,
            sortOrder: 13,
            tabs: ['boosters'],
            backendName: 'siwk',
            requires2fa: false
        },
        'kec-fe': {
            additionalRequirements: ['hasPermission'],
            badge: 'New',
            isBadgeBranded: true,
            category: 'Tools',
            enabled: true,
            url: '/kec',
            icon: 'IconGroceries',
            shouldMakeTabVisible: true,
            sortOrder: 11,
            tabs: ['boosters'],
            requires2fa: false
        },
        'klarna-search-b2b-portal': {
            additionalRequirements: ['isNotDeepLinkUser'],
            badge: 'New',
            category: 'Tools',
            enabled: true,
            url: '/search-and-compare',
            icon: 'IconShop',
            tabs: ['growth'],
            shouldMakeTabVisible: true,
            sortOrder: 13,
            requires2fa: false
        },
        'partner-self-service': {
            additionalRequirements: ['hasPermission'],
            backendName: 'partner-self-service',
            category: 'For your day-to-day',
            enabled: true,
            icon: 'IconEdit',
            sortOrder: 9,
            tabs: ['store-manager'],
            shouldMakeTabVisible: true,
            url: '/self-service',
            requires2fa: false
        }
    },
    common: {
        appCategories: {
            dayToDay: {
                order: 1,
                title: 'For your day-to-day'
            },
            nko: {
                order: 2,
                title: 'Klarna Online'
            },
            preferences: {
                order: 4,
                title: 'Preferences'
            },
            tools: {
                order: 3,
                title: 'Tools'
            }
        },
        assets: {
            baseUrl: 'https://x.klarnacdn.net/meta'
        },
        insights: {
            googleAnalytics: {
                cookieDomain: '',
                dimensions: {
                    user_group_id: 'dimension1',
                    user_group_name: 'dimension2',
                    user_id: 'dimension3',
                    user_language: 'dimension4',
                    user_realm: 'dimension5'
                },
                enabled: true,
                metrics: {},
                tagId: ''
            },
            mParticle: {
                enabled: false
            }
        },
        keycloak: {
            clientId: 'merchant-portal',
            connectAttempts: 5,
            connectDelay: 1000,
            healthCheckPath: '/auth/realms/merchants/.well-known/openid-configuration',
            partnerClientId: 'partner-portal',
            refreshTokenThreshold: 30
        },
        merchantSupport: {
            countries: {
                DE: 'https://www.klarna.com/de/verkaeufer/haendlersupport',
                FI: 'https://www.klarna.com/fi/yritys/kauppiastuki',
                GB: 'https://www.klarna.com/uk/business/contact-merchant-support',
                NL: 'https://www.klarna.com/nl/zakelijk/webwinkelsupport',
                NO: 'https://www.klarna.com/no/bedrift/butikksupport',
                SE: 'https://www.klarna.com/se/foretag/butikssupport',
                US: 'https://www.klarna.com/us/business/merchant-support-mail-form'
            },
            default: 'GB'
        },
        nko: {
            baseUrl: null
        },
        oneTrust: {
            enabled: true
        },
        privacy: {
            url: '/privacy-policy'
        },
        sentry: {
            dsn: 'https://fbe6f99f4edc48218dc43f4c710783aa@o24547.ingest.sentry.io/1352785',
            enabled: false
        },
        terms: {
            url: '/terms-and-conditions'
        }
    },
    version: '1'
};
