import React from 'react'
import { BotNodeId, HelpbotFlow } from '../../../types'
import { INeedMoreHelpThatHelped } from '../../common'
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage'

const payoutAndFeeCalculationFlow: HelpbotFlow = [
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION,
    botMessage: null,
    options: () => [
      {
        value: 'helpbot.settlements.payoutAndFeeCalculation.howAreFeesCalculated.question',
        next: BotNodeId.SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_FEES_CALCULATED
      },
      {
        value: 'helpbot.settlements.payoutAndFeeCalculation.howIsPayoutCalculated.question',
        next: BotNodeId.SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_PAYOUT_CALCULATED
      }
    ]
  },
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_FEES_CALCULATED,
    botMessage: () => <DefaultBotMessage translationKey='helpbot.settlements.payoutAndFeeCalculation.howAreFeesCalculated.answer' />,
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_PAYOUT_CALCULATED,
    botMessage: () => <DefaultBotMessage translationKey='helpbot.settlements.payoutAndFeeCalculation.howIsPayoutCalculated.answer' />,
    options: () => INeedMoreHelpThatHelped
  }
]

export default payoutAndFeeCalculationFlow
