import * as R from 'ramda';
import { createSelector } from 'reselect';
const configState = R.pathOr({}, ['config']);
const apps = R.pipe(configState, R.propOr([], 'apps'));
const generateGetApp = createSelector(apps, apps => clientId => apps[clientId]);
const generateGetAppBackendName = createSelector(generateGetApp, getApp => clientId => {
    const appConfig = getApp(clientId);
    return R.prop('backendName', appConfig);
});
const generateGetAppUrl = R.pipe(generateGetApp, getApp => clientId => {
    const app = getApp(clientId);
    return R.prop('url', app);
});
const common = R.pipe(configState, R.propOr({}, 'common'));
const terms = R.pipe(common, R.prop('terms'));
const insights = R.pipe(common, R.prop('insights'));
const keycloak = R.pipe(common, R.prop('keycloak'));
const getEnvironmentConfig = R.pipe(common, R.prop('environment'));
const environment = R.pipe(getEnvironmentConfig, R.prop('type'));
const region = R.pipe(getEnvironmentConfig, environmentConfig => {
    const region = R.prop('region', environmentConfig);
    return region ? region.toLowerCase() : undefined;
});
const merchantSupport = R.pipe(common, R.prop('merchantSupport'));
const nko = R.pipe(common, R.prop('nko'));
const privacy = R.pipe(common, R.prop('privacy'));
const getMerchantPortalBaseUrl = R.pipe(common, R.prop('merchantPortalBaseUrl'));
const getFrontendEventRouterBaseUrls = R.pipe(common, R.propOr({}, 'frontendEventRouterBaseUrls'));
const appCategories = R.pipe(common, R.prop('appCategories'));
const isOneTrustEnabled = R.pipe(common, R.pathOr(false, ['oneTrust', 'enabled']));
const getAppConfigByPathname = createSelector(apps, apps => pathname => {
    if (!apps)
        return undefined;
    if (!pathname)
        return undefined;
    let selectedAppId;
    if (pathname === '/' || pathname === '/home') {
        selectedAppId = 'home-fe';
    }
    else {
        selectedAppId = Object.keys(apps).find(clientId => apps[clientId].url !== '/' &&
            apps[clientId].url !== '/home' &&
            pathname.startsWith(apps[clientId].url));
    }
    if (!selectedAppId) {
        return undefined;
    }
    return Object.assign({ clientId: selectedAppId }, apps[selectedAppId]);
});
export { appCategories, apps, environment, generateGetApp, generateGetAppBackendName, generateGetAppUrl, getAppConfigByPathname, getFrontendEventRouterBaseUrls, getMerchantPortalBaseUrl, insights, isOneTrustEnabled, keycloak, merchantSupport, nko, privacy, region, terms };
