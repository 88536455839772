import { storeChatDataSession, cleanChatDataSession } from './restoreActiveChat'
import { getFreschatWidgetOptions } from './getFreshchatWidgetOptions'
import { getUserProperties } from './user'
import { FreschatUserResponse, getFreshchatWidget, isFreschatWidgetReady } from './getFreshchatWidget'
import { ClientGenerator, StartConversationContext } from './types'
import { incrConversationsMetric } from './metrics'
import { getDataFromSessionStorage } from './utils/sessionStorage'
import { CHAT_SECURE_SESSION_STORAGE_KEY } from './constants'
import { postUserInfo } from './postUserInfo'

let generateClient: ClientGenerator = () => ({ request: () => null })

export const updateClientGenerator = (g: ClientGenerator) => {
  generateClient = g
}

export const startConversationUserInfo = async (ctx: StartConversationContext) => {
  const markets = [ctx.market]
  const email = ctx.tokenHelper.getEmail()
  const language = ctx.language
  const { env, region } = ctx

  if (!region) return
  if (!isFreschatWidgetReady()) return

  const widgetOptions = getFreschatWidgetOptions(ctx)
  const userProperties = getUserProperties({ markets, email, language })

  initChatUser(userProperties)
  initChatWidget(widgetOptions)
  newUserHandling(ctx, widgetOptions)

  openChatWidget()

  storeChatDataSession({ widgetOptions, env })

  firstMessageHandling(ctx)
  widgetOpenedHandling(ctx)
  widgetClosedHandling(ctx)
}

export const restoreConversationIfAnyActive = async (ctx: StartConversationContext) => {
  const storedData = getDataFromSessionStorage(CHAT_SECURE_SESSION_STORAGE_KEY)

  if (storedData && storedData.market) {
    startConversationUserInfo({ ...ctx, ...storedData })
  }
}

const initChatWidget = (widgetOptions) => getFreshchatWidget().init(widgetOptions)
const openChatWidget = () => getFreshchatWidget().open()

const initChatUser = (userProperties) => getFreshchatWidget().user.setProperties(userProperties)

const newUserHandling = (ctx, widgetOptions) =>
  getFreshchatWidget().on('user:created', res => {
    const { onNewUser, region } = ctx

    if (canCallNewUserCallback(res, widgetOptions)) {
      onNewUser(res.data.restoreId, region)
    }
  })

const canCallNewUserCallback = (res, widgetOptions): boolean => res?.data?.restoreId && !(widgetOptions.restoreId)

const firstMessageHandling =
  (ctx: StartConversationContext) => {
    getFreshchatWidget().on('message:sent', () => {
      getUserAndPostTheirInfo(ctx)
      getFreshchatWidget().off('message:sent')
    })
  }

const getUserAndPostTheirInfo = ({ region }: StartConversationContext) => {
  getFreshchatWidget().user.get(({ data, success }: FreschatUserResponse) => {
    if (!success) return
    postUserInfo(generateClient('support-chat'), region!, data.alias)
  })
}

const widgetOpenedHandling =
  ({ chatClient, env, region, market, onChatWidgetOpens }: StartConversationContext) => {
    getFreshchatWidget().on('widget:opened', () => {
      incrConversationsMetric({ chatClient, env, region, market })
      onChatWidgetOpens()
    })
  }

const widgetClosedHandling = ({ onCloseChat }: StartConversationContext) => {
  getFreshchatWidget().on('widget:closed', () => {
    cleanChatDataSession()
    getFreshchatWidget().destroy()
    onCloseChat()
  })
}
