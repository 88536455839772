import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionIconArrowLeft, ButtonRoundSecondary, Link, SpacerHorizontal } from '@klarna/bubble-ui';
import { Flex } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
const LinkStyled = styled.span `
  cursor: pointer;
`;
function BackButton({ path, onClick, label }) {
    const history = useHistory();
    const onBackButtonClick = () => {
        onClick === null || onClick === void 0 ? void 0 : onClick();
        if (path) {
            history === null || history === void 0 ? void 0 : history.push(path);
        }
        else {
            history === null || history === void 0 ? void 0 : history.goBack();
        }
    };
    return (_jsxs(Flex, { alignItems: "center", children: [_jsx(ButtonRoundSecondary, { id: "mpui-fw-header-backbutton", "aria-label": label, size: "small", onPress: onBackButtonClick, children: _jsx(ActionIconArrowLeft, {}) }), label && (_jsxs(_Fragment, { children: [_jsx(SpacerHorizontal, { spaceToken: "space/200" }), _jsx(LinkStyled, { children: _jsx(Link, { textToken: "text-style/text/paragraphs/default/bold", colorToken: "colors/text/neutral", onPress: onBackButtonClick, children: label }) })] }))] }));
}
BackButton.propTypes = {
    label: PropTypes.string.isRequired,
    path: PropTypes.string,
    onClick: PropTypes.func
};
export default BackButton;
