import { createStorage } from 'mage-common';
import { useCallback, useMemo, useState } from 'react';
const localStorageKey = 'mp:featuresShown:notification:badgeAnimation';
export default function useNotificationBadge(notifications) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const localStorage = useMemo(() => createStorage(), [createStorage]);
    const [animateBadge, setAnimateBadge] = useState(!localStorage.get(localStorageKey));
    const stopAnimation = useCallback(() => {
        localStorage.set(localStorageKey, true);
        setAnimateBadge(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage, localStorageKey, setAnimateBadge]);
    const unreadNotifications = notifications.filter(notification => !notification.read);
    const notificationCount = unreadNotifications.length;
    const hasUnreadNotifications = notificationCount > 0;
    const badgeLabel = notificationCount > 9 ? '9+' : String(notificationCount);
    return [hasUnreadNotifications, badgeLabel, animateBadge, stopAnimation];
}
