import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from '@klarna/bubble-ui';
const TEMPLATES = ['internalServerError', 'forbidden', 'notFound'];
export default function getPropsForTemplate({ t, template, errorId }) {
    const defaultContactContent = t('core.errorTemplates.default.contact', {
        errorId: _jsx("strong", { children: errorId }),
        supportLink: children => (_jsx(Link, { textToken: "text-style/text/paragraphs/body/medium", isUnderlined: true, href: "https://www.klarna.com/merchant-support", target: "_blank", children: children }))
    }, 
    /* Fallback if translation is not found */
    'Please try refreshing the page');
    const errorTemplates = {
        internalServerError: {
            errorDescription: t('core.errorTemplates.errorDescription', { code: 500 }),
            title: t('core.errorTemplates.internalServerError.title'),
            message: t('core.errorTemplates.internalServerError.message')
        },
        forbidden: {
            errorDescription: t('core.errorTemplates.errorDescription', { code: 403 }),
            title: t('core.errorTemplates.forbidden.title'),
            message: t('core.errorTemplates.forbidden.message')
        },
        notFound: {
            errorDescription: t('core.errorTemplates.errorDescription', { code: 404 }),
            title: t('core.errorTemplates.notFound.title'),
            message: t('core.errorTemplates.notFound.message')
        },
        default: {
            errorDescription: errorId
                ? t('core.errorTemplates.default.errorDescription', { errorId })
                : '',
            title: t('core.errorTemplates.default.title', {}, 'Unexpected Error'),
            contactContent: [defaultContactContent]
        }
    };
    if (!template) {
        return errorTemplates.default;
    }
    return errorTemplates[template];
}
export { getPropsForTemplate, TEMPLATES };
