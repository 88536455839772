import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonPrimary, ButtonSecondary, DialogUnwrapped, SpacerVertical } from '@klarna/bubble-ui';
import { Article } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Illustration from './Illustration';
import Portal from './Portal';
ResetMfaConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};
function ResetMfaConfirmationDialog({ onClose, onConfirm }) {
    const [status, setStatus] = useState();
    const t = i18nHooks.useTranslator();
    function handleConfirm() {
        return __awaiter(this, void 0, void 0, function* () {
            setStatus('progress');
            try {
                yield onConfirm();
                setStatus('success');
            }
            catch (_a) {
                setStatus('error');
            }
        });
    }
    const isInProgress = status === 'progress';
    const isSuccessful = status === 'success';
    const content = {
        title: 'core.updateProfile.twoFactor.confirmationDialog.resetConfirmation.title',
        description: 'core.updateProfile.twoFactor.confirmationDialog.resetConfirmation.description',
        illustration: 'problem'
    };
    const successContent = {
        title: 'core.updateProfile.twoFactor.confirmationDialog.resetSuccess.title',
        description: 'core.updateProfile.twoFactor.confirmationDialog.resetSuccess.description',
        illustration: 'success'
    };
    const footerSuccess = [
        {
            Component: ButtonPrimary,
            props: {
                children: t('core.updateProfile.twoFactor.confirmationDialog.closeButton'),
                onClick: onClose
            }
        }
    ];
    const footer = [
        {
            Component: ButtonPrimary,
            props: {
                children: t('core.updateProfile.twoFactor.confirmationDialog.confirmationButton'),
                onClick: handleConfirm,
                loading: isInProgress
            }
        },
        {
            Component: ButtonSecondary,
            props: {
                children: t('core.updateProfile.twoFactor.confirmationDialog.cancelButton'),
                onClick: onClose,
                disabled: isInProgress
            }
        }
    ];
    if (isSuccessful) {
        return (_jsx(Portal, { children: _jsxs(DialogUnwrapped, { onClose: onClose, footer: footerSuccess, children: [_jsx(Illustration, { type: successContent.illustration }), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(Article, { title: t(successContent.title), titleType: "primary", description: t(successContent.description) })] }) }));
    }
    return (_jsx(Portal, { children: _jsxs(DialogUnwrapped, { onClose: onClose, footer: footer, children: [_jsx(Illustration, { type: content.illustration }), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(Article, { title: t(content.title), titleType: "primary", description: t(content.description) })] }) }));
}
export default ResetMfaConfirmationDialog;
