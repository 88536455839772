const pages = [
  {
    name: 'Cookies',
    overrideTopBarConfig: true,
    responsibleTeam: 'mpp'
  },
  {
    name: 'Error',
    responsibleTeam: 'mpp'
  },
  {
    name: 'PrivacyPolicy',
    overrideTopBarConfig: true,
    responsibleTeam: 'mpp',
    subpages: [
      {
        name: 'AP',
        overrideTopBarConfig: true
      },
      {
        name: 'EU',
        overrideTopBarConfig: true
      },
      {
        name: 'US',
        overrideTopBarConfig: true
      }
    ]
  },
  {
    name: 'Support',
    responsibleTeam: 'poxe',
    requireAuthentication: true,
    subpages: [
      {
        name: 'Ticket',
        overrideTopBarConfig: true,
        param: ':id'
      }
    ]
  },
  {
    name: 'TermsAndConditions',
    overrideTopBarConfig: true,
    responsibleTeam: 'mpp'
  },
  {
    name: 'TwoFactorAuthentication',
    responsibleTeam: 'meg',
    subpages: [
      {
        name: 'Faq',
        overrideTopBarConfig: true
      },
      {
        name: 'Setup',
        overrideTopBarConfig: true
      },
      {
        name: 'SetupApp',
        overrideTopBarConfig: true
      },
      {
        name: 'SetupBrowser',
        overrideTopBarConfig: true
      },
      {
        name: 'SetupEmail',
        overrideTopBarConfig: true
      },
      {
        name: 'SetupSms',
        overrideTopBarConfig: true
      }
    ]
  }
]

export default pages
