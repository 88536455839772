import { cookieHelper } from 'mage-common';
const MERCHANT_PORTAL_SESSION_COOKIE_NAME = 'MerchantPortal_Session';
const cookies = cookieHelper.getCookies();
const upsertSessionCookie = (value) => {
    const existingCookieValue = cookies.get(MERCHANT_PORTAL_SESSION_COOKIE_NAME);
    cookies.set(MERCHANT_PORTAL_SESSION_COOKIE_NAME, Object.assign(Object.assign({}, existingCookieValue), value), {
        path: '/',
        sameSite: 'strict'
    });
};
const getSessionCookie = () => {
    return cookies.get(MERCHANT_PORTAL_SESSION_COOKIE_NAME);
};
const deleteSessionCookie = () => {
    cookies.remove(MERCHANT_PORTAL_SESSION_COOKIE_NAME);
};
export { deleteSessionCookie, getSessionCookie, upsertSessionCookie };
