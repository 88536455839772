import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ResponsiveContextProvider, useGetToken, useThemeBreakpoints } from '@klarna/mp-ui';
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDesktop, useTablet } from '../../hooks/useMediaQuery';
import AppContent from './AppContent';
import { SIDEBAR_DEFAULT_WIDTH, SIDEBAR_TABLET_WIDTH, SIDEPANEL_WIDTH, zIndex } from './constants';
import { NotificationsContextProvider } from './Contexts/NotificationsContext';
import { SidePanelContextProvider, useSidePanelContext } from './Contexts/SidePanelContext';
import { StateContext } from './Contexts/StateContext';
import Footer from './Footer';
import Sidebar, { SidebarProvider } from './Sidebar';
import Tabs from './Tabs';
import TopBar from './TopBar';
const ContainerStyled = styled.div(({ hideTopBar, hideSidebar, hasTabs }) => {
    const { isSidePanelOpen, setHasTabs } = useSidePanelContext();
    setHasTabs(hasTabs);
    const tabletMediaQuery = useTablet();
    const desktopMediaQuery = useDesktop();
    const backgroundColor = useGetToken('colors/backgrounds/subtle-inline').toString();
    const topbarHeight = useGetToken('space/800').value();
    const getTemplateRows = () => {
        if (hideTopBar) {
            return '0px 1fr';
        }
        if (hasTabs) {
            return `${topbarHeight}px ${topbarHeight}px 1fr`;
        }
        return `${topbarHeight}px 1fr`;
    };
    return css({
        height: '100%',
        width: '100%',
        backgroundColor,
        position: 'fixed',
        display: 'grid',
        gridTemplateColumns: '0 1fr',
        gridTemplateRows: getTemplateRows(),
        zIndex: zIndex.root,
        gridTemplateAreas: hasTabs
            ? `
        "topbar topbar panel"
        "tabs tabs panel"
        "sidebar app panel"
        `
            : `
        "topbar topbar panel"
        "sidebar app panel"
        `,
        [tabletMediaQuery]: {
            gridTemplateColumns: `${hideSidebar ? 0 : SIDEBAR_TABLET_WIDTH}px 1fr ${isSidePanelOpen ? `${SIDEPANEL_WIDTH}px` : 0}`
        },
        [desktopMediaQuery]: {
            gridTemplateColumns: `${hideSidebar ? 0 : SIDEBAR_DEFAULT_WIDTH}px 1fr ${isSidePanelOpen ? `${SIDEPANEL_WIDTH}px` : 0}`
        }
    });
});
const ScrollableStyled = styled.div({
    boxSizing: 'border-box',
    position: 'relative',
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    gridArea: 'app',
    zIndex: zIndex.mainContent
});
const SidePanelStyled = styled.div(() => {
    const { isSidePanelOpen } = useSidePanelContext();
    const desktopMediaQuery = useDesktop();
    return css({
        gridArea: 'app/app/panel/panel',
        zIndex: zIndex.sidePanel,
        display: isSidePanelOpen ? 'block' : 'none',
        [desktopMediaQuery]: {
            gridArea: 'panel'
        }
    });
});
const initialContext = {
    state: {
        hideFooter: false,
        hideTopBar: false,
        hideSidebar: false
    }
};
const groupSidebarItems = (tabs, sidebarItems) => {
    return tabs.reduce((currentGroupedSidebarItems, tab) => {
        const tabItems = sidebarItems.filter(({ tabs = [] }) => tabs.includes(tab.id));
        if (tabItems.some(({ isBottomItem }) => !isBottomItem) || tab.alwaysShow) {
            return Object.assign(Object.assign({}, currentGroupedSidebarItems), { [tab.id]: [tab.homeApp, ...tabItems] });
        }
        else {
            return currentGroupedSidebarItems;
        }
    }, {});
};
/** A navigation framework that provides a common template, context and responsive utilities. */
function MarbleFramework({ children, error, id, isLoading, isFullScreenContent, sidePanel, footerConfig, sidebarConfig, topBarConfig }) {
    var _a;
    const breakpoints = useThemeBreakpoints();
    const [stateContext, setStateContext] = useState(initialContext.state);
    const isSidebarHidden = () => {
        return !sidebarConfig || stateContext.hideSidebar;
    };
    const isTopBarHidden = () => {
        return !topBarConfig || stateContext.hideTopBar;
    };
    const isFooterHidden = () => {
        return !footerConfig || stateContext.hideFooter;
    };
    const [hideFooter, hideSidebar, hideTopBar] = [
        isFooterHidden(),
        isSidebarHidden(),
        isTopBarHidden()
    ];
    const { items: sidebarItems = [], tabs = [] } = sidebarConfig || {};
    const groupedSidebarItems = useMemo(() => groupSidebarItems(tabs, sidebarItems), [tabs, sidebarItems]);
    const hasTabs = tabs.filter(tab => tab.id in groupedSidebarItems).length > 1;
    return (_jsx(ResponsiveContextProvider, { breakpoints: breakpoints, children: _jsx(StateContext.Provider, { value: { setState: setStateContext }, children: _jsx(SidePanelContextProvider, { children: _jsx(SidebarProvider, { config: sidebarConfig && Object.assign({}, sidebarConfig), children: _jsxs(ContainerStyled, { id: id, hideSidebar: hideSidebar, hideTopBar: hideTopBar, hasTabs: hasTabs, children: [!hideTopBar && _jsx(TopBar, Object.assign({}, topBarConfig, { showLogoOnMobile: hideSidebar })), ((_a = sidebarConfig === null || sidebarConfig === void 0 ? void 0 : sidebarConfig.tabs) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(Tabs, { groupedSidebarItems: groupedSidebarItems, tabs: sidebarConfig.tabs, sidebarItems: sidebarConfig === null || sidebarConfig === void 0 ? void 0 : sidebarConfig.items, defaultTab: sidebarConfig.defaultTab })), !hideSidebar && _jsx(Sidebar, { hideTopBar: hideTopBar }), _jsxs(ScrollableStyled, { children: [_jsx(NotificationsContextProvider, { children: _jsx(AppContent, { stateContext: stateContext, isFrameworkLoading: isLoading, frameworkError: error, isFullScreenContent: isFullScreenContent, children: children }) }), !hideFooter && footerConfig && _jsx(Footer, Object.assign({}, footerConfig))] }), sidePanel && _jsx(SidePanelStyled, { children: sidePanel })] }) }) }) }) }));
}
export default MarbleFramework;
