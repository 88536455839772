import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
function HeaderMenuButton({ id, onClick, children }) {
    return (_jsx("div", { id: id, style: { display: 'flex', alignItems: 'center' }, onClick: onClick, children: children }));
}
HeaderMenuButton.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
export default HeaderMenuButton;
