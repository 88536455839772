import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonPrimary, Radio, SpacerVertical, Typography, View } from '@klarna/bubble-ui';
import { selectors as i18nSelectors } from 'mage-i18n';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
const MID_RENDERING_LIMIT = 30;
export const RegionSelector = props => {
    const [selectedRegion, setSelectedRegion] = useState(null);
    const t = useSelector(state => i18nSelectors.translator(state));
    const { titleText, subTitleText, storeNamesByRegion, buttonText, onConfirmRegion } = props;
    const regions = Object.keys(storeNamesByRegion);
    const options = regions.map(region => {
        const option = {
            value: region,
            label: t(`core.regionSelector.regions.${region.toLowerCase()}`)
        };
        const regionMids = storeNamesByRegion[region];
        if (regionMids.length === 0) {
            option.description = t('core.regionSelector.noStores');
            option.disabled = true;
        }
        else if (regionMids.length > MID_RENDERING_LIMIT) {
            const midsToDisplay = regionMids.slice(0, 30).join(', ');
            option.description = `${midsToDisplay} ${t('core.regionSelector.andMore')}`;
        }
        else {
            option.description = regionMids.join(', ');
        }
        return option;
    });
    return (_jsxs("div", { id: "landing-page-selector", children: [_jsx(Typography, { textToken: "text-style/headings/titles/bold/grande", children: titleText }), subTitleText && (_jsx(Typography, { textToken: "text-style/text/paragraphs/body/regular", children: subTitleText })), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(Radio, { options: options, onChange: value => setSelectedRegion(value), value: selectedRegion }), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(View, { style: { alignItems: 'flex-start' }, children: _jsx(ButtonPrimary, { onPress: () => onConfirmRegion(selectedRegion), disabled: selectedRegion === null, children: buttonText }) })] }));
};
RegionSelector.propTypes = {
    titleText: PropTypes.string.isRequired,
    subTitleText: PropTypes.string,
    storeNamesByRegion: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    buttonText: PropTypes.string.isRequired,
    onConfirmRegion: PropTypes.func.isRequired
};
