import React from 'react'
import { Helpbot } from './components/Helpbot'
import { HelpbotStateProvider } from './contexts/HelpbotStateProvider'

const SupportHelpbot: React.FC = () => {
  return (
    <HelpbotStateProvider>
      <Helpbot />
    </HelpbotStateProvider>
  )
}

export { SupportHelpbot }
export default SupportHelpbot
