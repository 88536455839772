type StoreSessionInput = { key: string; data: any }

export const storeDataIntoSession = ({ key, data }: StoreSessionInput) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data))
  } catch (e: any) {
    console.log('cannot store data into storage session', e.message)
  }
}

export const getDataFromSessionStorage = (key: StoreSessionInput['key']) => {
  try {
    const storedData = sessionStorage.getItem(key)
    return storedData ? JSON.parse(storedData) : null
  } catch (e: any) {
    console.log('cannot get data from storage session', e.message)
    return null
  }
}
