import { __rest } from "tslib";
import { memoizeWithTokenHelper } from '@mpp/token-helper';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { utils as commonUtils } from 'mage-common';
import { selectors as configSelectors } from 'mage-config';
import { selectors as i18nSelectors } from 'mage-i18n';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import uuid from 'uuid/v4';
import { init } from './reducer';
import * as utils from './utils';
const normaliseMerchant = R.pipe((_a) => {
    var { links = [], partners = [], transacting_regions: transactingRegions = [] } = _a, merchant = __rest(_a, ["links", "partners", "transacting_regions"]);
    return (Object.assign(Object.assign({}, commonUtils.renameKeys({
        created_at: 'createdAt',
        merchant_id: 'merchantId',
        onboarding_region: 'onboardingRegion',
        store_name: 'storeName'
    }, merchant)), { links: links.map(commonUtils.renameKeys({
            link_id: 'linkId',
            link_type: 'linkType'
        })), partners: partners.map(commonUtils.renameKeys({
            merchant_portal_role: 'merchantPortalRole'
        })), transactingRegions: transactingRegions.map(commonUtils.renameKeys({
            latest_order_date: 'latestOrderDate'
        })) }));
}, commonUtils.cleanObject);
const coreState = R.propOr({}, 'core');
const clientId = R.pipe(coreState, R.prop('clientId'));
const appId = R.pipe(coreState, R.prop('appId'));
const currentApp = createSelector(appId, configSelectors.apps, (clientId, apps) => apps[clientId]);
const isHomeApp = R.pipe(clientId, R.equals('home-fe'));
const title = createSelector(clientId, i18nSelectors.translatorFactory, (clientId, createTranslator) => {
    const t = createTranslator('en'); // hard-code to English
    const title = t(`core.apps.${clientId}.title`);
    return title || clientId;
});
const slug = R.pipe(coreState, R.propOr('', 'slug'));
const getBackendName = createSelector(clientId, configSelectors.generateGetAppBackendName, (clientId, getAppBackendName) => getAppBackendName(clientId));
const generateGetBackendUrl = createSelector(getBackendName, _backendName => (backendName = _backendName) => `/api/{region}/${backendName}`);
const generateGetBackendUrlForRegion = createSelector(configSelectors.region, generateGetBackendUrl, (merchantPortalRegion, getBackendUrl) => ({ backendName, region = merchantPortalRegion } = {}) => {
    return getBackendUrl(backendName).replace('{region}', region.toLowerCase());
});
const getBasename = createSelector(clientId, configSelectors.generateGetAppUrl, (clientId, getAppUrl) => getAppUrl(clientId) || '');
const keycloakRealm = R.pipe(coreState, R.prop('realm'));
const isKlarnaRealm = R.pipe(keycloakRealm, R.equals('klarna'));
const isMerchantsRealm = R.pipe(keycloakRealm, R.equals('merchants'));
const isPartner = R.pipe(coreState, R.propEq(true, 'isPartner'));
const sessionId = R.pipe(coreState, R.prop('sessionId'));
const authenticated = R.pipe(coreState, R.prop('authenticated'));
const isAuthenticated = createSelector(authenticated, R.equals(true));
const getSelectedMid = createSelector(coreState, R.prop('selectedMid'));
const getAccessToken = R.pipe(coreState, R.prop('accessToken'));
const getDistributionPartner = createSelector(coreState, R.prop('distributionPartner'));
const getMerchants = createSelector(coreState, R.pipe(R.propOr(init.merchants, 'merchants'), R.map(normaliseMerchant)));
const getMerchantsByMids = createSelector(getMerchants, merchants => R.memoizeWith(R.identity, mids => R.filter(({ merchantId }) => R.includes(merchantId, mids), merchants)));
const getMerchantsGroupedByRegion = createSelector(getMerchants, R.groupBy(R.propOr('UNKNOWN', 'onboardingRegion')));
const getMerchant = createSelector(getMerchants, merchants => R.memoizeWith(R.identity, mid => R.find(R.propEq(mid, 'merchantId'), merchants)));
const getMerchantRegion = createSelector(getMerchant, getMerchant => R.memoizeWith(R.identity, mid => {
    const merchant = getMerchant(mid);
    if (merchant) {
        return merchant.onboardingRegion;
    }
}));
/**
 * !!! DEPRECATED !!!
 * Use the token-helper to get this data, as this selector will be removed soon(-ish)
 * https://jira.int.klarna.net/jira/browse/MPP-5080
 */
const getMerchantIds = createSelector(coreState, R.propOr(init.mids, 'mids'));
const getPartnerAccounts = createSelector(coreState, R.prop('partnerAccounts'));
const getClientRoles = createSelector(clientId, getSelectedMid, (clientId, mid) => memoizeWithTokenHelper(tokenHelper => tokenHelper.getPermissionsForMid(mid, clientId)));
const isUpdateProfileLoading = R.pipe(coreState, R.propOr(false, 'isUpdateProfileLoading'));
const isUpdateProfileFailed = R.pipe(coreState, R.propOr(false, 'isUpdateProfileFailed'));
const getDesignVersion = R.pipe(coreState, R.prop('designVersion'));
const getRequester = R.pipe(coreState, R.prop('requester'));
const isRequesterLoaded = R.pipe(getRequester, requester => requester !== undefined);
const isRequesterMfaSetupInitiated = R.pipe(getRequester, R.prop('mfa'));
const getMfaMethods = R.pipe(getRequester, R.prop('mfa_methods'));
const isRequesterMfaEnabled = createSelector(isRequesterMfaSetupInitiated, getMfaMethods, (isRequesterMfaSetupInitiated, mfaMethods) => isRequesterMfaSetupInitiated && (mfaMethods === null || mfaMethods === void 0 ? void 0 : mfaMethods.length) > 0);
const isRequesterKred = R.pipe(getRequester, R.propOr(false, 'is_kred'));
const isRequesterPartner = R.pipe(getRequester, R.propOr(false, 'is_partner'));
const getRequesterAttributes = R.pipe(getRequester, R.prop('attributes'));
const getPendingEntities = createSelector(getRequester, requester => {
    const { entity_roles_pending: entityRolesPending = {} } = requester || {};
    return Object.keys(entityRolesPending).reduce((currentEntities, entityType) => {
        const entities = Object.keys(entityRolesPending[entityType]).map(entityId => ({
            id: entityId,
            type: entityType
        }));
        return currentEntities.concat(entities);
    }, []);
});
const isRequesterUsingMfa = createSelector(isRequesterMfaEnabled, isKlarnaRealm, (isRequesterMfaEnabled, isKlarnaRealm) => isRequesterMfaEnabled || isKlarnaRealm);
const clientFactory = createSelector(getAccessToken, configSelectors.region, configSelectors.getMerchantPortalBaseUrl, (accessToken, merchantPortalRegion, merchantPortalBaseUrl) => R.memoizeWith((baseURL, options) => `${baseURL}-${JSON.stringify(options)}`, (baseURL, { backendName, Sentry: _Sentry = Sentry } = {}) => {
    const client = axios.create({
        baseURL,
        timeout: 60000,
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    client.interceptors.request.use(config => {
        var _a;
        const { region = merchantPortalRegion, baseURL = '', url = '' } = config;
        config.region = region.toLowerCase();
        config.baseURL = baseURL.replace('{region}', config.region);
        config.url = url.replace('{region}', config.region);
        if (!config.baseURL || baseURL.startsWith('/')) {
            const backendsBaseUrls = typeof BACKENDS_BASE_URLS === 'undefined' ? {} : BACKENDS_BASE_URLS;
            const merchantPortalBaseUrlOverride = (_a = backendsBaseUrls[backendName]) === null || _a === void 0 ? void 0 : _a[config.region];
            if (merchantPortalBaseUrlOverride) {
                config.baseURL = merchantPortalBaseUrlOverride;
            }
            else {
                config.baseURL = `${merchantPortalBaseUrl}${config.baseURL}`;
            }
        }
        config.headers.common['Klarna-Correlation-Id'] = uuid();
        return config;
    });
    client.interceptors.response.use(response => response, error => {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }
        _Sentry.withScope(scope => {
            if (!error.config)
                return;
            const { baseURL = '', url = '', method, region } = error.config;
            const upperCaseMethod = method.toUpperCase();
            const normalisedBaseUrl = baseURL.replace(/\/$/, '');
            const normalisedUrl = url.replace(/^\//, '');
            const fullUrl = `${normalisedBaseUrl}${normalisedUrl ? `/${normalisedUrl}` : ''}`;
            // mask parts of the URL (so similar URLs are grouped in Sentry)
            const maskedUrl = utils.maskUrl(merchantPortalBaseUrl)(fullUrl);
            const statusCode = error.response ? error.response.status : 0;
            const backendNameMatch = maskedUrl.match(/\/api\/[^/]*\/([^/]*)\//);
            scope.setFingerprint([upperCaseMethod, maskedUrl, String(statusCode)]);
            error.originalMessage = error.message;
            error.message = `Unable to ${upperCaseMethod} ${maskedUrl} - ${statusCode}`;
            _Sentry.captureException(error, {
                extra: {
                    error
                },
                tags: {
                    backendName: backendNameMatch ? backendNameMatch[1] : undefined,
                    backendRegion: region
                }
            });
        });
        return Promise.reject(error);
    });
    return client;
}));
const createBackendClient = createSelector(clientFactory, generateGetBackendUrl, getBackendName, (factory, getBackendUrl, _backendName) => R.memoizeWith(R.identity, (backendName = _backendName) => factory(getBackendUrl(backendName), { backendName })));
const createMerchantPortalApiClient = createSelector(createBackendClient, generateGetBackendUrl, createBackendClient => createBackendClient('merchant-portal'));
const createClient = createSelector(createBackendClient, createBackendClient => createBackendClient());
export { authenticated, clientFactory, clientId, coreState, createBackendClient, createClient, createMerchantPortalApiClient, currentApp, generateGetBackendUrl, generateGetBackendUrlForRegion, getAccessToken, getBasename, getClientRoles, getDesignVersion, getDistributionPartner, getMerchant, getMerchantIds, getMerchantRegion, getMerchants, getMerchantsByMids, getMerchantsGroupedByRegion, getPartnerAccounts, getPendingEntities, getRequester, getRequesterAttributes, getSelectedMid, isAuthenticated, isHomeApp, isKlarnaRealm, isMerchantsRealm, isPartner, isRequesterKred, isRequesterLoaded, isRequesterMfaEnabled, isRequesterMfaSetupInitiated, isRequesterPartner, isRequesterUsingMfa, isUpdateProfileFailed, isUpdateProfileLoading, keycloakRealm, sessionId, slug, title };
