import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ContextMenuMain, ContextMenuNavigationItem, ContextMenuSeparator, ContextMenuSettingsItem, IconClock, IconProfile, IconSpeechBubble, SpacerHorizontal } from '@klarna/bubble-ui';
import { format } from 'date-fns-tz';
import { hooks as coreHooks } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
const styleSheet = {
    menu: {
        position: 'relative',
        display: 'block',
        width: 300
    }
};
export default function Menu({ onLanguageClick, onLogoutClick, onProfileClick }) {
    const tokenHelper = coreHooks.useTokenHelper();
    const shouldShowProfileMenu = tokenHelper ? tokenHelper.isInMerchantsRealm() : false;
    const t = i18nHooks.useTranslator();
    const language = i18nHooks.useLanguage();
    return (_jsxs("div", { id: "usermenu-menu", style: styleSheet.menu, children: [_jsx(SpacerHorizontal, { spaceToken: "space/100" }), _jsxs(ContextMenuMain, { children: [_jsx(ContextMenuSettingsItem, { id: "usermenu-menu-language", onClick: onLanguageClick, icon: IconSpeechBubble, label: t('core.usermenu.language'), value: t(`core.languages.${language}`) }), _jsx(ContextMenuSettingsItem, { id: "usermenu-menu-tz", icon: IconClock, label: t('core.usermenu.timezone'), value: format(new Date(), 'z', {
                            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                        }) }), _jsx(_Fragment, { children: shouldShowProfileMenu && (_jsx(ContextMenuSettingsItem, { id: "usermenu-menu-profile", onClick: onProfileClick, icon: IconProfile, label: t('core.usermenu.profile'), value: t('core.usermenu.changeProfile') })) }), _jsx(ContextMenuSeparator, {}, "separator"), _jsx(ContextMenuNavigationItem, { id: "usermenu-menu-logout", onClick: onLogoutClick, label: t('core.usermenu.logout') })] })] }));
}
