export const UHQM_SIDEBAR_ANIMATION_DURATION = 200
export const UHQM_SESSION_STORAGE_KEY = 'uhqm-navigation-state-restore'
export const HELPBOT_SESSION_STORAGE_KEY = 'helpbot-state-restore'
export const MP_UI_TOPBAR_HEIGHT = 71 // topbar + separator
export const APP_CONTAINER_SELECTOR_FRAMEWORK = '#mpui-fw-main-section'

export const FEATURE_FLAGS = {
  englishChat: 'poxe.support-chat.enable-english-channel'
}

export const CHAT_TAGS = {
  marketDefault: (market) => `merchantportal-${market.toLowerCase()}`,
  english: 'merchantportal-english'
}

export const ENGLISH_SPEAKING_MARKETS = [
  'us',
  'ca',
  'au',
  'nz',
  'gb',
  'ie'
]

export const SECOND_MS = 1000
export const MINUTE_MS = 60 * SECOND_MS
export const HOUR_MS = 60 * MINUTE_MS
export const DAY_MS = 24 * HOUR_MS

export const COLORS = {
  Salmiak: {
    red: 0x17,
    green: 0x17,
    blue: 0x17,
    alpha: 1
  },
  Pumice: {
    red: 0x96,
    green: 0x93,
    blue: 0x91,
    alpha: 1
  },
  Ming: {
    red: 0x48,
    green: 0x7B,
    blue: 0x94,
    alpha: 1
  },
  Flint: {
    red: 0x78,
    green: 0x75,
    blue: 0x73,
    alpha: 1
  },
  Cardinal: {
    red: 0xD4,
    green: 0x37,
    blue: 0x31,
    alpha: 1
  },
  Scarlet: {
    red: 0xF7,
    green: 0x4B,
    blue: 0x45,
    alpha: 1
  },
  Ballet: {
    red: 0xFF,
    green: 0xF6,
    blue: 0xF5,
    alpha: 1
  }
}
