import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import merge from 'deepmerge';
import PropTypes from 'prop-types';
const styles = {
    alignItems: 'stretch',
    borderStyle: 'solid',
    borderWidth: 0,
    boxSizing: 'border-box',
    display: 'flex',
    flexBasis: 'auto',
    flexDirection: 'column',
    flexShrink: 0,
    margin: 0,
    padding: 0,
    // fix flexbox bugs
    minHeight: 0,
    minWidth: 0
};
const View = (_a) => {
    var { style = {}, children } = _a, props = __rest(_a, ["style", "children"]);
    return (_jsx("div", Object.assign({ style: merge(styles, style) }, props, { children: children })));
};
View.propTypes = {
    style: PropTypes.object,
    children: PropTypes.node
};
export default View;
