import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
const OVERLAY_Z = 999;
export const POPOVER_Z = OVERLAY_Z + 1;
const overlayFadeIn = keyframes `
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
`;
const Overlay = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  z-index: ${OVERLAY_Z};
  animation-name: ${overlayFadeIn};
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
  mix-blend-mode: hard-light;
  pointer-events: none;
`;
export function FeatureTourOverlay({ onClick, componentRef, padding = 0, radius = 8 }) {
    var _a;
    const overlayRef = useRef(null);
    const refCoordinates = (_a = componentRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
    const spotlight = {
        top: (refCoordinates === null || refCoordinates === void 0 ? void 0 : refCoordinates.top) - padding,
        right: (refCoordinates === null || refCoordinates === void 0 ? void 0 : refCoordinates.right) + padding,
        bottom: (refCoordinates === null || refCoordinates === void 0 ? void 0 : refCoordinates.bottom) + padding,
        left: (refCoordinates === null || refCoordinates === void 0 ? void 0 : refCoordinates.left) - padding
    };
    useEffect(() => {
        const overlay = overlayRef.current;
        const clickHandler = ({ x, y }) => {
            if (refCoordinates) {
                const isInsideSpotlight = x > spotlight.left && x < spotlight.right && y > spotlight.top && y < spotlight.bottom;
                if (!isInsideSpotlight) {
                    onClick();
                }
            }
        };
        if (overlay) {
            window.document.addEventListener('click', clickHandler);
            return () => {
                window.document.removeEventListener('click', clickHandler);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overlayRef, onClick, componentRef, refCoordinates]);
    return (_jsx(Overlay, { ref: overlayRef, "data-testid": "featureTourOverlay", children: _jsx("div", { style: {
                position: 'absolute',
                top: spotlight.top,
                left: spotlight.left,
                width: spotlight.right - spotlight.left,
                height: spotlight.bottom - spotlight.top,
                background: 'gray',
                borderRadius: radius
            } }) }));
}
