import axios from 'axios';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TokenHelperContext } from './components';
const useSanitizedRouteMatch = () => {
    let { path, url } = useRouteMatch();
    // strip trailing slashes
    path = path.replace(/\/$/, '');
    url = url.replace(/\/$/, '');
    return { path, url };
};
const useTokenHelper = () => useContext(TokenHelperContext);
/**
 * @returns {{ cancelRequest: function, createCancelToken: function, isCancel: function }}
 * cancelRequest - cancels the request attached to the current cancellation token
 * createCancelToken - create a cancellation token sent in the request
 * isCancel - checks if error is a cancel token error
 */
const useCancelToken = () => {
    const source = useRef(null);
    const createCancelToken = useCallback(() => {
        // @ts-expect-error: FIXME
        source.current = axios.CancelToken.source();
        // @ts-expect-error: FIXME
        return source.current.token;
    }, []);
    const cancelRequest = useCallback(message => {
        if (source.current) {
            // @ts-expect-error: FIXME
            source.current.cancel(message);
        }
    }, []);
    return {
        cancelRequest,
        createCancelToken,
        isCancel: axios.isCancel
    };
};
const useCacheMidInSessionStorage = (value, setter) => {
    useEffect(() => {
        // eslint-disable-next-line n/no-unsupported-features/node-builtins
        const cachedMid = sessionStorage.getItem('mid');
        if (cachedMid) {
            setter(cachedMid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // eslint-disable-next-line n/no-unsupported-features/node-builtins
        sessionStorage.setItem('mid', value);
    }, [value]);
};
export { useCacheMidInSessionStorage, useCancelToken, useSanitizedRouteMatch, useTokenHelper };
