import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { SwitchCheckbox } from '@klarna/bubble-ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useLabelTranslation from '../hooks/useLabelTranslation';
import useValidationErrorLabel from '../hooks/useValidationErrorLabel';
CheckboxField.propTypes = {
    name: PropTypes.string.isRequired
};
export default function CheckboxField(_a) {
    var { name } = _a, props = __rest(_a, ["name"]);
    const label = useLabelTranslation(name);
    const errorMessage = useValidationErrorLabel(name);
    const [field, , { setValue }] = useField(name);
    return (_jsx(SwitchCheckbox, Object.assign({}, field, props, { checked: field.value, error: errorMessage !== undefined, highlighted: errorMessage !== undefined, onChange: setValue, rightAligned: true, children: label })));
}
