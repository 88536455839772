import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonSecondary, SpacerHorizontal, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Chip } from '@klarna/mp-ui';
import { selectors as experimentationSelectors } from '@merchant-portal/experimentation';
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import insights from 'mage-insights';
import { thunks as stateThunks } from 'mage-state';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResetMfaConfirmationDialog from './ResetMfaConfirmationDialog';
const ManageMfaSection = ({ isCurrentUser, isMfaEnabled, onResetMfa }) => {
    const t = i18nHooks.useTranslator();
    const dispatch = useDispatch();
    const title = t('core.updateProfile.twoFactor.title');
    const subtitle = t('core.updateProfile.twoFactor.description');
    const realm = useSelector(coreSelectors.keycloakRealm);
    const [activeResetDialog, setActiveResetDialog] = useState(false);
    const disableUserManagementExperiment = useSelector(experimentationSelectors.isFeatureToggleEnabled('merchant-portal.users.disable-user-management'));
    const tokenHelper = coreHooks.useTokenHelper();
    const isProxyUser = tokenHelper.isProxyUser();
    function handleResetMfaDevice() {
        setActiveResetDialog(true);
    }
    function executeResetMfaDevice() {
        return __awaiter(this, void 0, void 0, function* () {
            yield onResetMfa();
            if (isCurrentUser) {
                // if a proxy user, log out
                if (isProxyUser) {
                    dispatch(stateThunks.logout());
                }
                else {
                    window.location.reload();
                }
            }
            insights.event({
                category: '2fa', // Is called 2fa for historic reasons - we now refer to 2FA/TOTP/etc. as MFA
                action: 'reset-mfa-device',
                label: realm
            });
        });
    }
    function handleClose() {
        setActiveResetDialog(false);
    }
    return (_jsxs("div", { children: [activeResetDialog && (_jsx(ResetMfaConfirmationDialog, { onConfirm: executeResetMfaDevice, onClose: handleClose })), _jsx(Typography, { textToken: "text-style/text/paragraphs/body/bold", children: _jsxs("span", { style: { display: 'flex' }, children: [title, _jsx(SpacerHorizontal, { spaceToken: "space/300" }), isMfaEnabled ? (_jsx(Chip, { className: "chip-mfa-enabled", label: t('core.updateProfile.twoFactor.isEnabled') })) : (_jsx(Chip, { className: "chip-mfa-disabled", hasWarning: true, label: t('core.updateProfile.twoFactor.isDisabled') }))] }) }), _jsx(Typography, { textToken: "text-style/text/paragraphs/body/regular", children: subtitle }), _jsx(SpacerVertical, { spaceToken: "space/300" }), onResetMfa && (_jsxs("div", { style: { display: 'flex' }, children: [_jsx(ButtonSecondary, { size: "medium", disabled: disableUserManagementExperiment, onClick: handleResetMfaDevice, children: t('core.updateProfile.twoFactor.reset') }), _jsx(SpacerHorizontal, { spaceToken: "space/100" })] }))] }));
};
ManageMfaSection.propTypes = {
    isCurrentUser: PropTypes.bool,
    isMfaEnabled: PropTypes.bool,
    onResetMfa: PropTypes.func
};
ManageMfaSection.defaultProps = {
    isMfaEnabled: false
};
export default ManageMfaSection;
