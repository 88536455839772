import React from 'react'
import { hooks } from 'mage-i18n'
import { Typography, SpacerVertical } from '@klarna/bubble-ui'

interface DefaultBotMessageProps {
  translationKey: string
}

interface ParagraphProps {
  text: string
  index: number
}

const Paragraph = ({ text, index }: ParagraphProps) => (
  <>
    {index > 0 && <SpacerVertical spaceToken='space/100' />}
    <Typography textToken='text-style/text/super-paragraphs/body/regular'>
      {text}
    </Typography>
  </>
)

const DefaultBotMessage = ({ translationKey }: DefaultBotMessageProps) => {
  const t: any = hooks.useTranslator()
  const text = t(translationKey) as string
  const paragraphs = text
    .split('\n')
    .map((text, i) => <Paragraph text={text} key={i} index={i} />)

  return <>{paragraphs}</>
}

export default DefaultBotMessage
