import { __awaiter } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Field, SelectorDirect, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Divider, Modal } from '@klarna/mp-ui';
import { actions, ManageMfaSection, StepUpAuthenticationContent, useAuthStatus } from 'mage-components';
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import { thunks as stateThunks } from 'mage-state';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const styleSheet = {
    row: {
        display: 'flex'
    },
    col: {
        flex: 1
    },
    'col:last-child': {
        marginLeft: 20
    }
};
const SectionTitle = (props) => {
    const { translationKey } = props;
    const t = i18nHooks.useTranslator();
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: styleSheet.row, children: _jsx(Typography, { textToken: "text-style/headings/titles/bold/tertiary", children: t(`core.updateProfile.${translationKey}`) }) }), _jsx(SpacerVertical, { spaceToken: "space/200" })] }));
};
const Security = (props) => {
    const { userId } = props;
    const dispatch = useDispatch();
    const t = i18nHooks.useTranslator();
    const isMfaEnabled = useSelector(coreSelectors.isRequesterMfaSetupInitiated);
    const createBackendClient = useSelector(coreSelectors.createBackendClient);
    const emberClient = createBackendClient('users');
    const resetPassword = userId => dispatch(actions.resetPassword(userId));
    const [hasResetPassword, setHasResetPassword] = useState(false);
    const [mfaError, setMfaError] = useState(undefined);
    const [passwordResetError, setPasswordResetError] = useState(undefined);
    const handlePasswordReset = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield resetPassword(userId);
            setHasResetPassword(true);
        }
        catch (_a) {
            setPasswordResetError(t('core.updateProfile.resetPasswordErrorLabel'));
            setHasResetPassword(false);
        }
    });
    const resetMfa = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield emberClient.post(`/users/${userId}/reset-mfa`);
        }
        catch (_error) {
            setMfaError(t('core.updateProfile.mfaError'));
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(SectionTitle, { translationKey: "securitySectionTitle" }), _jsxs("div", { children: [_jsx(SelectorDirect, { disabled: hasResetPassword, id: "update-profile-reset-password", onSelect: handlePasswordReset, options: [
                            {
                                label: t('core.updateProfile.resetPasswordLabel'),
                                selectedValue: t('core.updateProfile.resetPasswordSelectedValue'),
                                value: 'reset-password'
                            }
                        ] }), hasResetPassword && (_jsxs(_Fragment, { children: [_jsx(Typography, { colorToken: "colors/text/success", textToken: "text-style/text/super-paragraphs/default/regular", children: t('core.updateProfile.resetPasswordSuccessLabel') }), _jsx(SpacerVertical, { spaceToken: "space/200" })] })), passwordResetError && (_jsxs(_Fragment, { children: [_jsx(Typography, { colorToken: "colors/text/error", textToken: "text-style/text/super-paragraphs/default/regular", children: passwordResetError }), _jsx(SpacerVertical, { spaceToken: "space/200" })] }))] }), _jsxs("div", { children: [_jsx(Divider, {}), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(ManageMfaSection, { isCurrentUser: true, isMfaEnabled: isMfaEnabled, onResetMfa: resetMfa }), mfaError && (_jsxs(_Fragment, { children: [_jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(Typography, { colorToken: "colors/text/error", textToken: "text-style/text/super-paragraphs/default/regular", children: mfaError })] }))] })] }));
};
const UpdateProfile = ({ onClose }) => {
    const dispatch = useDispatch();
    const t = i18nHooks.useTranslator();
    const isUpdateProfileLoading = useSelector(coreSelectors.isUpdateProfileLoading);
    const isUpdateProfileFailed = useSelector(coreSelectors.isUpdateProfileFailed);
    const { isSetUpRequired } = useAuthStatus();
    const tokenHelper = coreHooks.useTokenHelper();
    const userId = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getUserId();
    const email = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getEmail();
    const givenName = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getGivenName();
    const familyName = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getFamilyName();
    const saveDetails = (userId, details) => dispatch(actions.saveDetails(userId, details));
    const refreshToken = options => dispatch(stateThunks.refreshToken(options));
    const [givenNameState, updateGivenName] = useState(givenName);
    const [familyNameState, updateFamilyName] = useState(familyName);
    const triggerCookieModal = () => {
        // @ts-expect-error: FIXME - add these to 'global'
        if (!window.OneTrust)
            window.triggerGtmEvent('OneTrustEnabled');
        // @ts-expect-error: FIXME - add this to 'global'
        window.OneTrust.ToggleInfoDisplay();
        onClose();
    };
    return (_jsxs(Modal, { id: "update-profile", onClose: onClose, title: t('core.updateProfile.title'), generalError: isUpdateProfileFailed ? t('core.updateProfile.error') : undefined, buttonPrimary: {
            onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    yield saveDetails(userId, {
                        given_name: givenNameState,
                        family_name: familyNameState
                    });
                    yield refreshToken({ force: true });
                    onClose();
                }
                catch (_err) {
                    /* empty */
                }
            }),
            text: t('core.updateProfile.submit'),
            loading: isUpdateProfileLoading
        }, children: [_jsxs("form", { children: [_jsx(Field, { disabled: true, label: t('core.updateProfile.emailLabel'), name: "email", value: email, isUnderlined: true }), _jsx(SpacerVertical, { spaceToken: "space/100" }), _jsxs("div", { style: styleSheet.row, children: [_jsx("div", { style: styleSheet.col, children: _jsx(Field, { label: t('core.updateProfile.firstNameLabel'), name: "given_name", value: givenNameState, onChange: updateGivenName, isUnderlined: true }) }), _jsx("div", { style: Object.assign(Object.assign({}, styleSheet.col), styleSheet['col:last-child']), children: _jsx(Field, { label: t('core.updateProfile.lastNameLabel'), name: "family_name", value: familyNameState, onChange: updateFamilyName, isUnderlined: true }) })] })] }), _jsx(SpacerVertical, { spaceToken: "space/400" }), isSetUpRequired ? (_jsxs(_Fragment, { children: [_jsx(SectionTitle, { translationKey: "accountSetUpSectionTitle" }), _jsx(StepUpAuthenticationContent, { fullWidth: true })] })) : (_jsx(Security, { userId: userId })), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsxs("div", { children: [_jsx(Divider, {}), _jsx(SpacerVertical, { spaceToken: "space/400" }), _jsx(SectionTitle, { translationKey: "preferencesSectionTitle" }), _jsx("div", { style: styleSheet.row, children: _jsx(SelectorDirect, { id: "update-profile-cookie-policy", onSelect: triggerCookieModal, options: [
                                {
                                    label: t('core.updateProfile.changeCookiePolicyLabel'),
                                    selectedValue: t('core.updateProfile.changeCookiePolicySelectedValue'),
                                    value: 'cookie-policy'
                                }
                            ] }) })] }), _jsx(Divider, {})] }));
};
export default UpdateProfile;
