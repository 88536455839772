import React from 'react'
import { BotEvent } from '../types'
import { useHelpbotState } from '../contexts/HelpbotStateProvider'
import { HelpbotStep } from './HelpbotStep'

const Helpbot = () => {
  const { userPath } = useHelpbotState()

  return (
    <div style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'auto' }}>
      {userPath.slice().reverse().map((nodeId: BotEvent) => {
        return (
          <div key={nodeId}>
            <HelpbotStep nodeId={nodeId} />
          </div>
        )
      })}
    </div>
  )
}

export { Helpbot }
