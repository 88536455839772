import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Divider, useGetToken } from '@klarna/mp-ui';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useDesktop, useTablet } from '../../../hooks/useMediaQuery';
import { SIDEBAR_DEFAULT_WIDTH, SIDEBAR_TABLET_WIDTH, zIndex } from '../constants';
import { useSidePanelContext } from '../Contexts/SidePanelContext';
import HambugerMenu from './HamburgerMenu';
import Menu from './Menu';
import { useSidebarContext, useToggleSidebarHandler } from './SidebarContext';
const SidebarContainerStyled = styled.div(({ isOpen, hideTopBar }) => {
    const { hasTabs } = useSidePanelContext();
    const tabletMediaQuery = useTablet();
    const marginTopMobile = hasTabs
        ? useGetToken('space/800').value() * 2
        : useGetToken('space/800').value();
    return css({
        boxSizing: 'border-box',
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        gridArea: 'sidebar',
        pointerEvents: isOpen ? 'all' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: zIndex.sidebarContainer,
        [tabletMediaQuery]: {
            marginTop: hideTopBar ? undefined : marginTopMobile,
            pointerEvents: 'initial'
        }
    });
});
const SidebarMobileBackground = styled.div(({ isOpen }) => {
    const backgroundColor = useGetToken('colors/backgrounds/subtle-inline').toString();
    const tabletMediaQuery = useTablet();
    const desktopMediaQuery = useDesktop();
    return css({
        backgroundColor,
        bottom: 0,
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        transform: isOpen ? 'translateX(0px)' : `translateX(-${SIDEBAR_DEFAULT_WIDTH}px)`,
        transition: 'transform 200ms ease-out',
        zIndex: zIndex.sidebar.mobileBackground,
        [tabletMediaQuery]: {
            transform: 'none',
            transition: 'none'
        },
        [desktopMediaQuery]: {
            display: 'none'
        }
    });
});
const SidebarStyled = styled.nav(({ isOpen }) => {
    const tabletMediaQuery = useTablet();
    const desktopMediaQuery = useDesktop();
    const marginTopMobile = useGetToken('space/1000').value();
    const marginTop = useGetToken('space/150').value();
    const paddingX = useGetToken('space/150').value();
    const paddingY = useGetToken('space/200').value();
    const scrollbarColor = useGetToken('colors/backgrounds/subtle-floating').toString();
    return css({
        boxSizing: 'border-box',
        bottom: 0,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginTop: marginTopMobile,
        opacity: isOpen ? 1 : 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollBehavior: 'smooth',
        padding: `0 ${paddingX}px ${paddingY}px ${paddingX}px`,
        width: isOpen ? SIDEBAR_DEFAULT_WIDTH : SIDEBAR_TABLET_WIDTH,
        position: 'relative',
        transform: isOpen ? 'translateX(0px)' : `translateX(-${SIDEBAR_DEFAULT_WIDTH}px)`,
        transition: 'transform 220ms ease-out',
        zIndex: zIndex.sidebar.menu,
        scrollbarWidth: 'thin',
        // Fix: Scrollbar in webkit
        '::-webkit-scrollbar': {
            width: 5,
            borderRadius: 20,
            background: 'transparent'
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: scrollbarColor,
            borderRadius: 20
        },
        [tabletMediaQuery]: {
            marginTop,
            transform: 'none',
            transition: 'width 150ms ease-out',
            opacity: 1
        },
        [desktopMediaQuery]: {
            opacity: 1,
            position: 'static',
            transition: 'none',
            width: SIDEBAR_DEFAULT_WIDTH
        }
    });
});
const SidebarBackdropStyled = styled.div(() => {
    const desktopMediaQuery = useDesktop();
    return css({
        background: 'transparent',
        height: '100%',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: zIndex.sidebar.mobileBackground,
        [desktopMediaQuery]: {
            display: 'none'
        }
    });
});
const MenuWrapperStyled = styled.div(() => {
    const paddingBottom = useGetToken('space/300').value();
    return css({
        paddingBottom,
        ':last-of-type': {
            paddingBottom: 0,
            marginTop: 'auto',
            marginBottom: 0
        }
    });
});
const SidebarMenuSectionsStyled = styled.div({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative'
});
const ExternalAppsSectionLabelStyled = styled.div(() => {
    const paddingBottom = useGetToken('space/150').value();
    const paddingLeft = useGetToken('space/150').value();
    return css({
        paddingBottom,
        paddingLeft
    });
});
function Sidebar({ hideTopBar }) {
    const { items, isOpen, externalAppsSectionLabel } = useSidebarContext();
    const toogleSidebar = useToggleSidebarHandler();
    const { mainItems, externalItems, bottomItems } = useMemo(() => {
        const result = {
            mainItems: [],
            externalItems: [],
            bottomItems: []
        };
        items === null || items === void 0 ? void 0 : items.forEach(item => {
            if (item.isBottomItem) {
                result.bottomItems.push(item);
            }
            else if (item.isExternalApp) {
                result.externalItems.push(item);
            }
            else {
                result.mainItems.push(item);
            }
        });
        return result;
    }, [items]);
    if (!(items === null || items === void 0 ? void 0 : items.length)) {
        return null;
    }
    return (_jsxs(SidebarContainerStyled, { "data-testid": "mpui-fw-sidebar-menu", isOpen: isOpen, role: "menubar", hideTopBar: hideTopBar, children: [isOpen && (_jsx(SidebarBackdropStyled, { "data-testid": "mpui-fw-sidebar-backdrop", onClick: toogleSidebar })), _jsx(HambugerMenu, {}), _jsx(SidebarStyled, { "data-testid": "mpui-fw-sidebar", isOpen: isOpen, children: _jsxs(SidebarMenuSectionsStyled, { children: [_jsx(MenuWrapperStyled, { "data-testid": "mpui-fw-sidebar-main-items", role: "group", children: _jsx(Menu, { items: mainItems, isOpen: isOpen }) }), externalItems.length > 0 && (_jsxs(MenuWrapperStyled, { "data-testid": "mpui-fw-sidebar-external-apps", role: "group", children: [isOpen && (_jsx(ExternalAppsSectionLabelStyled, { children: _jsx(Typography, { colorToken: "colors/text/neutral-on-background", textToken: "text-style/text/paragraphs/default/bold", children: externalAppsSectionLabel }) })), _jsx(Divider, {}), !isOpen && _jsx(SpacerVertical, { spaceToken: "space/300" }), _jsx(Menu, { items: externalItems, isOpen: isOpen })] })), bottomItems.length > 0 && (_jsx(MenuWrapperStyled, { "data-testid": "mpui-fw-sidebar-bottom-items", role: "group", children: _jsx(Menu, { items: bottomItems, isOpen: isOpen }) }))] }) }), _jsx(SidebarMobileBackground, { isOpen: isOpen })] }));
}
export default Sidebar;
