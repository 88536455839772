import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Field as BubbleField } from '@klarna/bubble-ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useLabelTranslation from '../hooks/useLabelTranslation';
import useValidationErrorLabel from '../hooks/useValidationErrorLabel';
import formatErrorLabel from '../utils/formatErrorLabel';
Field.propTypes = {
    name: PropTypes.string.isRequired
};
export default function Field(_a) {
    var { name } = _a, props = __rest(_a, ["name"]);
    const label = useLabelTranslation(name);
    const errorMessage = useValidationErrorLabel(name);
    const [field, , { setValue }] = useField(name);
    return (_jsx(BubbleField, Object.assign({}, field, props, { error: errorMessage !== undefined, label: errorMessage ? formatErrorLabel(label, errorMessage) : label, onChange: setValue })));
}
