import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonPrimary, IconPadlock } from '@klarna/bubble-ui';
import useMfaLockedButton from './useMfaLockedButton';
const MfaLockedButton = (_a) => {
    var { onLockedClick, onUnlockedClick, children } = _a, props = __rest(_a, ["onLockedClick", "onUnlockedClick", "children"]);
    const { userIsMissingAuth, handleClick, MfaModal } = useMfaLockedButton({
        onLockedClick,
        onUnlockedClick
    });
    return (_jsxs(_Fragment, { children: [_jsx(ButtonPrimary, Object.assign({}, props, { size: props.size || 'medium', onClick: handleClick, icon: userIsMissingAuth ? IconPadlock : undefined, children: children })), _jsx(MfaModal, {})] }));
};
export default MfaLockedButton;
