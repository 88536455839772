import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from '@klarna/bubble-ui';
import { Error } from '@merchant-portal/framework';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
const ErrorComponent = ({ errorId }) => {
    const t = i18nHooks.useTranslator();
    const errorDescription = errorId
        ? t('core.errorTemplates.default.errorDescription', { errorId })
        : '';
    const contactContent = t('core.errorTemplates.default.contact', {
        errorId: _jsx("strong", { children: errorId }),
        supportLink: children => (_jsx(Link, { textToken: "text-style/text/paragraphs/body/medium", isUnderlined: true, href: "https://www.klarna.com/merchant-support", target: "_blank", children: children }))
    }, 
    /* Fallback if translation is not found */
    'Please try refreshing the page');
    return (_jsx(Error, { title: t('core.errorTemplates.default.title', {}, 'Unexpected Error'), description: errorDescription, contactContent: [contactContent], reloadButton: {
            label: t('core.errorTemplates.reloadButton', {}, 'Reload')
        } }));
};
ErrorComponent.propTypes = {
    errorId: PropTypes.string.isRequired
};
export default ErrorComponent;
