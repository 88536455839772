import { selectors as experimentationSelectors } from '@merchant-portal/experimentation';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
const IS_MID_SELECTOR_DISABLED_XP_KEY = 'merchant-portal.boost.pig.mid-selector.disabled';
const useShouldHideMidSelector = ({ mids, handleMidChange }) => {
    const features = useSelector(experimentationSelectors.features);
    const feature = features === null || features === void 0 ? void 0 : features[IS_MID_SELECTOR_DISABLED_XP_KEY];
    const isFeatureHidden = (feature === null || feature === void 0 ? void 0 : feature.variate_id) === 'hide';
    const hasOneMid = mids.length === 1;
    const hideMidSelector = isFeatureHidden && hasOneMid;
    const hasHandledMidChange = useRef(false);
    useEffect(() => {
        if (hideMidSelector && !hasHandledMidChange.current) {
            handleMidChange(mids[0]);
            hasHandledMidChange.current = true;
        }
    }, [hideMidSelector, handleMidChange, mids]);
    return hideMidSelector;
};
export default useShouldHideMidSelector;
