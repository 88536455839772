import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SpotHome, SpotPersonalFinances, SpotRocket } from '@klarna/bubble-ui';
import { useFeature } from '@merchant-portal/experimentation';
import { GetSupportFloatingButton, HelpContextProvider, HelpToolContainer } from '@merchant-portal/help-tool';
import { ConnectedRouter } from 'connected-react-router';
import { AccessDenied, StepUpAuthenticationContent, useAuthStatus } from 'mage-components';
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import Insights from 'mage-insights';
import { selectors as stateSelectors } from 'mage-state';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import EnvironmentIdentifier from '../EnvironmentIdentifier';
import UserMenuButton from '../Header/UserMenu';
import KuasPrompter from '../KuasPrompter';
import NotificationCenterButton from '../NotificationCenterButton';
const tabNavigationClickEvent = tabId => {
    Insights.event({
        category: `home-fe/homepage-tabs/${tabId}`,
        action: 'click'
    });
};
const getAvailableTabs = (t, { isRequesterDeepLinkUser = false } = {}) => {
    return [
        {
            id: 'store-manager',
            label: t('core.ui.tabs.home'),
            icon: SpotHome,
            onClick: tabNavigationClickEvent,
            alwaysShow: !isRequesterDeepLinkUser,
            homeApp: {
                icon: 'IconHome',
                label: t(`core.apps.home-fe.title`),
                path: '/'
            }
        },
        {
            id: 'boosters',
            label: t('core.ui.tabs.boosters'),
            icon: SpotPersonalFinances,
            onClick: tabNavigationClickEvent,
            homeApp: {
                icon: 'IconHome',
                label: t(`core.apps.boosters-home-fe.title`),
                path: '/boost'
            }
        },
        {
            id: 'growth',
            label: t('core.ui.tabs.growth'),
            icon: SpotRocket,
            onClick: tabNavigationClickEvent,
            homeApp: {
                icon: 'IconInvestmentPurchase',
                label: t(`core.apps.marketing-home-fe.title`),
                path: '/growth'
            }
        }
    ];
};
const App = ({ App, mpApps, t, history, location, footerConfig, clientId, onLogoutClick, Framework }) => {
    const tokenHelper = coreHooks.useTokenHelper();
    const currentApp = useSelector(coreSelectors.currentApp);
    const currentAppRequires2fa = currentApp.requires2fa;
    const { hasUserAuthLevel } = useAuthStatus();
    const userIsMissingAuth = !hasUserAuthLevel('krn:portal-auth:loa:2fa');
    const currentAppIsLocked = userIsMissingAuth && currentAppRequires2fa;
    const getUserAccessibleApps = useSelector(stateSelectors.getUserAccessibleApps);
    const userAccessibleApps = tokenHelper ? getUserAccessibleApps(tokenHelper) : [];
    // TODO: quick fix to handle /support and /products/bpc-poc. long-term fix, move support into its own app? products/bpc-poc will move away in the future
    const isUserAccessibleApp = userAccessibleApps.some(R.propEq(clientId, 'clientId')) ||
        clientId === 'static-pages' ||
        clientId === 'products-fe';
    const partnerData = useSelector(coreSelectors.getDistributionPartner);
    const distributionPartnerId = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getLoginSource();
    const isLoading = partnerData === undefined;
    const isRequesterDeepLinkUser = !!distributionPartnerId;
    const isNotificationCenterEnabled = useFeature('merchant-portal.growth.notification.enabled');
    useEffect(() => {
        const title = t(`core.apps.${clientId}.title`);
        if (title) {
            document.title = `${title} | Klarna Merchant Portal`;
        }
    }, [t, clientId]);
    const sidebarApps = useMemo(() => {
        const apps = mpApps.map(({ badge, clientId, icon, isBadgeBranded, isBottomItem, isExternalApp, isExternalLink, enabled, shouldMakeTabVisible, tabs, url, requires2fa }) => {
            return (enabled && {
                badge,
                icon,
                id: `sidebar-${clientId}`,
                isBadgeBranded,
                isBottomItem,
                isExternalApp,
                isExternalLink,
                itemKey: clientId,
                label: t(`core.apps.${clientId}.title`, {}, clientId),
                onClick: () => Insights.trackSidebarNavigation({ label: clientId }),
                path: url,
                shouldMakeTabVisible,
                tabs,
                requires2fa
            });
        });
        if (partnerData && partnerData.redirect_url) {
            const pspDashboardClientId = 'psp-dashboard';
            apps.push({
                id: `sidebar-${pspDashboardClientId}`,
                isExternalLink: true,
                itemKey: pspDashboardClientId,
                label: `${partnerData.name} dashboard`,
                onClick: () => Insights.trackSidebarNavigation({ label: pspDashboardClientId }),
                path: partnerData.redirect_url,
                shouldMakeTabVisible: true,
                tabs: ['boosters']
            });
        }
        return apps;
    }, [mpApps, t, partnerData]);
    const availableTabs = useMemo(() => getAvailableTabs(t, { isRequesterDeepLinkUser }), [t, isRequesterDeepLinkUser]);
    const tabConfig = {
        defaultTab: 'store-manager',
        tabs: availableTabs
    };
    const sectionConfig = {
        externalAppsSectionLabel: t('home-fe.marketing-home.external-apps.title')
    };
    const sidebarConfig = useMemo(() => {
        const sidebarMenuItems = {
            items: sidebarApps
        };
        return Object.assign(Object.assign(Object.assign(Object.assign({}, tabConfig), sidebarMenuItems), sectionConfig), { userIsMissingAuth });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarApps, t]);
    const actionButtons = () => {
        const defaultActionButtons = [
            _jsx(UserMenuButton, { onLogoutClick: onLogoutClick }, "user-button")
        ];
        if (isNotificationCenterEnabled) {
            return [_jsx(NotificationCenterButton, {}, "notifications-app"), ...defaultActionButtons];
        }
        return defaultActionButtons;
    };
    return isLoading ? null : (_jsx(ConnectedRouter, { history: history, children: _jsxs(HelpContextProvider, { children: [_jsx(EnvironmentIdentifier, {}), _jsxs(Framework, { footerConfig: footerConfig, id: "mp-app-framework", sidebarConfig: sidebarConfig, sidePanel: _jsx(HelpToolContainer, {}), topBarConfig: {
                        actionButtons: actionButtons(),
                        distributionPartner: partnerData
                    }, children: [isUserAccessibleApp ? (currentAppIsLocked ? (_jsx(StepUpAuthenticationContent, {})) : (_jsx(App, { location: location }))) : (_jsx(AccessDenied, {})), _jsx(KuasPrompter, {}, "kuasPrompter")] }), _jsx(GetSupportFloatingButton, {})] }) }));
};
App.propTypes = {
    App: PropTypes.any,
    history: PropTypes.object.isRequired,
    location: PropTypes.object,
    designVersion: PropTypes.string,
    mpApps: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    footerConfig: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
    Framework: PropTypes.any.isRequired,
    onLoginClick: PropTypes.func.isRequired,
    onLogoutClick: PropTypes.func.isRequired
};
export default App;
