import React from 'react'
import { ChannelSelectorProvider } from '../../context/ChannelSelectorState'
import { ChannelDataStateProvider } from '../../context/ChannelDataStateProvider'
import ChannelSelectorPage from './ChannelSelectorPage'

const SupportChannelSelectorMain = () =>
  <ChannelDataStateProvider>
    <ChannelSelectorProvider>
      <ChannelSelectorPage />
    </ChannelSelectorProvider>
  </ChannelDataStateProvider>

export default SupportChannelSelectorMain
