import React from 'react'
import { hooks } from 'mage-i18n'
import { HelpbotOptions } from './HelpbotOptions'
import { HelpbotQuestion } from './HelpbotQuestion'
import { HelpbotResponse } from './HelpbotResponse'
import { BotStep, BotNode, BotEvent } from '../types'
import { useHelpbotState } from '../contexts/HelpbotStateProvider'

type HelpbotStepProps = {
    nodeId: BotEvent
}

const generateKey = (obj: any):string => JSON.stringify(obj)

const HelpbotStep: React.FC<HelpbotStepProps> = ({ nodeId }: HelpbotStepProps) => {
  const t = hooks.useTranslator()
  const { getBotStep } = useHelpbotState()
  const botStep: BotStep = getBotStep(nodeId)
  const botNode: BotNode = botStep.node
  const OptionsComponent = botNode.OptionsComponent

  const BotMessage = () => botNode.botMessage ? <HelpbotQuestion Message={botNode.botMessage} /> : <></>
  const BotOptions = () => OptionsComponent ? <OptionsComponent /> : <HelpbotOptions options={botNode.options()} />
  const BotResponse = () => botStep.responseValue ? <HelpbotResponse message={t(botStep.responseValue)} /> : <BotOptions />

  return (
    <div key={generateKey(botNode)}>
      <BotMessage />
      <BotResponse />
    </div>
  )
}

export { HelpbotStep }
