import * as Sentry from '@sentry/react'
import { memoizeAsync } from './utils/memoize'
import { StartConversationContext } from './types'

const MINUTE_MS = 60 * 1000
interface ConversationsMetricParams extends Pick<StartConversationContext, 'chatClient' | 'env' | 'region' | 'market'> {}

export const incrConversationsMetric = memoizeAsync(async ({ chatClient, env, region, market }: ConversationsMetricParams) => {
  try {
    await chatClient.request({
      method: 'POST',
      url: '/v1/metrics/conversations',
      region: region,
      data: { environment: env, market }
    })
  } catch (err: any) {
    if ([401, 403].includes(err?.status)) return

    Sentry.captureException(err)
  }
}, MINUTE_MS * 5)
