import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonSecondary, Frame, List, Radio, SpacerHorizontal, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Flex, Modal } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
const DEFAULT_STORENAMES_LIMIT = 30;
const RegionSelectorBar = ({ onConfirmRegion, selectedRegion, storeNamesByRegion }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRadioOption, setSelectedRadioOption] = useState(selectedRegion);
    const t = i18nHooks.useTranslator();
    const toggleModal = useCallback(() => setIsModalVisible(!isModalVisible), [isModalVisible]);
    const radioOptions = Object.keys(storeNamesByRegion).map(region => {
        const radioOption = {
            label: t(`core.regionSelector.regions.${region}`),
            value: region,
            disabled: !storeNamesByRegion[region].length
        };
        if (storeNamesByRegion[region].length) {
            const storesToDisplay = storeNamesByRegion[region]
                .slice(0, DEFAULT_STORENAMES_LIMIT)
                .join(', ');
            radioOption.description =
                storeNamesByRegion[region].length > DEFAULT_STORENAMES_LIMIT
                    ? `${storesToDisplay} ${t('core.regionSelector.andMore')}`
                    : storesToDisplay;
        }
        else {
            radioOption.description = t('core.regionSelector.noStores');
        }
        return radioOption;
    });
    return (_jsxs(_Fragment, { children: [_jsx(Frame, { backgroundColorToken: "colors/backgrounds/subtle-inline", children: _jsx(List, { children: _jsxs(Flex, { alignItems: "center", children: [_jsxs(Typography, { textToken: "text-style/text/paragraphs/body/regular", children: [t('core.regionSelectorBar.selectedRegion'), ":"] }), _jsx(SpacerHorizontal, { spaceToken: "space/100" }), _jsx(Typography, { textToken: "text-style/text/paragraphs/body/medium", children: t(`core.regionSelector.regions.${selectedRegion}`) }), _jsx(SpacerHorizontal, { spaceToken: "space/200" }), _jsx(ButtonSecondary, { onClick: toggleModal, size: "medium", children: t('core.regionSelectorBar.changeButton') })] }) }) }), _jsx(SpacerVertical, { spaceToken: "space/300" }), isModalVisible && (_jsx(Modal, { buttonPrimary: {
                    onClick: () => {
                        onConfirmRegion(selectedRadioOption);
                        toggleModal();
                    },
                    text: t('core.regionSelectorBar.modal.buttonText')
                }, description: t('core.regionSelectorBar.modal.description'), onClose: () => {
                    toggleModal();
                    setSelectedRadioOption(selectedRegion);
                }, title: t('core.regionSelectorBar.modal.title'), children: _jsx(Radio, { omitFirstLine: true, omitLastLine: true, onChange: setSelectedRadioOption, options: radioOptions, value: selectedRadioOption }) }))] }));
};
RegionSelectorBar.propTypes = {
    selectedRegion: PropTypes.oneOf(['ap', 'eu', 'us']).isRequired,
    onConfirmRegion: PropTypes.func.isRequired,
    storeNamesByRegion: PropTypes.shape({
        ap: PropTypes.arrayOf(PropTypes.string),
        eu: PropTypes.arrayOf(PropTypes.string),
        us: PropTypes.arrayOf(PropTypes.string)
    }).isRequired
};
export default RegionSelectorBar;
export { RegionSelectorBar };
