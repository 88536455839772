import { jsx as _jsx } from "react/jsx-runtime";
import { IconNotification } from '@klarna/bubble-ui';
import { TopBarActionButton } from '@merchant-portal/framework';
export const NotificationButton = ({ onClick, isToggled }) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (_jsx(TopBarActionButton, { iconOnly: true, toggled: isToggled, "data-testid": "notification-header-button", label: "", Icon: IconNotification, onClick: handleClick }));
};
