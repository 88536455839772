import { utcToZonedTime } from 'date-fns-tz'
import {
  addSeconds,
  getISODay,
  isWithinInterval,
  startOfDay
} from 'date-fns'
import { Timeslot } from '../types'
import { WorkingHour } from '../components/SupportHelpBot/types'

export const isSupportOpen = (marketWorkingHour: WorkingHour): boolean => {
  const now = new Date(Date.now())
  const timezone = marketWorkingHour.timezone
  const nowInTimeZone = new Date(utcToZonedTime(now, timezone))
  const currentWeekday = getISODay(nowInTimeZone) - 1 // 0 = Monday

  const todayTimeslots = marketWorkingHour.timeFramesByWeekday[currentWeekday]

  const isOpen = todayTimeslots.some(
    timeslot => doesDateBelongToTimeSlot(nowInTimeZone, timeslot)
  )

  return isOpen
}

const doesDateBelongToTimeSlot = (date: Date, timeslot: Timeslot) => {
  const today = startOfDay(date)
  const start = addSeconds(today, timeslot.start)
  const end = addSeconds(today, timeslot.end)
  return isWithinInterval(date, { start, end })
}
