import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColoredIllustrationEmptyState, IconAnnouncement, Link, Loader, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Flex, useGetToken } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import Insights from 'mage-insights';
import styled, { css } from 'styled-components';
import { CenteredContent, NotificationTitle } from './common';
export const NotificationLoading = () => {
    const t = i18nHooks.useTranslator();
    return (_jsxs(Flex, { style: { width: '100%' }, children: [_jsx(NotificationTitle, { children: t('core.notification.title') }), _jsx(CenteredContent, { "data-testid": "klarna-loader", children: _jsx(Loader, {}) })] }));
};
export const NotificationEmpty = () => {
    const t = i18nHooks.useTranslator();
    return (_jsxs(Flex, { style: { width: '100%' }, children: [_jsx(NotificationTitle, { children: t('core.notification.title') }), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsxs(CenteredContent, { style: { flexDirection: 'column' }, children: [_jsx(ColoredIllustrationEmptyState, { size: 80 }), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(Typography, { textToken: "text-style/text/paragraphs/default/regular", children: t('core.notification.content.uptodate') })] })] }));
};
const NotificationContainer = styled.div(() => {
    const backgroundColor = useGetToken('colors/backgrounds/subtle-inline').toString();
    const cornerRadiusS = useGetToken('corner-radius/s').value();
    return css({
        padding: '5px',
        '&:hover': {
            backgroundColor,
            borderRadius: `${cornerRadiusS}px`
        }
    });
});
export const NotificationList = ({ notifications }) => {
    const t = i18nHooks.useTranslator();
    const trackNotificationClick = category => {
        Insights.event({
            category,
            action: 'click'
        });
    };
    return (_jsxs(Flex, { children: [_jsx(NotificationTitle, { children: t('core.notification.title') }), _jsx(SpacerVertical, { spaceToken: "space/200" }), notifications.map(notification => (_jsx(NotificationContainer, { children: _jsx(Link, { onPress: () => {
                        trackNotificationClick(notification.analytics.category);
                    }, href: notification.link.href, children: _jsxs(Flex, { style: { gap: '16px', padding: '5px' }, children: [_jsx(Flex, { children: _jsx(IconAnnouncement, {}) }), _jsxs(Flex, { style: { flex: 1 }, children: [_jsx(Typography, { textToken: "text-style/text/paragraphs/default/bold", children: notification.title }), _jsx(SpacerVertical, { spaceToken: "space/100" }), _jsx(Typography, { textToken: "text-style/text/paragraphs/default/regular", children: notification.content }), _jsx(SpacerVertical, { spaceToken: "space/100" }), _jsx(Link, { isUnderlined: true, textToken: "text-style/text/paragraphs/default/regular", children: notification.link.content })] })] }) }) }, notification.title)))] }));
};
