import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { useGetToken } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { zIndex } from './constants';
import BackButton from './TopBar/BackButton';
const ContainerStyled = styled.header(() => {
    const backgroundColor = useGetToken('colors/backgrounds/default').toString();
    return css({
        position: 'sticky',
        top: 0,
        width: '100%',
        backgroundColor,
        zIndex: zIndex.appContent.pageHeader
    });
});
const HeaderStyled = styled.div(() => {
    const paddingVertical = useGetToken('space/200').value();
    return css({
        width: '100%',
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical
    });
});
function PageHeaderContainer({ title, description, backNavigation, setHeight }) {
    const [isCompact, setIsCompact] = useState(false);
    /** This is used by the parent component to update the top margin
     * of the main content when the page header collapses/expands
     */
    const containerRef = useCallback((node) => {
        if (!setHeight || !node) {
            return;
        }
        const resizeObserver = new ResizeObserver(entries => {
            const { height } = entries[0].contentRect;
            setHeight(height);
        });
        resizeObserver.observe(node);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /** This is used to detect whether the page content has scrolled
     * outside of the viewport and determine if the header should
     * collapse without listening on page scroll events
     */
    const targetRef = useCallback((node) => {
        if (!node) {
            return;
        }
        const intersectionObserver = new IntersectionObserver(entries => {
            const isElementOffViewport = !entries[0].isIntersecting;
            setIsCompact(isElementOffViewport);
        });
        intersectionObserver.observe(node);
    }, []);
    if (!title && !backNavigation) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(ContainerStyled, { ref: containerRef, children: _jsx(PageHeader, { title: title, description: description, backNavigation: backNavigation, isCompact: isCompact }) }), _jsx("div", { ref: targetRef })] }));
}
function PageHeader({ title, description, backNavigation, isCompact }) {
    return (_jsxs(HeaderStyled, { children: [backNavigation && (_jsxs(_Fragment, { children: [_jsx(BackButton, Object.assign({}, backNavigation)), _jsx(SpacerVertical, { spaceToken: isCompact ? 'space/unit' : 'space/200' })] })), _jsx("div", { "data-testid": "header-app-title", children: _jsx(Typography, { textToken: isCompact
                        ? 'text-style/headings/titles/bold/grande'
                        : 'text-style/headings/titles/bold/huge', children: title }) }), !isCompact && description && (_jsxs(_Fragment, { children: [_jsx(SpacerVertical, { spaceToken: "space/unit" }), _jsx(Typography, { textToken: "text-style/text/paragraphs/body/regular", colorToken: "colors/text/neutral", children: description })] }))] }));
}
PageHeaderContainer.propTypes = {
    backNavigation: PropTypes.shape({
        path: PropTypes.string,
        onClick: PropTypes.func,
        label: PropTypes.string.isRequired
    }),
    description: PropTypes.string,
    setHeight: PropTypes.func,
    title: PropTypes.string.isRequired
};
export default PageHeaderContainer;
export { PageHeader };
