import { jsx as _jsx } from "react/jsx-runtime";
/**
 * This is a wrapper around mp-ui Error component to allow Merchant Portal developers
 * to use templates for common errors, ensuring the consistency of messages displayed
 * to merchants and avoid duplication of translations.
 *
 * There are three ways to use it:
 * - as a normal mp-ui Error component, by passing errorProps
 * - passing a 'template' prop (currently available values: "forbidden", "notFound" and
 * "internalServerError"), to get centralized error messages
 * - not passing any template or errorProps, therefore falling back to the default template
 * (requires the 'errorId' prop to identify the error in the message provided to the user)
 */
import { IconBackNavigation } from '@klarna/bubble-ui';
import { Error as MPError } from '@merchant-portal/framework';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import { getPropsForTemplate, TEMPLATES } from './getPropsForTemplate';
const Error = ({ 
/** Error component props */
errorProps, 
/** Template */
template = null, errorId, 
/** Out of the box back and reload buttons */
hasBackButton = true, hasReloadButton = false }) => {
    const t = i18nHooks.useTranslator();
    const backButton = {
        icon: IconBackNavigation,
        label: t('core.errorTemplates.backButton'),
        onClick: () => window.history.back()
    };
    const actionButton = hasBackButton ? backButton : null;
    const reloadButton = hasReloadButton
        ? {
            label: t('core.errorTemplates.reloadButton')
        }
        : null;
    const templateProps = getPropsForTemplate({ t, template, errorId });
    return (_jsx(MPError, Object.assign({ actionButton: actionButton, reloadButton: reloadButton }, (errorProps || templateProps))));
};
Error.propTypes = {
    errorProps: PropTypes.shape({
        id: PropTypes.string,
        errorDescription: PropTypes.string,
        illustration: PropTypes.element,
        title: PropTypes.string,
        message: PropTypes.string,
        contactContent: PropTypes.arrayOf(PropTypes.string | PropTypes.node)
    }),
    template: PropTypes.oneOf(TEMPLATES),
    errorId: PropTypes.string,
    hasBackButton: PropTypes.bool,
    hasReloadButton: PropTypes.bool
};
export default Error;
