import { __awaiter } from "tslib";
import { useFeature } from '@merchant-portal/experimentation';
import { hooks as coreHooks } from 'mage-core';
import { thunks as stateThunks } from 'mage-state';
import { useDispatch } from 'react-redux';
const useAuthStatus = () => {
    const dispatch = useDispatch();
    const defaultAuthLevel = 'krn:portal-auth:loa:2fa';
    const isStepUpAuthenticationForAppsEnabled = useFeature('merchant-portal.deep-link-2fa-flow.enabled');
    const tokenHelper = coreHooks.useTokenHelper();
    const authLevel = (tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getAuthLevel()) || defaultAuthLevel;
    const setUpAuthFactors = new Set((tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getSetupAuthFactors()) || []);
    if (['krn:portal-auth:loa:1fa', 'krn:portal-auth:loa:2fa'].includes(authLevel)) {
        setUpAuthFactors.add('PASSWORD');
    }
    const hasUserAuthLevel = (expectedAuthLevel) => isStepUpAuthenticationForAppsEnabled ? authLevel === expectedAuthLevel : true;
    const completeAuthentication = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (acr = {
        values: [defaultAuthLevel],
        essential: true
    }) { return dispatch(stateThunks.login({ acr })); });
    return {
        authLevel,
        completeAuthentication,
        hasUserAuthLevel,
        isSetUpRequired: setUpAuthFactors.size < 2,
        setUpAuthFactors: Array.from(setUpAuthFactors)
    };
};
export default useAuthStatus;
