import * as R from 'ramda'

const isNotNil = R.complement(R.isNil)

const isNotEmpty = R.complement(R.isEmpty)

const includesOneOf = items => R.pipe(R.intersection(items), isNotEmpty)

const cleanObject = R.filter(isNotNil)

const renameKeys = R.curry((keysMap: object, obj: object) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj))
)

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

// taken from https://github.com/redux-utilities/redux-promise/blob/v0.5.1/src/index.js
const reduxPromise =
  ({ dispatch }) =>
  next =>
  action => {
    return action.payload && typeof action.payload.then === 'function'
      ? action.payload.then(
          result => dispatch({ ...action, payload: result }),
          error => dispatch({ ...action, payload: error, error: true })
        )
      : next(action)
  }

export { cleanObject, includesOneOf, isNotEmpty, isNotNil, reduxPromise, renameKeys, sleep }
