import { jsx as _jsx } from "react/jsx-runtime";
import { createStorage } from 'mage-common';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
const Context = createContext([
    {},
    () => {
        console.error('FeatureHighlightInfo context not initialized');
    }
]);
export function Provider({ children }) {
    const [infos, setInfos] = useState({});
    const updateInfo = useCallback((key, value) => {
        setInfos(previous => (Object.assign(Object.assign({}, previous), { [key]: value })));
    }, [setInfos]);
    return _jsx(Context.Provider, { value: [infos, updateInfo], children: children });
}
export function useFeatureHighlightInfo(storageId) {
    const [infos, _updateFeatureHighlightInfo] = useContext(Context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const localStorage = useMemo(() => createStorage(), [createStorage]);
    const featureHighlightAlreadyShown = !!localStorage.get(storageId);
    const shouldHighlightShow = useMemo(() => { var _a; return (_a = infos[storageId]) !== null && _a !== void 0 ? _a : false; }, [infos, storageId]);
    const updateFeatureHighlightInfo = useCallback(value => {
        _updateFeatureHighlightInfo(storageId, value);
    }, [_updateFeatureHighlightInfo, storageId]);
    return [shouldHighlightShow, featureHighlightAlreadyShown, updateFeatureHighlightInfo];
}
export default Context;
