import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertBlockError, SpacerVertical, SwitchToggle } from '@klarna/bubble-ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useLabelTranslation from '../hooks/useLabelTranslation';
import useValidationErrorLabel from '../hooks/useValidationErrorLabel';
ToggleField.propTypes = {
    name: PropTypes.string.isRequired
};
export default function ToggleField(_a) {
    var { name } = _a, props = __rest(_a, ["name"]);
    const label = useLabelTranslation(name);
    const errorMessage = useValidationErrorLabel(name);
    const [field, , { setValue }] = useField(name);
    return (_jsxs(_Fragment, { children: [_jsx(SwitchToggle, Object.assign({}, field, props, { checked: field.value, onChange: setValue, rightAligned: true, children: label })), errorMessage && (_jsxs(_Fragment, { children: [_jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(AlertBlockError, { content: [errorMessage] })] }))] }));
}
