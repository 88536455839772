import * as utils from '../utils'

const adminRoles = [
  'merchant-admin', // admin role for merchants
  'klarna-admin', // admin role for klarna staff
  'klarna-merchant-support' // merchant-support role for klarna staff (contains some admin privileges)
]

const isAdmin = tokenHelper => {
  return utils.includesOneOf(adminRoles)(tokenHelper.getRolesForAllEntities('merchant'))
}

const isAdminForMid = tokenHelper => mid => {
  return utils.includesOneOf(adminRoles)(tokenHelper.getRolesForEntity('merchant', mid))
}

const isKlarnaUser = tokenHelper => {
  return tokenHelper.hasRole('klarna-user')
}

const isNkoAdmin = tokenHelper => {
  return tokenHelper.hasRoleForAnyEntity('estore', 'nko-admin')
}

const isNkoUser = tokenHelper => {
  return utils.isNotEmpty(tokenHelper.getEids())
}

export { isAdmin, isAdminForMid, isKlarnaUser, isNkoAdmin, isNkoUser }
