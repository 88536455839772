import { ChatClient } from 'mage-conversation/src/types'
import * as Sentry from '@sentry/react'

export const generateRecordUserRestoreId = (chatClient: ChatClient) => (restoreId: string, region: string) => {
  try {
    chatClient.request({
      method: 'POST',
      url: '/v1/chat/restore-id',
      region,
      data: { restore_id: restoreId }
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}
