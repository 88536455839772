import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/da'
import '@formatjs/intl-pluralrules/locale-data/de'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/es'
import '@formatjs/intl-pluralrules/locale-data/fi'
import '@formatjs/intl-pluralrules/locale-data/fr'
import '@formatjs/intl-pluralrules/locale-data/it'
import '@formatjs/intl-pluralrules/locale-data/nl'
import '@formatjs/intl-pluralrules/locale-data/no'
import '@formatjs/intl-pluralrules/locale-data/sv'

import * as Sentry from '@sentry/react'
import dotProp from 'dot-prop-immutable'
import IntlMessageFormat from 'intl-messageformat'
import * as R from 'ramda'
import { createSelector } from 'reselect'

import { NAME } from './constants'

const getState = R.prop(NAME)

const translations = R.pipe(getState, R.prop('translations'))

const language = R.pipe(getState, R.prop('language'))

const country = R.pipe(getState, R.prop('country'))

const countries = R.pipe(getState, R.prop('countries'))

const locale = createSelector(language, country, (language, country) => {
  return country ? `${language}-${country.toUpperCase()}` : language
})

// mapping of translated language name (i.e. the Transifex filenames) to actual language, if they're different
const translatedLanguages = {
  no: ['nb', 'nn']
}

const getTranslatedLanguage = language =>
  R.pipe(
    R.pickBy(R.includes(language)),
    R.keys,
    R.head,
    R.ifElse(R.isNil, R.always(language), R.identity)
  )

const getTranslationString = (path, translations) => language =>
  dotProp.get(translations, `${language}.${path}`)

const translatorFactory = createSelector(translations, translations =>
  R.memoizeWith(R.identity, locale => (path, params = {}, fallbackString = '') => {
    const localeLanguage = (locale || '').substring(0, 2)
    const translatedLanguage = getTranslatedLanguage(localeLanguage)(translatedLanguages)
    const languages = R.uniq([localeLanguage, translatedLanguage, 'en'])
    const translationString = R.pipe(
      R.map(getTranslationString(path, translations)),
      R.find(R.identity)
    )(languages)
    const message = R.or(translationString, fallbackString)
    try {
      return new IntlMessageFormat(message, locale).format(params)
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setExtras({ message, locale, path })
        Sentry.captureException(error)
      })
      return fallbackString
    }
  })
)

const translator = createSelector(translatorFactory, locale, (factory, locale) => factory(locale))

const formatters = {
  number: createSelector(locale, locale => {
    return (
      options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false
      },
      withGrouping = false
    ) => {
      if (withGrouping) {
        options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      }
      return new Intl.NumberFormat(locale, options)
    }
  }),
  currency: createSelector(locale, locale => {
    return (currency, hideDecimals) => {
      return new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        currencyDisplay: 'symbol',
        ...(hideDecimals && {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      })
    }
  }),
  datetime: createSelector(locale, locale => {
    return new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    })
  }),
  date: createSelector(locale, locale => {
    return new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    })
  }),
  longDateNoYear: createSelector(locale, locale => {
    return new Intl.DateTimeFormat(locale, {
      month: 'long',
      day: 'numeric'
    })
  }),
  time: createSelector(locale, locale => {
    return new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric'
    })
  }),
  utcdate: createSelector(locale, locale => {
    return new Intl.DateTimeFormat(locale, {
      timeZone: 'UTC',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    })
  })
}

const decimalSeparator = createSelector(formatters.number, formatter =>
  formatter().format(1.1).substring(1, 2)
)

const createRegexSelector = allowNegative =>
  createSelector(decimalSeparator, decSep => {
    if (decSep === '.') {
      decSep = '\\.'
    }
    return `^${allowNegative ? '[-−]?' : ''}[0-9]+${decSep}?[0-9]{0,2}$`
  })

const numberRegex = createRegexSelector(true)

const positiveNumberRegex = createRegexSelector(false)

const parsers = {
  numberRegex,
  positiveNumberRegex,
  // Parse minor units into major units
  fromMinorUnits: () => R.divide(R.__, 100),
  // Parse major units into minor units
  fromMajorUnits: () => R.pipe(R.multiply(100), Math.round),
  // Parse possible string value into a number
  parseNumber: createSelector(decimalSeparator, numberRegex, (decSep, numberRegex) => number => {
    if (typeof number === 'number') {
      return number
    }

    if (new RegExp(numberRegex).test(number)) {
      number = number.replace(decSep, '.').replace('−', '-')
      return Number(number)
    }
    return NaN
  })
}

export {
  countries,
  country,
  formatters,
  language,
  locale,
  parsers,
  translations,
  translator,
  translatorFactory
}
