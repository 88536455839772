import React from 'react'
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage'
import { BotNodeId, HelpbotFlow, AnswerOption } from '../../../types'
import { INeedMoreHelpThatHelped } from '../../common'

export const entrypoint: AnswerOption = {
  value: 'helpbot.orders.paymentsForMyOrders.title',
  next: BotNodeId.ORDER_PAYMENTS
}

export const flow: HelpbotFlow = [
  {
    id: BotNodeId.ORDER_PAYMENTS,
    botMessage: null,
    options: () => [
      {
        value: 'helpbot.orders.paymentsForMyOrders.whenWillIGetPaid.question',
        next: BotNodeId.ORDER_PAYMENTS_WHEN_WILL_I_GET_PAID
      },
      {
        value: 'helpbot.orders.paymentsForMyOrders.refundOrderReceivedPayment.question',
        next: BotNodeId.ORDER_PAYMENTS_REFUND_ORDER_RECEIVED_PAYMENT
      },
      {
        value: 'helpbot.orders.paymentsForMyOrders.referenceNotShowUp.question',
        next: BotNodeId.ORDER_PAYMENTS_REFERENCE_NOT_SHOW_UP
      },
      {
        value: 'helpbot.orders.paymentsForMyOrders.gotPaidSpecificOrder.question',
        next: BotNodeId.ORDER_PAYMENTS_GOT_PAID_SPECIFIC_ORDER
      }
    ]
  },
  {
    id: BotNodeId.ORDER_PAYMENTS_WHEN_WILL_I_GET_PAID,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.orders.paymentsForMyOrders.whenWillIGetPaid.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.ORDER_PAYMENTS_REFUND_ORDER_RECEIVED_PAYMENT,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.orders.paymentsForMyOrders.refundOrderReceivedPayment.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.ORDER_PAYMENTS_REFERENCE_NOT_SHOW_UP,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.orders.paymentsForMyOrders.referenceNotShowUp.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.ORDER_PAYMENTS_GOT_PAID_SPECIFIC_ORDER,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.orders.paymentsForMyOrders.gotPaidSpecificOrder.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  }
]
