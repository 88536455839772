import { jsx as _jsx } from "react/jsx-runtime";
import { IconProfile } from '@klarna/bubble-ui';
import { Tooltip } from '@klarna/mp-ui';
import { TopBarActionButton } from '@merchant-portal/framework';
import { hooks as coreHooks } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
const getDisplayName = (givenName, familyName) => {
    return givenName && familyName
        ? `${givenName} ${familyName.charAt(0)}.`
        : givenName
            ? `${givenName}`
            : '?';
};
function UserMenuIcon({ toggled }) {
    const t = i18nHooks.useTranslator();
    return toggled ? (_jsx(Button, { toggled: toggled })) : (_jsx(Tooltip, { text: t('core.usermenu.tooltip'), children: _jsx(Button, { toggled: toggled }) }));
}
function Button({ toggled }) {
    const tokenHelper = coreHooks.useTokenHelper();
    const familyName = tokenHelper.getFamilyName();
    const givenName = tokenHelper.getGivenName();
    const actionButtonProps = {
        id: 'header-usermenu-icon',
        toggled: toggled,
        Icon: IconProfile,
        label: getDisplayName(givenName, familyName)
    };
    return _jsx(TopBarActionButton, Object.assign({}, actionButtonProps));
}
Button.propTypes = {
    toggled: PropTypes.bool
};
UserMenuIcon.propTypes = {
    toggled: PropTypes.bool
};
export default UserMenuIcon;
