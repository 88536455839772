import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useLabelTranslation from '../hooks/useLabelTranslation';
import useValidationErrorLabel from '../hooks/useValidationErrorLabel';
import TextArea from './TextArea';
TextAreaField.propTypes = {
    name: PropTypes.string.isRequired
};
export default function TextAreaField(_a) {
    var { name } = _a, props = __rest(_a, ["name"]);
    const label = useLabelTranslation(name);
    const errorLabel = useValidationErrorLabel(name);
    const [field, , { setValue, setTouched }] = useField(name);
    return (_jsx(TextArea, Object.assign({}, field, { label: label }, props, { testId: name, errorLabel: errorLabel, onChange: setValue, onBlur: () => setTouched(true) })));
}
