type MemoizedValue<T> = {
  lastCalled: Date,
  value: T | Promise<T>
}

export const memoizeAsync = <T>(fn: (...args: any[]) => Promise<T>, cacheTime: number): ((...args: any[]) => Promise<T>) => {
  let memoizedValue: MemoizedValue<T> | undefined

  return async (...args: any[]): Promise<T> => {
    if (!memoizedValue?.value || new Date().getTime() - memoizedValue?.lastCalled.getTime() > cacheTime) {
      const value = await fn(...args)
      memoizedValue = {
        lastCalled: new Date(),
        value
      }
    }

    return memoizedValue.value
  }
}

export const memoize = <T>(fn: (...args: any[]) => T, cacheTime: number): ((...args: any[]) => T) => {
  let memoizedValue: MemoizedValue<T> | undefined

  return (...args: any[]): T => {
    if (!memoizedValue?.value || new Date().getTime() - memoizedValue?.lastCalled.getTime() > cacheTime) {
      const value = fn(...args)
      memoizedValue = {
        lastCalled: new Date(),
        value
      }
    }

    return memoizedValue.value as T
  }
}
