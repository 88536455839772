import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import TokenHelperContext from './TokenHelperContext';
function withTokenHelper(propsCreator) {
    return (WrappedComponent) => {
        var _a, _b;
        const wrappedComponentDisplayName = (_b = (_a = WrappedComponent === null || WrappedComponent === void 0 ? void 0 : WrappedComponent.displayName) !== null && _a !== void 0 ? _a : WrappedComponent === null || WrappedComponent === void 0 ? void 0 : WrappedComponent.name) !== null && _b !== void 0 ? _b : 'Component';
        function TokenHelper(passThroughProps) {
            const tokenHelper = useContext(TokenHelperContext);
            const props = tokenHelper ? propsCreator(tokenHelper, passThroughProps) : {};
            return _jsx(WrappedComponent, Object.assign({}, passThroughProps, props));
        }
        TokenHelper.displayName = `WithTokenHelper(${wrappedComponentDisplayName})`;
        return TokenHelper;
    };
}
export default withTokenHelper;
