import { BotNodeId, UxEvent } from '../types'

export const INeedMoreHelpThatHelped = [{
  value: 'helpbot.options.iNeedMoreHelp',
  next: UxEvent.GO_TO_CHANNEL_SELECTION
},
{
  value: 'helpbot.options.thatHelped',
  next: BotNodeId.THAT_HELPED
}]
