import React, { useEffect } from 'react'
import useIsChatActive from '../../../../hooks/useIsChatActive'
import useContactChannels from '../../../../hooks/useContactChannels'
import { useChannelSelectorState } from '../../../../context/ChannelSelectorState'
import { useChannelDataState } from '../../../../context/ChannelDataStateProvider'
import FallbackLoader from '../../../FallbackLoader'
import MarketOption from './MarketOption'

interface SelectorProps {
  markets: string[]
  onMarketSelected: (countryCode: string) => void
}

const MarketSelector: React.FC<SelectorProps> = ({ markets, onMarketSelected }) => (
  <>{markets.map((code) => <MarketOption key={code} countryCode={code} onClick={() => onMarketSelected(code)} />)}</>
)

const MarketSelectorContainer: React.FC = () => {
  const chatFeatures = useIsChatActive()
  const { setChatIsLoading, getIsChatLoading } = useChannelSelectorState()
  // @ts-ignore
  const { isLoading, data: { markets } } = useChannelDataState()
  const { navigateToCreateTicketPage, startChatConversation } = useContactChannels()

  const showLoader = () => isLoading || (markets.length === 1 && getIsChatLoading(markets[0].countryCode))
  const onMarketSelected = (countryCode: string) => {
    if (chatFeatures.getIsChatActive(countryCode)) {
      startChatConversation(countryCode)
      setChatIsLoading(countryCode)
      return
    }
    navigateToCreateTicketPage()
  }

  useEffect(() => {
    if (isLoading) return
    if (!markets.length) return navigateToCreateTicketPage()
    if (markets.length === 1) {
      onMarketSelected(markets[0].countryCode)
    }
  }, [isLoading, markets])

  if (showLoader()) return <FallbackLoader />

  return (
    <>
      <MarketSelector markets={markets.map((m) => m.countryCode)} onMarketSelected={onMarketSelected} />
      {getIsChatLoading(markets[0].countryCode) && <FallbackLoader />}
    </>
  )
}

export default MarketSelectorContainer
