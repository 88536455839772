import { useChannelDataState } from '../context/ChannelDataStateProvider'
import { Market, RegionEnum } from '../components/SupportHelpBot/types'
import { isSupportOpen } from '../utilities/workingHours'

const useIsChatActive = () => {
  const { data: { markets, workingHours } } = useChannelDataState()

  const getIsChatActive = (marketCountryCode: string): boolean => {
    if (!isChatFeatureEnabled(marketCountryCode, markets)) return false

    const marketWorkingHours = workingHours.find(wh => wh.marketCountryCodes.includes(marketCountryCode))
    if (!marketWorkingHours) return false
    return isSupportOpen(marketWorkingHours)
  }

  const getWorkingHoursRegion = (marketCountryCode: string): RegionEnum | undefined =>
    workingHours.find(wh => wh.marketCountryCodes.includes(marketCountryCode))?.region

  return { getIsChatActive, getWorkingHoursRegion }
}

const isChatFeatureEnabled = (marketCountryCode: string, markets: Market[]): boolean => {
  const foundFeature = markets.find(m => m.countryCode === marketCountryCode)
  if (!foundFeature) return false

  return foundFeature.isChatFeatureEnabled
}

export default useIsChatActive
