import React from 'react'
import PropTypes from 'prop-types'
import {
  IllustrationErrorExplodingBalloon,
  AlertBlock,
  ButtonPrimary,
  SpacerVertical,
  IconBackNavigation,
  Typography
} from '@klarna/bubble-ui'
import { hooks as i18nHooks, Message } from 'mage-i18n'
import HelpContext from '../context/HelpContext'

ErrorPage.propTypes = {
  errorId: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired
}

function ErrorPage ({ errorId, clearError }) {
  const t = i18nHooks.useTranslator()

  return (
    <HelpContext.Consumer>
      {({ resetHelptool }) => (
        <div style={{ margin: 30 }}>
          <IllustrationErrorExplodingBalloon />
          <SpacerVertical spaceToken='space/200' />
          <Typography textToken='text-style/headings/titles/bold/primary'>
            {t('help-tool.errorBoundary.title', {}, 'something went wrong')}
          </Typography>
          <SpacerVertical spaceToken='space/200' />
          <Typography>
            <Message
              path='help-tool.errorBoundary.message'
              fallback='We are very sorry but there was an error. You can click the button below to reset and close this sidebar.'
            />
          </Typography>
          <SpacerVertical spaceToken='space/400' />
          <ButtonPrimary
            size='medium'
            icon={IconBackNavigation}
            onClick={() => {
              clearError()
              resetHelptool()
            }}
          >
            {t('help-tool.errorBoundary.resetButton', {}, 'Go back')}
          </ButtonPrimary>
          {errorId && (
            <>
              <SpacerVertical spaceToken='space/400' />
              <AlertBlock content={[
                <Message
                  key={errorId}
                  path='help-tool.errorBoundary.errorId'
                  params={{ errorId }}
                  fallback='Error ID: {errorId}'
                />
              ]}
              />
            </>
          )}
        </div>
      )}
    </HelpContext.Consumer>
  )
}

export default ErrorPage
