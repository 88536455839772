import axios from 'axios';
import { selectors as stateSelectors } from 'mage-state';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MERCHANT_PORTAL_API_TOKEN, MERCHANT_PORTAL_API_TOKEN_PREVIEW, MERCHANT_PORTAL_SPACE_ID } from '../constants';
import handleError from '../handleError';
import * as selectors from '../selectors';
import useCMSEnvironment from './useCMSEnvironment';
export default function useCMSRequest(resource, extraParams = {}, skip = false) {
    var _a;
    const preview = useSelector(selectors.preview);
    const cmsLocale = useSelector(stateSelectors.cmsLocale);
    const locale = (_a = extraParams.locale) !== null && _a !== void 0 ? _a : cmsLocale;
    const environment = useCMSEnvironment();
    const params = Object.assign({ locale }, extraParams);
    const stringedParams = JSON.stringify(params);
    const apiToken = preview ? MERCHANT_PORTAL_API_TOKEN_PREVIEW : MERCHANT_PORTAL_API_TOKEN;
    const [result, setResult] = useState();
    useEffect(() => {
        if (!environment)
            return;
        setResult(undefined);
        if (!skip) {
            const url = buildUrl(environment, preview, resource);
            fetch(apiToken, setResult, url, params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [environment, preview, resource, stringedParams, skip, apiToken]);
    return result;
}
function buildUrl(environment, preview, resource) {
    const baseURL = preview ? 'https://preview.contentful.com' : 'https://cdn.contentful.com';
    const space = MERCHANT_PORTAL_SPACE_ID;
    const path = `/spaces/${space}/environments/${environment}${resource}`;
    return `${baseURL}${path}`;
}
function fetch(apiToken, setResult, url, params) {
    const client = axios.create({
        timeout: 10000,
        headers: {
            Authorization: `Bearer ${apiToken}`
        }
    });
    client
        .request({ method: 'GET', url, params })
        .then(response => response.data)
        .then(setResult)
        .catch(error => {
        handleError(error);
        // Retry on rate limit failure
        if (error.response && error.response.status === 429) {
            setTimeout(() => fetch(apiToken, setResult, url, params), 1000);
        }
    });
}
