"use strict";
const R = require('ramda');
const euConfig = require('./eu');
const productionConfig = require('./production');
const clusterConfig = {
    apps: {
        'lending-fe': {
            enabled: true
        }
    }
};
module.exports = R.pipe(R.mergeDeepLeft(productionConfig), R.mergeDeepLeft(clusterConfig))(euConfig);
