import { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'

import NotFound from '../containers/NotFound'
import getPages from '../pages'

export const App = () => {
  const pages = useMemo(() => getPages(), [])

  return (
    <Switch>
      {pages}
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
