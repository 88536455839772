import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { BASE_GUTTER } from './utils/constants';
import { getSpaceFactorFromBreakpoint, getValueWithBreakpointFallback } from './utils/responsive';
import { withResponsive } from './utils/withResponsive';
const styles = (breakpoint, padding, extraStyles) => {
    const paddingFactor = getValueWithBreakpointFallback(padding, breakpoint, getSpaceFactorFromBreakpoint(breakpoint));
    const paddingValue = padding && breakpoint === 'mobile' ? paddingFactor * BASE_GUTTER : 0;
    const customStyle = {
        mobile: {
            maxWidth: '100%'
        },
        tablet: {
            maxWidth: '768px'
        },
        desktop: {
            maxWidth: '1150px'
        }
    };
    return Object.assign(Object.assign({ margin: '0 auto', width: '100%', padding: paddingValue, boxSizing: 'border-box', flex: '1 1 auto', overflow: 'hidden' }, customStyle[breakpoint]), extraStyles);
};
const Container = ({ breakpoint, padding, children, extraStyles = {} }) => {
    return (_jsx("div", { className: "mp-grid-container", style: styles(breakpoint, padding, extraStyles), children: children }));
};
Container.propTypes = {
    breakpoint: PropTypes.string.isRequired,
    children: PropTypes.any,
    extraStyles: PropTypes.object,
    padding: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};
export const GridContainer = withResponsive(Container);
