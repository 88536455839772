import React from 'react'
import ChatWidgetContainer from './components/ChatWidgetContainer'
import SupportHelpbot from '../SupportHelpBot'

const ChatWidget: React.FC = () => {
  return (
    <ChatWidgetContainer>
      <SupportHelpbot />
    </ChatWidgetContainer>
  )
}

export default ChatWidget
