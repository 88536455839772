import { routerMiddleware } from 'connected-react-router';
import { utils } from 'mage-common';
import { middleware as coreMiddleware } from 'mage-core';
import { middleware as insightsMiddleware } from 'mage-insights';
import { path } from 'ramda';
import { createLogger } from 'redux-logger';
import multi from 'redux-multi';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import mostAdapter from 'redux-observable-adapter-most';
import thunk from 'redux-thunk';
export default (state, history, createMiddlewares, epics) => {
    const env = path(['config', 'common', 'environment', 'type'], state);
    const loggingEnvs = ['development', 'staging'];
    return [
        thunk,
        utils.reduxPromise,
        multi,
        ...(typeof ENABLE_REDUX_LOGGING !== 'undefined' &&
            ENABLE_REDUX_LOGGING &&
            loggingEnvs.includes(env)
            ? [createLogger()]
            : []),
        routerMiddleware(history),
        coreMiddleware,
        ...createMiddlewares(history, state),
        insightsMiddleware,
        createEpicMiddleware(combineEpics(...epics), { adapter: mostAdapter })
    ];
};
