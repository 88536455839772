"use strict";
module.exports = {
    common: {
        environment: {
            type: 'development'
        },
        keycloak: {
            baseUrls: {
                local: 'http://localhost:8080'
            }
        }
    }
};
