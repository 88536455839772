import { RegionEnum, RegionalResponse, RestoreId, WorkingHour } from '../components/SupportHelpBot/types'

export type WorkingHourResponse = {
  timezone: string,
  market_country_codes: string[], /* eslint-disable-line camelcase */
  timeframes_by_weekday: { /* eslint-disable-line camelcase */
    start: number,
    end: number
  }[][]
}

export type WorkingHoursResponse = {
  working_hours: WorkingHourResponse[] /* eslint-disable-line camelcase */
}

const DEFAULT_RESTORE_ID = {
  eu: '',
  ap: '',
  us: ''
}

export const getTransformedRestoreId = (regionalResponses: RegionalResponse[]): RestoreId => {
  const restoreId = DEFAULT_RESTORE_ID

  regionalResponses.forEach((regionalResponse) => (
    restoreId[regionalResponse.region] = regionalResponse.response.data?.restore_id || ''
  ))

  return restoreId
}

export const getTransformedWorkingHours = (regionalResponses: RegionalResponse[]): WorkingHour[] => {
  const workingHours: WorkingHour[] = regionalResponses.map(
    ({ response, region }:RegionalResponse) => (transformWorkingHours(response.data?.working_hours, region))
  )
    .flat()
    .filter(workingHours => workingHours)

  return workingHours
}

const transformWorkingHours = (
  workingHoursInResponse: WorkingHourResponse[],
  region: RegionEnum
): WorkingHour[] => (
  workingHoursInResponse
    ? workingHoursInResponse.map(transformWorkingHour(region)).filter(filterWorkingHours)
    : []
)

const transformWorkingHour = (region: RegionEnum) => (workingHour: WorkingHourResponse): WorkingHour => ({
  marketCountryCodes: workingHour.market_country_codes,
  timeFramesByWeekday: workingHour.timeframes_by_weekday,
  timezone: workingHour.timezone,
  region: region
})

const filterWorkingHours = (workingHour: WorkingHour) => (workingHour.marketCountryCodes.length > 0)
