import React from 'react'
import { AnswerOption } from '../types'
import { HelpbotOption } from './HelpbotOption'

interface HelpbotOptionsProps {
  options: AnswerOption []
}

const HelpbotOptions: React.FC<HelpbotOptionsProps> =
  ({ options }: HelpbotOptionsProps) =>
    <>
      {options.map((o) => <HelpbotOption key={o.value.toString()} option={o} />)}
    </>

export { HelpbotOptions }
