"use strict";
module.exports = {
    common: {
        environment: {
            type: 'staging'
        },
        keycloak: {
            baseUrls: {
                ap: 'https://keycloak.staging.ap1.meta.klarna.net',
                eu: 'https://keycloak.staging.eu1.meta.klarna.net',
                us: 'https://keycloak.staging.us1.meta.klarna.net'
            }
        },
        insights: {
            googleAnalytics: {
                cookieDomain: 'auto',
                tagId: 'G-9NTG2M13FJ'
            }
        },
        merchantPortalBaseUrl: 'https://portal.nonprod.klarna.net',
        frontendEventRouterBaseUrls: {
            eu: 'https://frontend-event-router-eu.staging.c2c.klarna.net',
            ap: 'https://frontend-event-router-ap.staging.c2c.klarna.net',
            us: 'https://frontend-event-router-us.staging.c2c.klarna.net'
        },
        nko: {
            baseUrl: 'https://nko-server-eu.staging.c2c.klarna.net'
        }
    }
};
