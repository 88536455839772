import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import React from 'react';
import FieldValidationContext from '../contexts/FieldValidationContext';
FieldValidationProvider.propTypes = {
    forceShowValidationErrors: PropTypes.bool,
    validationErrors: PropTypes.object,
    children: PropTypes.node
};
export default function FieldValidationProvider({ forceShowValidationErrors, validationErrors, children }) {
    return (_jsx(FieldValidationContext.Provider, { value: { forceShowValidationErrors, validationErrors }, children: children }));
}
