import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as s from '../selectors';
export function Message({ t, path, params = {}, fallback = '', dangerous }) {
    const translation = t(path, params, fallback);
    if (!dangerous)
        return translation;
    return _jsx("span", { dangerouslySetInnerHTML: { __html: translation } });
}
Message.propTypes = {
    t: PropTypes.func,
    path: PropTypes.string.isRequired,
    params: PropTypes.object,
    fallback: PropTypes.string,
    dangerous: PropTypes.bool
};
export default connect(state => ({ t: s.translator(state) }))(Message);
