import * as R from 'ramda'

const buildUrlPath = (...paths: string[]) => {
  const urlPath = R.pipe(R.reject<string>(R.isNil), R.map(kebabify), R.join('/'))(paths)
  return `/${urlPath}`
}

const formatMidStore = ({ merchantId, storeName }: { merchantId?: string; storeName?: string }) =>
  storeName ? `${storeName} (${merchantId})` : merchantId

const getContentUrl = (contentType: string, region = 'eu', language = 'en') => {
  contentType = contentType.toLowerCase()
  language = language.toLowerCase()
  region = region.toLowerCase()

  // For easier region testing
  if (region === 'local') region = 'eu'

  try {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    return require(`./content/${region}/${contentType}-${language}.md`)
  } catch {
    try {
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      return require(`./content/${region}/${contentType}-en.md`)
    } catch {
      throw new Error(
        `Static file does not exist: ./content/${region}/${contentType}-${language}.md`
      )
    }
  }
}

const getCorrelationIdFromError = (error: {
  config?: { headers?: { 'Klarna-Correlation-Id'?: string } }
}) => {
  return R.path(['config', 'headers', 'Klarna-Correlation-Id'], error)
}

const SUPPORT_TICKET_STATUS_LABEL_MAP = {
  inProgress: 'warning',
  open: 'warning',
  pending: 'warning',
  waitingForYourReply: 'warning'
}

const getStatusLabel = (status: keyof typeof SUPPORT_TICKET_STATUS_LABEL_MAP) =>
  SUPPORT_TICKET_STATUS_LABEL_MAP[status]

const kebabify = R.pipe(R.replace(/([a-z])([A-Z])/g, '$1-$2'), R.toLower)

export {
  buildUrlPath,
  formatMidStore,
  getContentUrl,
  getCorrelationIdFromError,
  getStatusLabel,
  kebabify
}
