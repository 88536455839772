import { ButtonPrimary, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Dialog } from 'mage-components';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
const T_PREFIX = 'credentials.verificationEmailModal';
const FullWidthButton = styled(ButtonPrimary) `
  width: 100%;
`;
function VerificationEmailModal({ onSendEmailClick, merchantId }) {
    const t = i18nHooks.useTranslator();
    return (React.createElement(Dialog, null,
        React.createElement(Typography, { textToken: "text-style/headings/titles/bold/primary", align: "center", colorToken: 'colors/text/default' }, t(`${T_PREFIX}.title`)),
        React.createElement(SpacerVertical, { spaceToken: "space/200" }),
        React.createElement(Typography, { textToken: "text-style/text/paragraphs/grande/regular", align: "center" }, t(`${T_PREFIX}.description`)),
        React.createElement(SpacerVertical, { spaceToken: "space/100" }),
        React.createElement(Typography, { textToken: "text-style/text/paragraphs/tiny/regular", align: "center", colorToken: 'colors/text/neutral' }, t(`${T_PREFIX}.agreement`, { store_name: merchantId })),
        React.createElement(SpacerVertical, { spaceToken: "space/200" }),
        React.createElement(FullWidthButton, { size: "medium", onClick: onSendEmailClick }, t(`${T_PREFIX}.sendEmailButton`))));
}
export default VerificationEmailModal;
