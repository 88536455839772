import React from 'react'
import { BotNodeId, HelpbotFlow } from '../../../types'
import { INeedMoreHelpThatHelped } from '../../common'
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage'

const generatingReportsFlow: HelpbotFlow = [
  {
    id: BotNodeId.SETTLEMENTS_GENERATING_REPORTS,
    botMessage: null,
    options: () => [
      {
        value: 'helpbot.settlements.generatingReports.howToSeeReports.question',
        next: BotNodeId.SETTLEMENTS_GENERATING_REPORTS_SEE_REPORTS
      },
      {
        value:
          'helpbot.settlements.generatingReports.convertCsvToExcel.question',
        next: BotNodeId.SETTLEMENTS_GENERATING_REPORTS_CONVERT_CSV_TO_EXCEL
      }
    ]
  },
  {
    id: BotNodeId.SETTLEMENTS_GENERATING_REPORTS_SEE_REPORTS,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.settlements.generatingReports.howToSeeReports.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.SETTLEMENTS_GENERATING_REPORTS_CONVERT_CSV_TO_EXCEL,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.settlements.generatingReports.convertCsvToExcel.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  }
]

export default generatingReportsFlow
