import { jsx as _jsx } from "react/jsx-runtime";
import { Portal, useGetToken } from '@klarna/mp-ui';
import { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useDesktop, useTablet } from '../../../hooks/useMediaQuery';
import { SIDEBAR_SUB_MENU_POPOVER_WIDTH, SIDEBAR_TABLET_WIDTH, zIndex } from '../constants';
import MenuItem from './MenuItem';
export const MenuStyled = styled.ul({
    display: 'block',
    padding: 0,
    margin: 0,
    listStyle: 'none'
});
const revealAnimation = () => {
    const frame = keyframes({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    });
    return css `
    ${frame} 250ms
  `;
};
const SubMenuWrapperStyled = styled(MenuStyled)(({ isOpen }) => {
    const paddingLeft = useGetToken('space/400').value();
    const tabletMediaQuery = useTablet();
    const desktopMediaQuery = useDesktop();
    return css({
        paddingLeft,
        [tabletMediaQuery]: {
            animation: revealAnimation,
            animationDelay: '150ms',
            animationFillMode: 'forwards',
            display: isOpen ? 'block' : 'none',
            opacity: 0
        },
        [desktopMediaQuery]: {
            opacity: 1,
            display: 'block',
            animation: 'none'
        }
    });
});
export function SubMenu({ items, isOpen }) {
    return (_jsx(SubMenuWrapperStyled, { "aria-label": "submenu", "data-testid": "mpui-fw-sidebar-submenu", isOpen: isOpen, children: items.map(item => (_jsx(MenuItem, Object.assign({}, item, { isExpanded: true }), item.id))) }));
}
const ContainerStyled = styled(MenuStyled)(({ top = 0 }) => {
    const padding = useGetToken('space/300').value();
    const borderRadius = useGetToken('corner-radius/s').value();
    const backgroundColor = useGetToken('colors/backgrounds/default').toString();
    const boxShadowColor = useGetToken('colors/backgrounds/inverse');
    boxShadowColor.alpha = 0.4;
    const tabletMediaQuery = useTablet();
    const desktopMediaQuery = useDesktop();
    return css({
        display: 'none',
        [tabletMediaQuery]: {
            display: 'block',
            animation: revealAnimation,
            animationFillMode: 'forwards',
            opacity: 0,
            backgroundColor,
            borderRadius,
            boxShadow: `0px 6px 12px 0px ${boxShadowColor}`,
            left: SIDEBAR_TABLET_WIDTH,
            padding,
            position: 'fixed',
            top: 0,
            transform: `translate3d(0, ${top}px, 0)`,
            width: SIDEBAR_SUB_MENU_POPOVER_WIDTH,
            zIndex: zIndex.sidebar.subMenu
        },
        [desktopMediaQuery]: {
            display: 'none'
        }
    });
});
export function SubMenuPopover({ items, top, onMouseOver, onMouseLeave }) {
    const containerRef = useRef();
    useEffect(() => {
        if (containerRef.current) {
            const offset = 20;
            const { bottom, height } = containerRef.current.getBoundingClientRect();
            if (window && bottom > window.innerHeight) {
                const transformY = window.innerHeight - height - offset;
                containerRef.current.style.transform = `translate3d(0, ${transformY}px, 0)`;
            }
        }
    }, [top]);
    return (_jsx(Portal, { children: _jsx(ContainerStyled, { onMouseLeave: onMouseLeave, onMouseOver: onMouseOver, top: top, ref: containerRef, children: items === null || items === void 0 ? void 0 : items.map(item => _jsx(MenuItem, Object.assign({}, item, { isExpanded: true }), item.id)) }) }));
}
