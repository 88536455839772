import { jsx as _jsx } from "react/jsx-runtime";
import { Fieldset as BubbleFieldset } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
import React from 'react';
Fieldset.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        Component: PropTypes.element.isRequired,
        props: PropTypes.object
    })).isRequired
};
function Fieldset({ fields }) {
    return (_jsx(BubbleFieldset, { fields: fields.map(({ Component, name, props = {} }) => ({
            Component,
            size: 1,
            props: Object.assign({ name }, props)
        })) }));
}
export default Fieldset;
