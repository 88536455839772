import * as Sentry from '@sentry/react';
import { actions as coreActions, selectors as coreSelectors } from 'mage-core';
import * as R from 'ramda';
/** THUNKS **/
const storeDetails = (client, userId, details) => {
    return client.request({
        method: 'PUT',
        url: `/users/${userId}`,
        data: details
    });
};
const saveDetails = (userId, details) => (dispatch, getState) => {
    const state = getState();
    const client = coreSelectors.createBackendClient(state)('users');
    dispatch(coreActions.profile.updating());
    return storeDetails(client, userId, details)
        .then(() => dispatch(coreActions.profile.update.succeeded()))
        .catch(err => {
        dispatch(coreActions.profile.update.failed());
        Sentry.captureException(err);
        throw err;
    });
};
const resetPassword = userId => (dispatch, getState) => {
    const state = getState();
    const client = coreSelectors.createBackendClient(state)('users');
    dispatch(coreActions.password.resetting());
    return client
        .request({
        method: 'POST',
        url: `/actions/reset-password?user_id=${userId}`
    })
        .then(() => dispatch(coreActions.password.reset.succeeded()))
        .catch(err => {
        dispatch(coreActions.password.reset.failed(R.propOr(err, 'response', err)));
        Sentry.captureException(err);
        throw err;
    });
};
const fetchRequester = (userId, tokenHelper) => (dispatch, getState) => {
    const state = getState();
    if (coreSelectors.isMerchantsRealm(state)) {
        const client = coreSelectors.createBackendClient(state)('users');
        return dispatch(coreActions.requester.fetch(client, userId, tokenHelper));
    }
    else {
        return dispatch(coreActions.requester.set(null));
    }
};
const updateRequester = (userId, data) => (dispatch, getState) => {
    const state = getState();
    if (coreSelectors.isMerchantsRealm(state)) {
        const client = coreSelectors.createBackendClient(state)('users');
        return dispatch(coreActions.requester.update(client, userId, data));
    }
};
export { fetchRequester, resetPassword, saveDetails, updateRequester };
