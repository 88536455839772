import { useEffect } from 'react';
export function useWhenClickOutside(ref, fn) {
    useEffect(() => {
        const clickOutsideEventListener = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                fn();
            }
        };
        document.addEventListener('mousedown', clickOutsideEventListener);
        document.addEventListener('touchstart', clickOutsideEventListener);
        return () => {
            document.body.style.overflow = 'unset';
            document.removeEventListener('mousedown', clickOutsideEventListener);
            document.removeEventListener('touchstart', clickOutsideEventListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
