import browserLocale from 'browser-locale';
import * as R from 'ramda';
// allow overriding of dependencies
const deps = {
    get browserLocale() {
        return R.or(this._browserLocale, browserLocale);
    },
    set browserLocale(browserLocale) {
        this._browserLocale = browserLocale;
    },
    get localStorage() {
        // eslint-disable-next-line n/no-unsupported-features/node-builtins
        return R.or(this._localStorage, window.localStorage);
    },
    set localStorage(localStorage) {
        this._localStorage = localStorage;
    }
};
const countries = ['be', 'de', 'dk', 'fi', 'gb', 'nl', 'no', 'se', 'us'];
/**
 * 'en_GB' -> { language: 'en', country: 'gb' }
 */
const parseLocale = R.pipe(R.defaultTo(''), R.toLower, R.split('-'), R.zipObj(['language', 'country']));
const groupByKey = R.reduce(R.mergeDeepRight, {});
const validate = (value, defaultValue) => R.cond([
    [R.includes(value), R.always(value)],
    [R.T, R.always(defaultValue)]
]);
const getLocallyStoredLanguage = () => {
    try {
        return deps.localStorage.getItem('language');
    }
    catch (_a) {
        /* empty */
    }
};
export default (...translations) => {
    const locale = parseLocale(deps.browserLocale());
    const locallyStoredLanguage = getLocallyStoredLanguage();
    const groupedTranslations = groupByKey(translations);
    const languages = Object.keys(groupedTranslations);
    const language = validate(R.or(locallyStoredLanguage, locale.language), 'en')(languages);
    const country = validate(locale.country, 'gb')(countries);
    return {
        language,
        country,
        countries,
        translations: groupedTranslations
    };
};
export { deps };
