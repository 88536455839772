import * as Sentry from '@sentry/react'
import { RegionalResponse, RegionEnum, RequestMethodEnum } from '../components/SupportHelpBot/types'

const DEFAULT_REGIONS = [RegionEnum.EU, RegionEnum.AP, RegionEnum.US]

type MakeRequestParams = {
  client: any,
  method: RequestMethodEnum,
  url: string,
  region: RegionEnum,
  params?: any
}

export const requestWrapper = (
  client: any,
  method: RequestMethodEnum,
  url: string,
  params?: any,
  regions: RegionEnum[] = DEFAULT_REGIONS
): Promise<RegionalResponse[]> => (
  Promise.all(regions.map(
    async (region) => {
      const response = await makeRequest({ client, method, url, region, params })
      return {
        region,
        response
      }
    }
  ))
)

const makeRequest = async ({ client, method, url, region, params }: MakeRequestParams) => {
  try {
    return await client.request({
      method,
      url,
      region,
      params
    })
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}
