const NAME = 'i18n'

const createMonthsOfTheYear = t => [
  t('shared.months.january'),
  t('shared.months.february'),
  t('shared.months.march'),
  t('shared.months.april'),
  t('shared.months.may'),
  t('shared.months.june'),
  t('shared.months.july'),
  t('shared.months.august'),
  t('shared.months.september'),
  t('shared.months.october'),
  t('shared.months.november'),
  t('shared.months.december')
]

const createWeekDayOneLetterNames = t => ({
  monday: t('shared.weekDaysShort.monday'),
  tuesday: t('shared.weekDaysShort.tuesday'),
  wednesday: t('shared.weekDaysShort.wednesday'),
  thursday: t('shared.weekDaysShort.thursday'),
  friday: t('shared.weekDaysShort.friday'),
  saturday: t('shared.weekDaysShort.saturday'),
  sunday: t('shared.weekDaysShort.sunday')
})

export { createMonthsOfTheYear, createWeekDayOneLetterNames, NAME }
