import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import Notifications from '../Notifications';
const NotificationsContext = React.createContext({});
/**
 * Context used for displaying notifications to the user, such as Toast messages
 */
const useNotificationsContext = () => {
    return useContext(NotificationsContext);
};
/**
 * Context that exposes a set of functions related to showing
 * notifications to the users, such as Toast messages.
 */
const NotificationsContextProvider = ({ children }) => {
    const [notificationFunctions, setNotificationFunctions] = useState(null);
    return (_jsxs(NotificationsContext.Provider, { value: notificationFunctions, children: [_jsx(Notifications, { setNotificationFunctions: setNotificationFunctions }), notificationFunctions ? children : null] }));
};
export { NotificationsContextProvider, useNotificationsContext };
// Allows storybook to get props
export const NotificationsContextPropsDoc = () => null;
