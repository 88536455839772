import { translator } from 'mage-i18n'
import { connect } from 'react-redux'

import NotFound from '../components/NotFound'

const mapStateToProps = state => ({
  t: translator(state)
})

export default connect(mapStateToProps)(NotFound)
