import styles from './styles'
import { hooks } from 'mage-i18n'
import { useHelpContext } from '../../hooks'
import React from 'react'
import { IconSpeechBubble, ButtonPrimary, ButtonRoundPrimary, IconClose } from '@klarna/bubble-ui'
import ChatWidget from '../ChatWidget'
import { HELPBOT_SESSION_STORAGE_KEY } from '../../constants'
import { cleanDataIntoSession } from '../../utilities/sessionStorage'

const GetSupportFloatingButton = () => {
  // @ts-ignore
  const { isWidgetOpen, toggleWidget, closeWidget } = useHelpContext()
  const t = hooks.useTranslator()

  const handleClick = () => {
    toggleWidget()
  }

  const handleClose = () => {
    closeWidget()
    cleanDataIntoSession(HELPBOT_SESSION_STORAGE_KEY)
  }

  if (!isWidgetOpen) {
    return (
      <div style={styles.chatWidget}>
        <ButtonPrimary onClick={handleClick} size='medium' icon={IconSpeechBubble}>
          {t('help-tool.support-button')}
        </ButtonPrimary>
      </div>
    )
  }

  return (
    <div style={styles.chatWidget}>
      <ChatWidget />
      <div style={styles.chatCloseButton}>
        <ButtonRoundPrimary onClick={handleClose}>
          <IconClose />
        </ButtonRoundPrimary>
      </div>
    </div>
  )
}

export default GetSupportFloatingButton
