import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';
import i18nActions from '../actions/mage-i18n';
const init = {
    language: 'en',
    country: 'gb',
    countries: [],
    translations: {}
};
const setLanguageActions = combineActions('CORE/REQUESTER/FETCH', i18nActions.language.set);
export const i18nReducer = handleActions({
    [setLanguageActions]: (state, { error, payload = {} }) => {
        return error || typeof payload !== 'object'
            ? state
            : Object.assign(Object.assign({}, state), R.pick(['language'], payload));
    }
}, init);
export const i18nConstants = {
    NAME: 'i18n'
};
