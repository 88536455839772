import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Radio } from '@klarna/bubble-ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useValidationErrorLabel from '../hooks/useValidationErrorLabel';
RadioField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};
export default function RadioField(_a) {
    var { name, options } = _a, props = __rest(_a, ["name", "options"]);
    const errorMessage = useValidationErrorLabel(name);
    const [field, , { setValue }] = useField(name);
    return (_jsx(Radio, Object.assign({}, field, props, { error: errorMessage !== undefined, options: options, onChange: setValue })));
}
