"use strict";
module.exports = {
    apps: {
        'disputes-fe': {
            enabled: false
        },
        support: {
            enabled: false
        },
        'klarna-search-b2b-portal': {
            enabled: false
        }
    },
    common: {
        environment: {
            type: 'playground'
        },
        insights: {
            googleAnalytics: {
                cookieDomain: 'portal.playground.klarna.com',
                tagId: 'G-Y2YC84PS4H'
            }
        },
        keycloak: {
            baseUrls: {
                ap: 'https://auth.playground.oc.portal.klarna.com',
                eu: 'https://auth.playground.eu.portal.klarna.com',
                us: 'https://auth.playground.us.portal.klarna.com'
            }
        },
        merchantPortalBaseUrl: 'https://portal.playground.klarna.com',
        frontendEventRouterBaseUrls: {
            eu: 'https://eu.playground.klarnaevt.com',
            ap: 'https://oc.playground.klarnaevt.com',
            us: 'https://na.playground.klarnaevt.com'
        },
        nko: {
            baseUrl: 'https://nko.playground2.eu1.starbug.klarna.net'
        },
        sentry: {
            enabled: true
        }
    }
};
