import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { withStyleSheetOverride } from '@klarna/higher-order-components';
import { Message } from 'mage-i18n';
import PropTypes from 'prop-types';
import LockEnhanced from './Lock';
const getDefaultStyleSheet = () => ({
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '80px 0 40px'
    }
});
const AccessDenied = ({ styleSheet }) => {
    return (_jsxs("div", { id: "access-denied", children: [_jsx("div", { style: styleSheet.iconContainer, children: _jsx(LockEnhanced, {}) }), _jsx(Typography, { textToken: "text-style/headings/titles/bold/primary", textAlignToken: "text-alignment/center", accessibilityPreset: "heading 1", children: _jsx(Message, { path: "core.accessDenied.title" }) }), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(Typography, { textToken: "text-style/text/paragraphs/body/regular", textAlignToken: "text-alignment/center", accessibilityPreset: "paragraph", children: _jsx(Message, { path: "core.accessDenied.message" }) })] }));
};
AccessDenied.propTypes = {
    styleSheet: PropTypes.shape({
        iconContainer: PropTypes.object
    })
};
export default withStyleSheetOverride(getDefaultStyleSheet)(AccessDenied);
export { AccessDenied };
