import { createStorage } from 'mage-common';
import { useAnalytics } from 'mage-insights';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFeatureHighlightInfo } from './FeatureHighlightInfo';
export function useTimerTrigger(_timeToShow, onTimer) {
    useEffect(() => {
        let [mpuiOverlayVisible, cookieOverlayVisible] = [false, false];
        const initialWaitTime = Math.min(_timeToShow, 2000);
        let timeToShow = _timeToShow - initialWaitTime;
        let dismissTimer;
        const onOverlayDismiss = () => {
            if (!mpuiOverlayVisible && !cookieOverlayVisible) {
                dismissTimer = setTimeout(() => {
                    onTimer();
                }, timeToShow);
            }
        };
        const mpUIModalObserver = new IntersectionObserver(mutationList => {
            mutationList.forEach(mutation => {
                mpuiOverlayVisible = mutation.isIntersecting;
                if (mpuiOverlayVisible) {
                    timeToShow = _timeToShow;
                }
                onOverlayDismiss();
            });
        });
        const cookieObserver = new IntersectionObserver(mutationList => {
            mutationList.forEach(mutation => {
                cookieOverlayVisible = mutation.isIntersecting;
                if (cookieOverlayVisible) {
                    timeToShow = _timeToShow;
                }
                onOverlayDismiss();
            });
        });
        const timer = setTimeout(() => {
            const mpuiModal = document.querySelector('#mp-ui-modal');
            const cookieOverlay = document.querySelector('.onetrust-pc-dark-filter.ot-fade-in');
            if (mpuiModal || cookieOverlay) {
                if (mpuiModal) {
                    mpUIModalObserver.observe(mpuiModal);
                }
                if (cookieOverlay) {
                    cookieObserver.observe(cookieOverlay);
                }
            }
            else {
                onOverlayDismiss();
            }
        }, initialWaitTime);
        return () => {
            clearTimeout(timer);
            if (dismissTimer) {
                clearTimeout(dismissTimer);
            }
            mpUIModalObserver.disconnect();
            cookieObserver.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onTimer]);
}
export default function useFeatureHighlight(storageId, timeToShow, analyticsName = undefined, enabled = true) {
    const track = useAnalytics({
        category: `feature-highlight/${analyticsName}`,
        action: 'impression'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const localStorage = useMemo(() => createStorage(), [createStorage]);
    const [, , updateFeatureHighlightInfo] = useFeatureHighlightInfo(storageId);
    const featureHighlightAlreadyShown = useMemo(() => !!localStorage.get(storageId), [localStorage, storageId]);
    const [showFeatureHighlight, _setShowFeatureHighlight] = useState(false);
    const setShowFeatureHighlight = useCallback(value => {
        updateFeatureHighlightInfo(value);
        _setShowFeatureHighlight(value);
    }, [updateFeatureHighlightInfo, _setShowFeatureHighlight]);
    const onTimer = useCallback(() => {
        setShowFeatureHighlight(enabled && !featureHighlightAlreadyShown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureHighlightAlreadyShown, setShowFeatureHighlight, enabled, storageId]);
    useTimerTrigger(timeToShow, onTimer);
    const closeFeatureHighlight = useCallback(() => {
        setShowFeatureHighlight(false);
    }, [setShowFeatureHighlight]);
    useEffect(() => {
        if (showFeatureHighlight && localStorage.get(storageId) !== true) {
            if (analyticsName) {
                track();
            }
            localStorage.set(storageId, true);
        }
    }, [showFeatureHighlight, localStorage, storageId, track, analyticsName]);
    return [showFeatureHighlight, closeFeatureHighlight, featureHighlightAlreadyShown];
}
