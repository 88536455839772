import * as Sentry from '@sentry/react'
import { ChatClient } from './types'
import { Region } from '@mpp/token-helper'

export const postUserInfo = async (chatClient: ChatClient, region: Region, freshchatUserId: string) => {
  try {
    await chatClient.request({
      method: 'POST',
      url: '/v1/chat/user/information',
      region: region,
      data: { freshchat_user_uuid: freshchatUserId }
    })
  } catch (err) {
    // @ts-ignore
    if ([401, 403].includes(err?.status)) return

    Sentry.captureException(err)
  }
}
