import { useGetToken } from '@klarna/mp-ui';
import { NavLink as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useDesktop, useTablet } from '../../../hooks/useMediaQuery';
export const SUBMENU_ITEM_ACTIVE_CLASSNAME = 'mpui-fw-sub-menu-item-active';
export const MenuItemStyled = styled.li({
    padding: 0
});
const LinkCss = () => {
    const height = useGetToken('space/500').value();
    const marginTop = useGetToken('space/50').value();
    const textStyles = useGetToken('text-style/text/paragraphs/default/bold').styles();
    const paddingVertical = useGetToken('space/100').value();
    const paddingHorizontal = useGetToken('space/150').value();
    const borderRadius = useGetToken('corner-radius/s').value();
    return css(Object.assign(Object.assign({ borderRadius, alignItems: 'center', boxSizing: 'border-box', display: 'flex', height,
        marginTop, padding: `${paddingVertical}px ${paddingHorizontal}px`, textDecoration: 'none', overflow: 'hidden' }, textStyles), { lineHeight: `${textStyles.lineHeight}px` }));
};
const LinkColorCss = ({ active, isFocused, isExpanded, hasActiveSubmenu }) => {
    const colorDefault = useGetToken('colors/text/neutral').toString();
    const colorActive = useGetToken('colors/text/inverse').toString();
    const colorActiveWithSubmenu = useGetToken('colors/text/default').toString();
    const activeBgColor = useGetToken('colors/backgrounds/inverse').toString();
    const activeHoveredBgColor = useGetToken('colors/backgrounds/inverse', {
        interactiveState: 'hovered'
    }).toString();
    const hoveredBgColor = useGetToken('colors/backgrounds/subtle-inline', {
        interactiveState: 'hovered'
    }).toString();
    const getLinkBgColor = (isActive) => {
        if (!isExpanded && isActive) {
            return activeBgColor;
        }
        if (isActive && hasActiveSubmenu) {
            return;
        }
        if (isActive) {
            return activeBgColor;
        }
        else if (isFocused) {
            return hoveredBgColor;
        }
    };
    const getLinkColor = (isActive) => {
        if (isActive && hasActiveSubmenu) {
            return colorActiveWithSubmenu;
        }
        else if (isActive) {
            return colorActive;
        }
        else {
            return colorDefault;
        }
    };
    const getHoverBgColor = (isActive) => {
        if (isActive && hasActiveSubmenu) {
            return;
        }
        if (isActive) {
            return activeHoveredBgColor;
        }
        return hoveredBgColor;
    };
    return css({
        backgroundColor: getLinkBgColor(active),
        color: getLinkColor(active),
        [`&.${SUBMENU_ITEM_ACTIVE_CLASSNAME}`]: {
            backgroundColor: getLinkBgColor(true),
            color: getLinkColor(true),
            ':hover,:focus': {
                backgroundColor: getHoverBgColor(true)
            }
        },
        ':hover,:focus': {
            transition: 'background-color 150ms ease-out',
            backgroundColor: getHoverBgColor(active)
        }
    });
};
export const RouterLinkStyled = styled(RouterLink)(LinkCss, LinkColorCss);
export const LinkStyled = styled.a(LinkCss, LinkColorCss);
export const MenuItemIconWrapperStyled = styled.span(() => {
    const marginRight = useGetToken('space/100').value();
    return css({
        display: 'inline-flex',
        alignItems: 'center',
        marginRight
    });
});
export const SecondaryIconWrapperStyled = styled.span(() => {
    const paddingLeft = useGetToken('space/50').value();
    return css({
        marginRight: 0,
        marginLeft: 'auto',
        paddingLeft,
        display: 'inline-flex'
    });
});
export const LabelStyled = styled.span(({ isExpanded }) => {
    return css({
        display: 'inline-flex',
        opacity: isExpanded ? 1 : 0,
        alignItems: 'center',
        overflow: 'initial',
        transition: 'opacity 0.3s ease-in-out'
    });
});
export const BadgeStyled = styled.span(({ isExpanded }) => {
    const marginLeft = useGetToken('space/50').value();
    const tableMediaQuery = useTablet();
    const desktopMediaQuery = useDesktop();
    return css({
        display: 'inline-flex',
        marginLeft,
        [tableMediaQuery]: {
            display: isExpanded ? 'inline-flex' : 'none'
        },
        [desktopMediaQuery]: {
            display: 'inline-flex'
        }
    });
});
