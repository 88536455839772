import { selectors as experimentationSelectors } from '@merchant-portal/experimentation';
import { memoizeWithTokenHelper } from '@mpp/token-helper';
import { createSelector } from '@reduxjs/toolkit';
import Keycloak from 'keycloak-js';
import * as commonSelectors from 'mage-common/src/selectors';
import * as configSelectors from 'mage-config/src/selectors';
import * as coreSelectors from 'mage-core/src/selectors';
import * as i18nSelectors from 'mage-i18n/src/selectors';
import * as R from 'ramda';
import * as utils from './utils';
const isNkoApp = R.pathEq(true, ['config', 'isNkoApp']);
const makeNkoUrl = (nkoConfig, estoreId, app) => (Object.assign(Object.assign({}, app), { url: nkoConfig.baseUrl + R.pathOr('', ['url'], app).replace('{estoreId}', estoreId), newTab: true }));
const urlWithFinalSlash = url => (url.endsWith('/') ? url : `${url}/`);
const addRealmFragment = (realm, url) => `${urlWithFinalSlash(url)}#realm=${realm}`;
const makeRealmPreservingUrl = (realm, app) => (Object.assign(Object.assign({}, app), { url: addRealmFragment(realm, app.url) }));
const hasCategory = R.complement(R.pathEq('', ['category']));
const appLink = clientId => (apps, realm) => apps[clientId] && addRealmFragment(realm, apps[clientId].url);
/** SELECTORS */
const getLanguages = createSelector(i18nSelectors.translations, coreSelectors.clientId, (translations, clientId) => R.pipe(R.pickBy(R.has(clientId)), R.keys)(translations));
const getAppConfig = createSelector(configSelectors.apps, coreSelectors.clientId, (apps, clientId) => apps[clientId]);
const getAppBaseUrl = createSelector(configSelectors.getMerchantPortalBaseUrl, coreSelectors.getBasename, (merchantPortalBaseUrl, basename) => `${merchantPortalBaseUrl}${basename}`);
const getHomeLink = createSelector(configSelectors.apps, coreSelectors.keycloakRealm, appLink('home-fe'));
const getAppLink = createSelector(configSelectors.apps, coreSelectors.keycloakRealm, coreSelectors.clientId, (apps, realm, clientId) => appLink(clientId)(apps, realm));
const keycloakConfig = createSelector(configSelectors.keycloak, coreSelectors.keycloakRealm, getAppConfig, coreSelectors.isPartner, configSelectors.region, R.pathOr({}, ['router', 'location', 'query']), (keycloakConfig, realm, appConfig, isPartner, region, locationQuery) => {
    var _a;
    let clientId = keycloakConfig.clientId;
    if (isPartner && realm === 'merchants') {
        clientId = keycloakConfig.partnerClientId;
    }
    else if (appConfig && appConfig.keycloakClient) {
        clientId = appConfig.keycloakClient;
    }
    let keycloakBaseUrl = 
    // @ts-expect-error: KEYCLOAK_BASE_URL is defined in the webpack config
    typeof KEYCLOAK_BASE_URL !== 'undefined' ? KEYCLOAK_BASE_URL : keycloakConfig.baseUrls[region];
    const sessionCookie = utils.getSessionCookie();
    const iss = (_a = locationQuery.iss) !== null && _a !== void 0 ? _a : sessionCookie === null || sessionCookie === void 0 ? void 0 : sessionCookie.iss;
    if (iss) {
        try {
            const issOrigin = new URL(decodeURIComponent(iss)).origin;
            const isValidIss = Object.values(keycloakConfig.baseUrls).some(baseUrl => baseUrl === issOrigin);
            if (isValidIss) {
                keycloakBaseUrl = issOrigin;
            }
        }
        catch (_b) {
            /* handle malformed iss */
        }
    }
    return Object.assign(Object.assign({}, R.pick(['connectAttempts', 'connectDelay', 'refreshTokenThreshold'], keycloakConfig)), { url: `${keycloakBaseUrl}/auth`, healthCheckUrl: keycloakBaseUrl + keycloakConfig.healthCheckPath, realm,
        clientId });
});
const getKeycloakUrl = R.pipe(keycloakConfig, R.prop('url'));
const getKeycloakRealm = R.pipe(keycloakConfig, R.prop('realm'));
const getKeycloakClientId = R.pipe(keycloakConfig, R.prop('clientId'));
const getKeycloak = createSelector(getKeycloakUrl, getKeycloakRealm, getKeycloakClientId, (keycloakUrl, keycloakRealm, keycloakClientId) => {
    const keycloak = new Keycloak({
        url: keycloakUrl,
        realm: keycloakRealm,
        clientId: keycloakClientId
    });
    keycloak.onAuthSuccess = () => {
        const { tokenParsed = {} } = keycloak;
        const { acr, iss } = tokenParsed;
        utils.upsertSessionCookie({ acr, iss });
    };
    ['onAuthError', 'onAuthRefreshError', 'onAuthLogout'].forEach(eventName => {
        keycloak[eventName] = () => {
            utils.deleteSessionCookie();
        };
    });
    return keycloak;
});
const getApps = createSelector(configSelectors.apps, coreSelectors.keycloakRealm, configSelectors.nko, experimentationSelectors.features, (apps, realm, nkoConfig, features) => memoizeWithTokenHelper(tokenHelper => {
    const eid = tokenHelper.getEids()[0];
    return R.toPairs(apps)
        .map(([appName, appProps]) => (Object.assign({ clientId: appName }, appProps)))
        .filter(appConfig => {
        return !R.pathOr(false, [`merchant-portal.apps.${appConfig.clientId}.disabled`, 'is_enabled'], features);
    })
        .filter(appConfig => {
        return appConfig.enabled;
    })
        .sort((appConfigOne, appConfigTwo) => appConfigOne.sortOrder - appConfigTwo.sortOrder)
        .map(appConfig => {
        return isNkoApp(appConfig)
            ? makeNkoUrl(nkoConfig, eid, appConfig)
            : makeRealmPreservingUrl(realm, appConfig);
    });
}));
const getUserAccessibleApps = createSelector(getApps, apps => memoizeWithTokenHelper(tokenHelper => {
    const clientIds = tokenHelper.getClientsForAllEntities();
    const isNkoAdmin = commonSelectors.tokenHelper.isNkoAdmin(tokenHelper);
    const isNkoUser = commonSelectors.tokenHelper.isNkoUser(tokenHelper);
    const isRequesterDeepLinkUser = !!tokenHelper.getLoginSource();
    return apps(tokenHelper).filter(({ additionalRequirements = [], clientId }) => {
        return additionalRequirements.every(requirement => {
            switch (requirement) {
                case 'isTransactingUser':
                    return tokenHelper.isTransactingUser();
                case 'hasPermission':
                    return clientIds.includes(clientId);
                case 'isNkoAdminIfNkoUser':
                    return !isNkoUser || isNkoAdmin;
                case 'isNotDeepLinkUser':
                    return !isRequesterDeepLinkUser;
                default:
                    return false;
            }
        });
    });
}));
const getAppCategories = createSelector(configSelectors.appCategories, categories => R.pipe(R.toPairs, R.map(([categoryKey, value]) => (Object.assign({ id: categoryKey }, value))), R.sortBy(R.prop('order')))(categories));
const getCategorizedApps = createSelector(getAppCategories, getUserAccessibleApps, (categories, apps) => memoizeWithTokenHelper(tokenHelper => {
    return R.pipe(R.filter(hasCategory), R.sort((appA, appB) => {
        const categoryTitles = R.map(({ title }) => title, categories);
        return categoryTitles.indexOf(appA.category) - categoryTitles.indexOf(appB.category);
    }), R.groupBy(app => app.category))(apps(tokenHelper));
}));
const cmsLocale = createSelector(i18nSelectors.language, language => ({ no: 'nb' })[language] || language);
const cmsEnvironment = createSelector(configSelectors.environment, environment => ({
    production: 'master',
    playground: 'master'
})[environment] || 'staging');
export { cmsEnvironment, cmsLocale, getAppBaseUrl, getAppCategories, getAppConfig, getAppLink, getApps, getCategorizedApps, getHomeLink, getKeycloak, getLanguages, getUserAccessibleApps, keycloakConfig };
