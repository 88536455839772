import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionIconChevronRight, Typography } from '@klarna/bubble-ui';
import styled from 'styled-components';
const ContainerStyled = styled.div `
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;
const HeaderStyled = styled.div `
  display: 'flex',
  gap: '16px',
  width: '100%',
  alignItems: 'center'
`;
const ModalButton = ({ onClick, children, label }) => {
    return (_jsxs(ContainerStyled, { onClick: onClick, children: [_jsx("div", { children: _jsxs(HeaderStyled, { children: [children, _jsx("div", { children: _jsx(Typography, { textToken: "text-style/text/paragraphs/body/bold", children: label }) })] }) }), _jsx(ActionIconChevronRight, {})] }));
};
export default ModalButton;
