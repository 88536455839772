import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconClock, IconEnvelope, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
const ContentContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Feature = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
`;
const SetUpRequired = () => {
    const t = i18nHooks.useTranslator();
    const partnerData = useSelector(coreSelectors.getDistributionPartner);
    const distributionPartnerName = partnerData === null || partnerData === void 0 ? void 0 : partnerData.name;
    return (_jsxs(ContentContainer, { children: [_jsx("div", { children: _jsx(Typography, { textToken: "text-style/text/paragraphs/grande/regular", children: t('core.stepUpAuthentication.setUp.description') }) }), _jsx(SpacerVertical, { spaceToken: "space/300" }), _jsxs(Feature, { children: [_jsx(IconClock, {}), _jsx(Typography, { textToken: "text-style/text/paragraphs/tall/regular", children: t('core.stepUpAuthentication.setUp.features.speed') })] }), distributionPartnerName && (_jsxs(_Fragment, { children: [_jsx(SpacerVertical, { spaceToken: "space/200" }), _jsxs(Feature, { children: [_jsx(IconEnvelope, {}), _jsx(Typography, { textToken: "text-style/text/paragraphs/tall/regular", children: t('core.stepUpAuthentication.setUp.features.email', {
                                    distributionPartner: distributionPartnerName
                                }) })] })] }))] }));
};
export default SetUpRequired;
