import React from 'react'
import { ChatBubble } from '@klarna/bubble-ui'

interface HelpbotResponseProps {
  message: string
}

type Styles = {
  chatBubble: any,
  wrapper: any
}

const styles: Styles = {
  chatBubble: {
    marginBottom: '0px',
    alignSelf: 'flex-end'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  }
}

const HelpbotResponse: React.FC<HelpbotResponseProps> = ({ message }: HelpbotResponseProps) => {
  return (
    <div style={styles.wrapper}>
      <div style={styles.chatBubble}>
        <ChatBubble direction='right' color='black' maxWidth={300}>
          {message}
        </ChatBubble>
      </div>
    </div>
  )
}

export { HelpbotResponse }
