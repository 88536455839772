import { HELPBOT_SESSION_STORAGE_KEY } from '../constants'
import { cleanDataIntoSession } from './sessionStorage'

type SidebarControllerFn = (v: boolean) => void

// TODO: Simplify the way the sidebar is controlled once Marble is fully deployed.
export const generateSidebarController = (
  setHelptoolIsOpen: SidebarControllerFn
): SidebarControllerFn => (isOpen: boolean) => {
  setHelptoolIsOpen(isOpen)
  cleanDataIntoSession(HELPBOT_SESSION_STORAGE_KEY)
}
