"use strict";
const R = require('ramda');
const commonConfig = require('./configs/common');
const getConfig = (cluster, configOverrideFromApp = {}) => {
    const clusterConfig = require(`./configs/${cluster}.js`);
    return R.pipe(R.mergeDeepLeft(clusterConfig), R.mergeDeepLeft(configOverrideFromApp))(commonConfig);
};
module.exports = {
    getConfig
};
