import {
  ButtonPrimary,
  IllustrationErrorExplodingBalloon, // NOTE: Use IconBalloonBroken instead
  SpacerVertical
} from '@klarna/bubble-ui'
import { CenteredContent } from 'mage-components'
import PropTypes from 'prop-types'

import StaticAppWrapper from '../containers/StaticAppWrapper'

const NotFound = ({ t }) => {
  return (
    <StaticAppWrapper id="NotFound">
      <SpacerVertical spaceToken="space/50" />
      <CenteredContent
        illustration={<IllustrationErrorExplodingBalloon />}
        title={t('static-pages.NotFound.title')}
        summary={t('static-pages.NotFound.details')}
      >
        <ButtonPrimary
          onClick={() => {
            window.history.back()
          }}
        >
          {t('static-pages.NotFound.goBack')}
        </ButtonPrimary>
      </CenteredContent>
    </StaticAppWrapper>
  )
}

NotFound.propTypes = {
  t: PropTypes.func.isRequired
}

export default NotFound
