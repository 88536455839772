import * as R from 'ramda'
import { createActions } from 'redux-actions'

const actions = createActions({
  CORE: {
    TOKEN: {
      SET: undefined
    },
    KEYCLOAK: {
      REALM: {
        SET: undefined,
        CLEAR: undefined
      }
    },
    PROFILE: {
      UPDATING: undefined,
      UPDATE: {
        SUCCEEDED: undefined,
        FAILED: undefined
      }
    },
    PASSWORD: {
      RESETTING: undefined,
      RESET: {
        SUCCEEDED: undefined,
        FAILED: undefined
      }
    },
    REQUESTER: {
      FETCH: (client, userId, tokenHelper) => {
        return client
          .request({
            url: `/users/${userId}?with_capabilities=true&with_pending_entities=true`
          })
          .then(R.prop('data'))
          .then(data => ({
            ...data,
            tokenHelper
          }))
      },
      UPDATE: (client, userId, data) => {
        return client
          .request({
            method: 'PUT',
            url: `/users/${userId}`,
            data
          })
          .then(R.prop('data'))
      },
      SET: undefined
    },
    DISTRIBUTION_PARTNER: {
      SET: undefined
    },
    MERCHANTS: {
      SET: undefined
    },
    MIDS: {
      SET: undefined
    },
    PARTNER_ACCOUNTS: {
      SET: undefined
    },
    MID: {
      SET: undefined
    },
    IS_PARTNER: {
      SET: undefined
    }
  }
}).core

export default actions
