import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, SpacerHorizontal, Typography } from '@klarna/bubble-ui';
import { useGetToken, useResponsiveContext } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
const FooterStyled = styled.footer(() => {
    const minHeight = useGetToken('space/800').value();
    const horizontalPadding = useGetToken('space/300').value();
    return css `
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: ${minHeight}px;
    padding: 0 ${horizontalPadding}px;
    margin-top: auto;
    grid-area: app;
  `;
});
const TextContainer = styled.div(() => {
    const touchDeviceMaxWidth = useGetToken('breakpoint/l').value();
    return css `
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @media screen and (min-width: ${touchDeviceMaxWidth}px) {
      justify-content: flex-end;
    }
  `;
});
const TruncatedText = styled.div(() => {
    const touchDeviceMaxWidth = useGetToken('breakpoint/l').value();
    return css `
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: ${touchDeviceMaxWidth}px) {
      max-width: 100%;
    }
  `;
});
function Footer({ text, links = [] }) {
    const { isMobile } = useResponsiveContext();
    return (_jsx(FooterStyled, { children: _jsxs(TextContainer, { children: [_jsx(TruncatedText, { children: typeof text === 'string' ? (_jsx(Typography, { textToken: "text-style/text/paragraphs/tiny/regular", colorToken: "colors/text/neutral", children: text })) : (text) }), !isMobile && _jsx(SpacerHorizontal, { spaceToken: "space/50" }), links.map(({ linkText, path }, index) => (_jsx(Link, { textToken: "text-style/text/paragraphs/tiny/medium", colorToken: "colors/text/default", isUnderlined: true, href: path, children: linkText }, index)))] }) }));
}
Footer.propTypes = {
    text: PropTypes.node,
    links: PropTypes.arrayOf(PropTypes.shape({
        linkText: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired
    }))
};
export default Footer;
// Allows storybook to get props
export const FooterPropsDoc = () => null;
