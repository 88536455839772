import React from 'react'
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage'
import { AnswerOption, BotNodeId, HelpbotFlow } from '../../../types'
import { INeedMoreHelpThatHelped } from '../../common'

export const entrypoint: AnswerOption = {
  value: 'helpbot.orders.reservations.title',
  next: BotNodeId.ORDER_RESERVATIONS
}

export const flow: HelpbotFlow = [
  {
    id: BotNodeId.ORDER_RESERVATIONS,
    botMessage: null,
    options: () => [
      {
        value: 'helpbot.orders.orderManagement.extendExpiryReservation.question',
        next: BotNodeId.ORDER_MANAGEMENT_EXTEND_EXPIRY_RESERVATION
      },
      {
        value: 'helpbot.orders.reservations.whyUnableToExtendReservation.question',
        next: BotNodeId.ORDER_RESERVATIONS_UNABLE_TO_EXTEND_TIME
      }
    ]
  },
  {
    id: BotNodeId.ORDER_RESERVATIONS_UNABLE_TO_EXTEND_TIME,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.orders.reservations.whyUnableToExtendReservation.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  }
]
