export const styles = {
    base: {
        borderStyle: 'solid',
        borderColor: '#D1D1D0',
        borderBottomWidth: 1
    },
    title: {
        transform: 'translateY(8px)'
    },
    presets: {
        flexDirection: 'row',
        padding: '0 20px 20px',
        marginLeft: -5,
        marginRight: -5
    },
    preset: {
        marginLeft: 5,
        marginRight: 5
    }
};
export const createMonthsOfTheYear = t => [
    t('core.dateRangePicker.months.january'),
    t('core.dateRangePicker.months.february'),
    t('core.dateRangePicker.months.march'),
    t('core.dateRangePicker.months.april'),
    t('core.dateRangePicker.months.may'),
    t('core.dateRangePicker.months.june'),
    t('core.dateRangePicker.months.july'),
    t('core.dateRangePicker.months.august'),
    t('core.dateRangePicker.months.september'),
    t('core.dateRangePicker.months.october'),
    t('core.dateRangePicker.months.november'),
    t('core.dateRangePicker.months.december')
];
export const createWeekDayOneLetterNames = t => ({
    monday: t('core.dateRangePicker.weekDaysShort.monday'),
    tuesday: t('core.dateRangePicker.weekDaysShort.tuesday'),
    wednesday: t('core.dateRangePicker.weekDaysShort.wednesday'),
    thursday: t('core.dateRangePicker.weekDaysShort.thursday'),
    friday: t('core.dateRangePicker.weekDaysShort.friday'),
    saturday: t('core.dateRangePicker.weekDaysShort.saturday'),
    sunday: t('core.dateRangePicker.weekDaysShort.sunday')
});
