import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, DesignTokens } from '@klarna/bubble-ui';
import { constants } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
export var Envs;
(function (Envs) {
    Envs["Staging"] = "staging";
    Envs["Playground"] = "playground";
    Envs["Development"] = "development";
    Envs["Production"] = "production";
})(Envs || (Envs = {}));
const { zIndex } = constants;
const BorderStyled = styled.div(({ topOnly, envColor }) => ({
    borderBottomWidth: !topOnly ? '10px' : '0px',
    borderColor: envColor,
    borderLeftWidth: !topOnly ? '10px' : '0px',
    borderRightWidth: !topOnly ? '10px' : '0px',
    borderStyle: 'solid',
    borderTopWidth: '10px',
    boxSizing: 'border-box',
    height: '100%',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: topOnly ? zIndex.sidebar - 1 : zIndex.environmentId.border
}));
const EnvironmentContainerStyled = styled.span({
    cursor: 'default',
    display: 'flex',
    justifyContent: 'center',
    left: 'calc(50% - 50px)',
    position: 'absolute',
    top: '0',
    userSelect: 'none',
    width: '100px',
    zIndex: zIndex.environmentId.tag
});
const EnvironmentId = ({ disabled = false, env, color, hideLabel = false, id, showInProd = false, label, onClick, topOnly = false }) => {
    var _a, _b;
    const designContext = DesignTokens.useDesignContext();
    const environmentColorTokens = {
        default: 'colors/backgrounds/error-inline',
        playground: 'colors/backgrounds/warning-inline',
        staging: 'colors/backgrounds/success-inline'
    };
    const environmentColors = React.useMemo(() => ({
        default: designContext.get(environmentColorTokens.default).toString(),
        playground: designContext.get(environmentColorTokens.playground).toString(),
        staging: designContext.get(environmentColorTokens.staging).toString()
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [designContext]);
    if (disabled || !env || (env === Envs.Production && !showInProd)) {
        return null;
    }
    const envColorToken = (_a = environmentColorTokens === null || environmentColorTokens === void 0 ? void 0 : environmentColorTokens[env]) !== null && _a !== void 0 ? _a : environmentColorTokens.default;
    const envColor = (_b = color !== null && color !== void 0 ? color : environmentColors === null || environmentColors === void 0 ? void 0 : environmentColors[env]) !== null && _b !== void 0 ? _b : environmentColors.default;
    return (_jsxs("div", { id: id, children: [_jsx(BorderStyled, { envColor: envColor, topOnly: topOnly }), !hideLabel && (_jsx(EnvironmentContainerStyled, { onClick: onClick, id: "mpui-environment-id", children: _jsx(Badge, { backgroundColorToken: envColorToken, borderColorToken: envColorToken, textColorToken: "colors/text/default", children: label || env }) }))] }));
};
EnvironmentId.propTypes = {
    color: PropTypes.any,
    disabled: PropTypes.bool,
    env: PropTypes.oneOf(['staging', 'playground', 'development', 'production']).isRequired,
    hideLabel: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    showInProd: PropTypes.bool,
    topOnly: PropTypes.bool
};
export default EnvironmentId;
