import { AlertBannerWarning, Separator, SpacerVertical, SwitchCheckbox, Typography } from '@klarna/bubble-ui';
import { Flex, Modal } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React, { useState } from 'react';
import styled from 'styled-components';
import DownloadLocalDataLink from './DownloadLocalDataLink';
const TRANSLATION_PREFIX = 'credentials.addApiKeySuccessModal';
const ApiKeysContainer = styled.div `
  width: 70%;
  display: flex;
  justify-content: flex-start;
`;
function AddApiKeySuccessModal({ data, onClose, doesRequireVerification = false }) {
    const t = i18nHooks.useTranslator();
    const [hasDownloaded, setHasDownloaded] = useState(false);
    const [hasAcknowledged, setHasAcknowledged] = useState(false);
    const hasSaved = hasDownloaded || hasAcknowledged;
    const { username, password } = data;
    const downloadData = `username: ${username}\npassword: ${password}`;
    const buttonText = doesRequireVerification
        ? t(`${TRANSLATION_PREFIX}.continueToVerification`)
        : t('credentials.common.close');
    return (React.createElement(Modal, { useWindowHeight: false, closeOnClickOutside: false, closeOnEscape: false, title: t(`${TRANSLATION_PREFIX}.title`), description: t(`${TRANSLATION_PREFIX}.description`), buttonPrimary: {
            text: buttonText,
            onClick: onClose,
            disabled: !hasSaved,
            'data-testid': 'confirmation-button'
        } },
        React.createElement("div", { style: { padding: '20px 0' } },
            React.createElement(Flex, { id: 'credentials-username', justifyContent: 'space-between' },
                React.createElement(Typography, null, t('credentials.common.username')),
                React.createElement(ApiKeysContainer, null,
                    React.createElement(Typography, { textToken: "text-style/text/labels/body/medium" }, username)))),
        React.createElement(Separator, null),
        React.createElement("div", { style: { padding: '20px 0' } },
            React.createElement(Flex, { id: 'credentials-password', justifyContent: 'space-between' },
                React.createElement(Typography, null, t('credentials.common.password')),
                React.createElement(ApiKeysContainer, null,
                    React.createElement(Typography, { textToken: "text-style/text/labels/body/medium" }, password)))),
        React.createElement(SpacerVertical, { spaceToken: 'space/200' }),
        React.createElement(DownloadLocalDataLink, { id: 'credentials', data: downloadData, filename: `${username}.txt`, onClick: () => setHasDownloaded(true) }),
        React.createElement(SpacerVertical, { spaceToken: 'space/200' }),
        React.createElement(AlertBannerWarning, { content: [t(`${TRANSLATION_PREFIX}.warning`)] }),
        React.createElement(SpacerVertical, { spaceToken: 'space/200' }),
        React.createElement(SwitchCheckbox, { id: 'credentials-checkbox', checked: hasAcknowledged, onChange: setHasAcknowledged }, t(`${TRANSLATION_PREFIX}.checkbox`))));
}
export default AddApiKeySuccessModal;
