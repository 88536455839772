import React from 'react'
import { BotNodeId, HelpbotFlow } from '../../../types'
import { INeedMoreHelpThatHelped } from '../../common'
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage'

const payoutsFlow: HelpbotFlow = [
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT,
    botMessage: null,
    options: () => [
      {
        value: 'helpbot.settlements.payouts.whyCalculationNotMatch.question',
        next: BotNodeId.SETTLEMENTS_PAYOUT_WHY_CALCULATION_NOT_MATCH
      },
      {
        value: 'helpbot.settlements.payouts.missingPayout.question',
        next: BotNodeId.SETTLEMENTS_PAYOUT_MISSING_PAYOUT
      },
      {
        value: 'helpbot.settlements.payouts.howIsPayoutCalculated.question',
        next: BotNodeId.SETTLEMENTS_PAYOUT_HOW_CALCULATED
      }
    ]
  },
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT_WHY_CALCULATION_NOT_MATCH,
    botMessage: () => <DefaultBotMessage translationKey='helpbot.settlements.payouts.whyCalculationNotMatch.answer' />,
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT_MISSING_PAYOUT,
    botMessage: () => <DefaultBotMessage translationKey='helpbot.settlements.payouts.missingPayout.answer' />,
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT_HOW_CALCULATED,
    botMessage: () => <DefaultBotMessage translationKey='helpbot.settlements.payouts.howIsPayoutCalculated.answer' />,
    options: () => INeedMoreHelpThatHelped
  }
]

export default payoutsFlow
