import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { MultiSelector, Selector } from '@klarna/mp-ui';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import { useSelector } from 'react-redux';
import { mapPartnerAccountsToOptions } from './utils';
const isMultiSelectPartnerAccountSelector = (props) => {
    return props.isMultiselect;
};
const PartnerAccountSelector = (props) => {
    const { borderless, children, closeText, disabled, fullWidthTrigger, id, label, loading, maxHeight, maxWidth, onChange = () => null, onClose = () => null, partnerAccounts = [] } = props;
    const t = i18nHooks.useTranslator();
    const partnerAccountsData = useSelector(coreSelectors.getPartnerAccounts);
    const options = mapPartnerAccountsToOptions(partnerAccounts, partnerAccountsData);
    return (_jsx(_Fragment, { children: isMultiSelectPartnerAccountSelector(props) ? (_jsx(MultiSelector, { borderless: borderless, closeLabel: closeText || t('core.partnerAccountsSelector.closeText'), closeWithSelectionLabel: props.closeTextWithSelection || t('core.partnerAccountsSelector.closeTextWithSelection'), deselectAllOptionLabel: props.deselectAllOptionText || t('core.partnerAccountsSelector.deselectAllOptionText'), disabled: disabled, emptyStateLabel: props.emptyStateText || t('core.partnerAccountsSelector.emptyStateText'), isFullWidthTrigger: fullWidthTrigger, id: id, label: label || t('core.partnerAccountsSelector.label'), loading: loading, maxHeight: maxHeight, maxWidth: maxWidth, 
            // @ts-expect-error: FIXME - types in mp-ui
            onChange: onChange, onClose: onClose, options: options, searchLabel: props.searchFieldLabel || t('core.partnerAccountsSelector.searchFieldLabel'), selectAllOptionLabel: props.selectAllOptionText || t('core.partnerAccountsSelector.selectAllOptionText'), selectedAllLabel: props.selectedAllText || t('core.partnerAccountsSelector.selectedAllText'), selectedLabel: props.selectedText || t('core.partnerAccountsSelector.selectedText'), values: props.values || [], withSearch: partnerAccounts.length > 4, withSelectAll: partnerAccounts.length > 1, children: children })) : (_jsx(Selector, { borderless: borderless, closeLabel: closeText || t('core.partnerAccountSelector.closeText'), disabled: disabled, isFullWidthTrigger: fullWidthTrigger, id: id, label: label || t('core.partnerAccountSelector.label'), loading: loading, maxHeight: maxHeight, maxWidth: maxWidth, 
            // @ts-expect-error: FIXME - types in mp-ui
            onChange: onChange, onClose: onClose, options: options, value: typeof props.value === 'undefined' ? '' : props.value, children: children })) }));
};
export default PartnerAccountSelector;
