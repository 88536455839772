import { jsx as _jsx } from "react/jsx-runtime";
import { Pagination as PaginationMPUI } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
const styleSheet = {
    pagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
};
export function Pagination({ id, onPageChange, page, pagination: { count = 25, total = 0 }, scrollToTop }) {
    const onChange = page => {
        onPageChange(page);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        scrollToTop && window.scrollTo(0, 0);
    };
    const t = i18nHooks.useTranslator();
    return count ? (_jsx("div", { id: id, style: styleSheet.pagination, children: _jsx(PaginationMPUI, { current: page, size: count, total: total, ofLabel: t('core.pagination.ofLabel'), onChange: onChange }) })) : null;
}
Pagination.defaultProps = {
    pagination: {},
    scrollToTop: false
};
Pagination.propTypes = {
    id: PropTypes.string,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    pagination: PropTypes.object.isRequired,
    scrollToTop: PropTypes.bool
};
export default Pagination;
