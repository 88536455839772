import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
const Dialog = (_a) => {
    var { portal = true, 
    // eslint-disable-next-line react/prop-types
    _closeOnClickOutside = true, 
    // eslint-disable-next-line react/prop-types
    _useWindowHeight = false, 
    // eslint-disable-next-line react/prop-types
    _closeOnEscape = true, children } = _a, props = __rest(_a, ["portal", "_closeOnClickOutside", "_useWindowHeight", "_closeOnEscape", "children"]);
    return (_jsx(Modal, Object.assign({ usePortal: portal, closeOnClickOutside: true, closeOnEscape: true, useWindowHeight: true }, props, { children: children })));
};
Dialog.propTypes = {
    onClose: PropTypes.func,
    closeOnClickOutside: PropTypes.bool,
    useWindowHeight: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    large: PropTypes.bool,
    portal: PropTypes.bool,
    id: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    footer: PropTypes.array,
    unwrapped: PropTypes.bool
};
export default Dialog;
