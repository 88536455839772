import { useFeature } from '@merchant-portal/experimentation'

const useIsFeatureEnabled = (featureKey) => {
  const output = useFeature(
    featureKey
  )
  if (!output || !output.is_enabled) return false
  if (output.is_enabled === 'true') return true
  return false
}

export default useIsFeatureEnabled
