import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import MenuItem from './MenuItem';
import { MenuStyled, SubMenu, SubMenuPopover } from './SubMenu';
const VISIBILITY_TIMEOUT = 200;
function Menu({ items, isOpen }) {
    const [subMenuPosition, setSubMenuPosition] = useState(0);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const subMenuVisibilityTimeout = useRef();
    const clearVisibilityTimeout = useCallback(() => {
        if (subMenuVisibilityTimeout.current) {
            clearTimeout(subMenuVisibilityTimeout.current);
        }
    }, []);
    const handleEnter = useCallback(({ subMenu }, e) => {
        var _a;
        if ((_a = subMenu === null || subMenu === void 0 ? void 0 : subMenu.items) === null || _a === void 0 ? void 0 : _a.length) {
            const menuItemElement = e.target;
            clearVisibilityTimeout();
            setSubMenuPosition(menuItemElement.getBoundingClientRect().top);
            setActiveSubMenu(subMenu);
        }
    }, [clearVisibilityTimeout]);
    const handleLeave = useCallback(() => {
        clearVisibilityTimeout();
        subMenuVisibilityTimeout.current = setTimeout(() => {
            setActiveSubMenu(null);
        }, VISIBILITY_TIMEOUT);
    }, [clearVisibilityTimeout]);
    const handleSubMenuMouseOver = useCallback(() => {
        clearVisibilityTimeout();
    }, [clearVisibilityTimeout]);
    return (_jsxs(_Fragment, { children: [_jsx(MenuStyled, { role: "menu", children: items.map((_a) => {
                    var { subMenu } = _a, item = __rest(_a, ["subMenu"]);
                    return (_jsx(MenuItem, Object.assign({}, item, { isExpanded: isOpen, "aria-haspopup": !!subMenu, subMenu: subMenu, isFocused: (activeSubMenu === null || activeSubMenu === void 0 ? void 0 : activeSubMenu.parentId) === item.id, onEnter: handleEnter, onLeave: handleLeave, children: !!subMenu && _jsx(SubMenu, Object.assign({}, subMenu, { isOpen: isOpen })) }), item.id));
                }) }), !isOpen && !!activeSubMenu && (_jsx(SubMenuPopover, Object.assign({ top: subMenuPosition, onMouseOver: handleSubMenuMouseOver, onMouseLeave: handleLeave }, activeSubMenu)))] }));
}
export default Menu;
