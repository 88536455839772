import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IllustrationSmooth, SpacerVertical } from '@klarna/bubble-ui';
import { Framework } from '@merchant-portal/framework';
import { format } from 'date-fns-tz';
import { CenteredContent } from 'mage-components';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import EnvironmentIdentifier from '../EnvironmentIdentifier';
const Content = ({ until }) => {
    const t = i18nHooks.useTranslator();
    useEffect(() => {
        const title = t('core.maintenance.pageTitle');
        document.title = title;
    }, [t]);
    let summary;
    if (until) {
        const untilTime = format(new Date(parseInt(until)), 'Pp', {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
        summary = t('core.maintenance.scheduledSummary', { until: untilTime });
    }
    else {
        summary = t('core.maintenance.summary');
    }
    return (_jsxs(_Fragment, { children: [_jsx(SpacerVertical, { large: true }), _jsx(CenteredContent, { illustration: _jsx(IllustrationSmooth, {}), title: t('core.maintenance.title'), summary: summary })] }));
};
Content.propTypes = {
    until: PropTypes.string
};
const Maintenance = ({ footerConfig, until }) => {
    return (_jsxs(_Fragment, { children: [_jsx(EnvironmentIdentifier, {}), _jsx(Framework, { footerConfig: footerConfig, children: _jsx(Content, { until: until }) })] }));
};
Maintenance.propTypes = {
    footerConfig: PropTypes.shape({
        text: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(PropTypes.shape({
            linkText: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired
        }))
    }).isRequired,
    until: PropTypes.string
};
export default Maintenance;
export { Content };
