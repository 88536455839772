import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as s from '../selectors';
export const Number = ({ formatter, value, options, withGrouping }) => formatter(options, withGrouping).format(value);
Number.propTypes = {
    formatter: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    options: PropTypes.object,
    withGrouping: PropTypes.bool
};
export default connect(state => ({
    formatter: s.formatters.number(state)
}))(Number);
