import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Framework } from '@merchant-portal/framework';
import PropTypes from 'prop-types';
import EnvironmentIdentifier from '../EnvironmentIdentifier';
const Unauthenticated = ({ footerConfig }) => {
    return (_jsxs(_Fragment, { children: [_jsx(EnvironmentIdentifier, {}), _jsx(Framework, { isLoading: true, footerConfig: { text: footerConfig.text } })] }));
};
Unauthenticated.propTypes = {
    footerConfig: PropTypes.object
};
export default Unauthenticated;
