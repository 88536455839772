// Copied from https://stash.int.klarna.net/projects/MT/repos/merchant-tooling-ui/browse/core/common/components/UI/CountryFlag.tsx
import React from 'react'
import { string } from 'prop-types'
import FlagAT from '@bubble-ui/asset-flag-at'
import FlagAU from '@bubble-ui/asset-flag-au'
import FlagBE from '@bubble-ui/asset-flag-be'
import FlagCA from '@bubble-ui/asset-flag-ca'
import FlagCH from '@bubble-ui/asset-flag-ch'
import FlagCZ from '@bubble-ui/asset-flag-cz'
import FlagDE from '@bubble-ui/asset-flag-de'
import FlagDK from '@bubble-ui/asset-flag-dk'
import FlagEE from '@bubble-ui/asset-flag-ee'
import FlagES from '@bubble-ui/asset-flag-es'
import FlagFI from '@bubble-ui/asset-flag-fi'
import FlagFR from '@bubble-ui/asset-flag-fr'
import FlagGB from '@bubble-ui/asset-flag-gb'
import FlagGR from '@bubble-ui/asset-flag-gr'
import FlagIE from '@bubble-ui/asset-flag-ie'
import FlagIT from '@bubble-ui/asset-flag-it'
import FlagMX from '@bubble-ui/asset-flag-mx'
import FlagNL from '@bubble-ui/asset-flag-nl'
import FlagNO from '@bubble-ui/asset-flag-no'
import FlagNZ from '@bubble-ui/asset-flag-nz'
import FlagPL from '@bubble-ui/asset-flag-pl'
import FlagPT from '@bubble-ui/asset-flag-pt'
import FlagRO from '@bubble-ui/asset-flag-ro'
import FlagSE from '@bubble-ui/asset-flag-se'
import FlagUS from '@bubble-ui/asset-flag-us'
import { IconLanguage } from '@klarna/bubble-ui'

CountryFlag.propTypes = {
  countryCode: string
}

const FlagMap = {
  AT: FlagAT,
  AU: FlagAU,
  BE: FlagBE,
  CA: FlagCA,
  CH: FlagCH,
  CZ: FlagCZ,
  DE: FlagDE,
  DK: FlagDK,
  GR: FlagGR,
  EE: FlagEE,
  ES: FlagES,
  FI: FlagFI,
  FR: FlagFR,
  GB: FlagGB,
  IE: FlagIE,
  IT: FlagIT,
  MX: FlagMX,
  NL: FlagNL,
  NO: FlagNO,
  NZ: FlagNZ,
  PL: FlagPL,
  PT: FlagPT,
  RO: FlagRO,
  SE: FlagSE,
  US: FlagUS,
  GLOBAL: IconLanguage
}

export default function CountryFlag ({ countryCode }) {
  const props = {
    height: 20,
    width: 20,
    viewBox: '0 0 180 180'
  }

  const isFlagPresent = (countryCode = '') =>
    Object.keys(FlagMap).includes(countryCode.toUpperCase())

  if (countryCode === 'EN') {
    return <IconLanguage />
  }

  if (!isFlagPresent(countryCode)) {
    return (
      <svg width='20' height='20'>
        <circle cx='10' cy='10' r='10' fill='grey' />
      </svg>
    )
  }
  const Flag = FlagMap[countryCode.toUpperCase()]

  return <Flag {...props} />
}
