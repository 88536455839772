import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertBlock, Badge, ButtonPrimary, IconAgent, IconRefresh, IllustrationErrorExplodingBalloon, Separator, SpacerHorizontal, SpacerVertical, Typography } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
/** The error state for the Merchant Portal */
function Error({ actionButton, contactContent, label, id, illustration, description, reloadButton, title = 'Unexpected error' }) {
    const reloadHandler = () => window.location.reload();
    return (_jsxs("div", { id: id, style: { display: 'flex', flexDirection: 'column' }, children: [label && (_jsxs(_Fragment, { children: [_jsx(Badge, { backgroundColorToken: "colors/backgrounds/error-inline", borderColorToken: "colors/backgrounds/error-inline", textColorToken: "colors/text/error", children: label }), _jsx(SpacerVertical, { spaceToken: "space/500" })] })), illustration || _jsx(IllustrationErrorExplodingBalloon, {}), _jsx(SpacerVertical, { spaceToken: "space/500" }), title && (_jsx(Typography, { textToken: "text-style/headings/titles/bold/primary", children: title })), title && description && _jsx(SpacerVertical, { spaceToken: "space/400" }), description && (_jsx(Typography, { textToken: "text-style/text/paragraphs/body/regular", children: description })), (description || title) && (actionButton || reloadButton) && (_jsx(SpacerVertical, { spaceToken: "space/500" })), _jsxs("div", { style: { display: 'flex' }, children: [actionButton && (_jsx(ButtonPrimary, { size: "medium", onClick: actionButton.onClick, icon: actionButton.icon, id: actionButton.id, children: actionButton.label })), reloadButton && actionButton && _jsx(SpacerHorizontal, { spaceToken: "space/200" }), reloadButton && (_jsx(ButtonPrimary, { size: "medium", onClick: reloadHandler, icon: IconRefresh, id: reloadButton.id, children: reloadButton.label }))] }), contactContent && (_jsxs(_Fragment, { children: [_jsx(SpacerVertical, { spaceToken: "space/500" }), _jsx(Separator, {}), _jsx(SpacerVertical, { spaceToken: "space/500" }), _jsx(AlertBlock, { iconType: IconAgent, content: contactContent })] }))] }));
}
Error.propTypes = {
    id: PropTypes.string,
    illustration: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    contactContent: PropTypes.any,
    actionButton: PropTypes.shape({
        icon: PropTypes.any,
        id: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired
    }),
    reloadButton: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string.isRequired
    })
};
export default Error;
export { Error };
// Allows storybook to get props
export const ErrorPropsDoc = () => null;
export const ReloadButtonPropsDoc = () => null;
export const ActionButtonPropsDoc = () => null;
