import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Portal } from '@klarna/mp-ui';
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import Insights from 'mage-insights';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useWhenClickOutside } from '../hooks';
import NotificationBadge from './components/NotificationBadge';
import { NotificationButton } from './components/NotificationButton';
import { NotificationPanel } from './components/NotificationPanel';
import { useFetchNotifications } from './hooks/useFetchNotification';
import useNotificationBadge from './hooks/useNotificationBadge';
const NotificationContainer = styled.div `
  position: relative;
  max-height: 40px;
`;
export const NotificationCenter = ({ client }) => {
    const [isToggled, setIsToggled] = useState(false);
    const tokenHelper = coreHooks.useTokenHelper();
    const [notifications, isLoading, markAsRead] = tokenHelper
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
            useFetchNotifications({ client, targetId: tokenHelper.getUserId() })
        : [[], false, () => { }];
    const [showBadge, badgeLabel, animateBadge, stopAnimation] = useNotificationBadge(notifications);
    const panelRef = useRef();
    useWhenClickOutside(panelRef, () => setIsToggled(false));
    return (_jsxs(NotificationContainer, { children: [_jsx(NotificationButton, { isToggled: isToggled, onClick: () => {
                    if (!isToggled) {
                        Insights.event({
                            category: 'notification-button:open',
                            action: 'impression'
                        });
                    }
                    setIsToggled(!isToggled);
                    stopAnimation();
                    markAsRead();
                } }), showBadge && _jsx(NotificationBadge, { animated: animateBadge, children: badgeLabel }), isToggled && (_jsx(Portal, { children: _jsx(NotificationPanel, { notifications: notifications, isLoading: isLoading, ref: panelRef }) }))] }));
};
export default function NotificationCenterButton() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createBackendClient = useSelector(coreSelectors.createBackendClient);
    return _jsx(NotificationCenter, { client: createBackendClient('notifier') });
}
