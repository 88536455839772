import React from 'react'
import { hooks } from 'mage-i18n'
import { Typography, SpacerVertical } from '@klarna/bubble-ui'
import ChannelSelector from './ChannelSelector'

const ChannelSelectorContainer = () => {
  const t = hooks.useTranslator()
  return (
    <div>
      <Typography textToken='text-style/headings/titles/bold/tertiary'>
        {t('help-tool.marketSelector.whichMarketToContact')}
      </Typography>
      <SpacerVertical spaceToken='space/200' />
      <ChannelSelector />
    </div>
  )
}

export default ChannelSelectorContainer
