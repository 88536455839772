import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
const FlexWithEllipsis = styled.div(({ maxWidth }) => {
    return css({
        display: 'flex',
        flexDirection: 'column',
        maxWidth,
        span: {
            width: '100%',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    });
});
const TextValueWithEllipsis = ({ content, overflowEllipsisWidth, title, subtitle }) => (_jsxs(FlexWithEllipsis, { maxWidth: overflowEllipsisWidth, children: [_jsx(Typography, { textToken: "text-style/text/labels/default/regular", colorToken: "colors/text/neutral", children: title }), _jsx(SpacerVertical, { spaceToken: "space/50" }), _jsx(Typography, { textToken: "text-style/text/labels/body/medium", children: content }), _jsx(SpacerVertical, { spaceToken: "space/50" }), _jsx(Typography, { textToken: "text-style/text/labels/default/regular", colorToken: "colors/text/neutral", children: subtitle })] }));
TextValueWithEllipsis.propTypes = {
    content: PropTypes.string,
    overflowEllipsisWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    subtitle: PropTypes.string
};
export default TextValueWithEllipsis;
