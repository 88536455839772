/* eslint-disable no-undef */
import React, { createContext, useContext, useState } from 'react'
import { useChannelDataState } from './ChannelDataStateProvider'

export type ChannelSelectorState = {
  getIsChatLoading: (market: string) => boolean,
  setChatIsLoading: (market: string) => void,
  resetIsChatLoading: () => void
}

type ChannelSelectorStateProps = {
  children: React.JSX.Element
}

export const ChannelSelectorContext = createContext<ChannelSelectorState | undefined>(undefined)

export const ChannelSelectorProvider = ({
  children
}: ChannelSelectorStateProps): React.JSX.Element | null => {
  const { data: { markets } } = useChannelDataState()

  const initialState = new Map<string, boolean>(markets.map(m => ([m.countryCode, false])))

  const [isChatLoadingByMarket, setIsChatLoadingByMarket] = useState(initialState)
  const getIsChatLoading = (market: string): boolean => isChatLoadingByMarket.get(market) || false

  const setChatIsLoading = (market: string): void => {
    isChatLoadingByMarket.set(market, true)
    setIsChatLoadingByMarket(new Map(isChatLoadingByMarket))
  }

  const resetIsChatLoading = () => {
    setIsChatLoadingByMarket(new Map(initialState))
  }

  const stateManager: ChannelSelectorState = {
    getIsChatLoading,
    setChatIsLoading,
    resetIsChatLoading
  }

  return (
    <ChannelSelectorContext.Provider value={stateManager}>
      {children}
    </ChannelSelectorContext.Provider>
  )
}

export const useChannelSelectorState = (): ChannelSelectorState => {
  const value = useContext(ChannelSelectorContext)

  if (!value) throw new Error('cannot get ChannelSelectorProvider state manager as not been provided')

  return value
}
