import { jsx as _jsx } from "react/jsx-runtime";
import { bool, node } from 'prop-types';
import React, { useContext, useState } from 'react';
const AuthenticationContext = React.createContext({});
AuthenticationContextProvider.propTypes = {
    initialAuthenticationRequired: bool,
    children: node
};
function AuthenticationContextProvider({ initialAuthenticationRequired = false, children }) {
    const [isAuthenticationRequired, setAuthenticationRequired] = useState(initialAuthenticationRequired);
    return (_jsx(AuthenticationContext.Provider, { value: { isAuthenticationRequired, setAuthenticationRequired }, children: children }));
}
function useAuthenticationContext() {
    return useContext(AuthenticationContext);
}
export default AuthenticationContext;
export { AuthenticationContextProvider, useAuthenticationContext };
