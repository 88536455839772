import React from 'react'
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage'
import { AnswerOption, BotNodeId, HelpbotFlow } from '../../../types'
import { INeedMoreHelpThatHelped } from '../../common'

export const entrypoint: AnswerOption = {
  value: 'helpbot.orders.orderModification',
  next: BotNodeId.ORDER_MODIFICATIONS_AND_ISSUES
}

export const flow: HelpbotFlow = [
  {
    id: BotNodeId.ORDER_MODIFICATIONS_AND_ISSUES,
    botMessage: null,
    options: () => [
      {
        value: 'helpbot.orders.orderManagement.unableToTakeActions.question',
        next: BotNodeId.ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS
      },
      {
        value: 'helpbot.orders.orderManagement.canIUpdateCustomerInfo.question',
        next: BotNodeId.ORDER_MANAGEMENT_CAN_I_UPDATE_CUSTOMER_INFO
      },
      {
        value:
          'helpbot.orders.orderManagement.extendExpiryReservation.question',
        next: BotNodeId.ORDER_MANAGEMENT_EXTEND_EXPIRY_RESERVATION
      },
      {
        value: 'helpbot.orders.orderManagement.extendDueDate.question',
        next: BotNodeId.ORDER_MANAGEMENT_EXTEND_DUE_DATE
      }
    ]
  },
  {
    id: BotNodeId.ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.orders.orderManagement.selected' />
    ),
    options: () => [
      {
        value: 'helpbot.orders.orderManagement.unableToTakeActions.directPartner.question',
        next: BotNodeId.ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DIRECT_PARTNER
      },
      {
        value: 'helpbot.orders.orderManagement.unableToTakeActions.distributionPartner.question',
        next: BotNodeId.ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DISTRIBUTION_PARTNER
      }
    ]
  },
  {
    id: BotNodeId.ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DIRECT_PARTNER,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.orders.orderManagement.unableToTakeActions.directPartner.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DISTRIBUTION_PARTNER,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.orders.orderManagement.unableToTakeActions.distributionPartner.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.ORDER_MANAGEMENT_CAN_I_UPDATE_CUSTOMER_INFO,
    botMessage: () => <DefaultBotMessage translationKey='helpbot.orders.orderManagement.canIUpdateCustomerInfo.answer' />,
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.ORDER_MANAGEMENT_EXTEND_EXPIRY_RESERVATION,
    botMessage: () => (
      <DefaultBotMessage translationKey='helpbot.orders.orderManagement.extendExpiryReservation.answer' />
    ),
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.ORDER_MANAGEMENT_EXTEND_DUE_DATE,
    botMessage: () => <DefaultBotMessage translationKey='helpbot.orders.orderManagement.extendDueDate.answer' />,
    options: () => INeedMoreHelpThatHelped
  }
]
