import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@klarna/bubble-ui';
import styled from 'styled-components';
export const NotificationTitle = ({ children }) => (_jsx(_Fragment, { children: _jsx(Typography, { textToken: "text-style/text/paragraphs/body/bold", children: children }) }));
export const CenteredContent = styled.div `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
