import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DesignVersionProvider, ParticleDesignVersions, Tooltip } from '@klarna/bubble-ui';
import { EnvironmentId } from '@merchant-portal/framework';
import { constants as commonConstants } from 'mage-common';
import { selectors as configSelectors } from 'mage-config';
import { translator } from 'mage-i18n';
import { useSelector } from 'react-redux';
import CMSEnvironmentSelector from './CMSEnvironmentSelector';
import { ThemeSelector } from './UIThemeSelector';
const { ENVS } = commonConstants;
const tooltipStyle = {
    display: 'none',
    justifyContent: 'center',
    position: 'absolute',
    top: '25px',
    left: 'calc(50% - 150px)',
    minWidth: '300px',
    zIndex: 106
};
function toggleTooltip(evt) {
    evt.stopPropagation();
    const tooltip = document.getElementById('tooltip');
    if (tooltip) {
        tooltip.style.display = tooltip.style.display === 'none' ? 'flex' : 'none';
    }
}
const EnvironmentIdentifier = () => {
    const environment = useSelector(configSelectors.environment);
    const t = useSelector(translator);
    if (environment === ENVS.PRODUCTION || !Object.values(ENVS).includes(environment))
        return null;
    const devTooltip = (_jsx("div", { id: "tooltip", style: tooltipStyle, children: _jsxs(Tooltip, { inverse: true, isNotText: true, arrow: "top", children: [_jsx(ThemeSelector, {}), _jsx("hr", { width: "100%" }), _jsx(CMSEnvironmentSelector, {})] }) }));
    return (_jsxs(DesignVersionProvider, { value: ParticleDesignVersions.PANCAKE_LIGHT, children: [_jsx(EnvironmentId, { id: "mpui-environment-id-wrapper", env: environment, label: t(`config.environments.${environment}`), onClick: toggleTooltip }), [ENVS.STAGING, ENVS.DEVELOPMENT].includes(environment) && devTooltip] }));
};
export default EnvironmentIdentifier;
