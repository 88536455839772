import useCMSEntries from './useCMSEntries';
export default function useCMSEntry({ contentType, sortField, filters, include = 1, skip = false }) {
    const cmsEntries = useCMSEntries({
        contentType,
        sortField,
        filters,
        include,
        skip
    });
    const cmsEntry = cmsEntries && cmsEntries[0];
    return cmsEntry || null;
}
