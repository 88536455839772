import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColoredIllustrationRocket, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { useResponsiveContext } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import PopOverWithHighlight from './PopOverWithHighlight';
import useFeatureHighlight from './useFeatureHighlight';
const FEATURE_BOOSTERS_HIGHLIGHT = 'mp:featuresShown:tabs:boosters';
const DELAY_TO_SHOW_MS = 1000;
const ANALYTICS_NAME = 'highlight/tabs/boosters';
export default function PopoverBoostersInfo({ boostersTabRef }) {
    const { isMobile } = useResponsiveContext();
    const t = i18nHooks.useTranslator();
    const [mustShowPopOver, closePopOver] = useFeatureHighlight(FEATURE_BOOSTERS_HIGHLIGHT, DELAY_TO_SHOW_MS, ANALYTICS_NAME);
    return (_jsxs(PopOverWithHighlight, { showPopOver: mustShowPopOver, popOverOnClose: closePopOver, componentRef: boostersTabRef, position: isMobile ? 'bottom' : 'right', children: [_jsx(ColoredIllustrationRocket, { size: 50 }), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(Typography, { textToken: "text-style/text/paragraphs/body/bold", children: t('framework.popOvers.boostersTab.title') }), _jsx(Typography, { textToken: "text-style/text/paragraphs/default/regular", children: t('framework.popOvers.boostersTab.content') }), _jsx(SpacerVertical, { spaceToken: "space/200" })] }));
}
