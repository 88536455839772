import React from 'react'
import { SelectorDirect, Loader } from '@klarna/bubble-ui'
import { hooks } from 'mage-i18n'

import useContactChannels from '../../hooks/useContactChannels'
import useIsChatActive from '../../hooks/useIsChatActive'
import { useChannelSelectorState } from '../../context/ChannelSelectorState'

export type Channel = 'ticket' | 'chat'

type ChannelOption = {
  value: Channel,
  label: string
}

type Props = {
  marketCountryCode: string
}

const generateOption = (channel: Channel, t) => ({
  value: channel,
  label: t(`helpbot.contact.${channel}`)
})

const getChannelOptions = (isChatActive: boolean, t): ChannelOption[] => {
  const options: ChannelOption[] = []
  if (isChatActive) {
    options.push(generateOption('chat', t))
  } else {
    options.push(generateOption('ticket', t))
  }

  return options
}

const MarketChannels = ({ marketCountryCode }: Props) => {
  const t = hooks.useTranslator()
  const { navigateToCreateTicketPage, startChatConversation } = useContactChannels()
  const { getIsChatLoading, setChatIsLoading } = useChannelSelectorState()
  const chatFeatures = useIsChatActive()
  const isChatActive = chatFeatures.getIsChatActive(marketCountryCode)
  const options = getChannelOptions(isChatActive, t)

  const handleOnSelect = (channel: Channel) => {
    if (channel === 'chat') {
      startChatConversation(marketCountryCode)
      setChatIsLoading(marketCountryCode)
      return
    }
    navigateToCreateTicketPage()
  }

  return (
    <>
      {getIsChatLoading(marketCountryCode)
        ? <Loader />
        // @ts-ignore: FIXME
        : <SelectorDirect onSelect={handleOnSelect} options={options} />}
    </>
  )
}

export default MarketChannels
