"use strict";
const R = require('ramda');
const euConfig = require('./eu');
const playgroundConfig = require('./playground');
const clusterConfig = {
    apps: {
        'lending-fe': {
            enabled: true
        }
    }
};
module.exports = R.pipe(R.mergeDeepLeft(playgroundConfig), R.mergeDeepLeft(clusterConfig))(euConfig);
