import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * This component is deprecated!
 * It's been copied from bubble-contrib to unblock the bump to bubble-ui v3,
 * but center alignment is not encouraged by the design system
 * and we will move away from using it altogether.
 */
import { ButtonPrimary, ButtonQuaternary, ButtonSecondary, ButtonTertiary, SpacerVertical, Typography, View } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
const styles = {
    mainStyle: {
        flexDirection: 'column'
    },
    illustrationWrapperStyle: {
        paddingBottom: 20,
        alignSelf: 'center'
    },
    addressStyle: {
        paddingTop: 20,
        paddingBottom: 20
    },
    definitionStyle: {
        paddingTop: 25,
        paddingBottom: 30
    },
    buttonStyle: {
        paddingTop: 20
    },
    contentStyle: {
        alignItems: 'center',
        paddingTop: 30
    },
    legalWrapperStyle: {
        alignSelf: 'center',
        maxWidth: '100%'
    },
    footnoteWrapperStyle: {
        alignSelf: 'center'
    },
    generalErrorStyle: {
        marginTop: 20,
        width: '100%'
    }
};
const Button = {
    Primary: ButtonPrimary,
    Secondary: ButtonSecondary,
    Tertiary: ButtonTertiary,
    Quaternary: ButtonQuaternary
};
const getButton = ({ content, type, props }, index) => {
    const ButtonType = Button[type];
    return (_jsx(ButtonType, Object.assign({}, props, { children: content }), index));
};
function CenteredContent({ buttons, children, definition, footnote, illustration, summary, title }) {
    return (_jsxs(View, { style: styles.mainStyle, children: [illustration && _jsx(View, { style: styles.illustrationWrapperStyle, children: illustration }), _jsxs("div", { children: [_jsx(Typography, { textToken: "text-style/headings/titles/bold/tertiary", accessibilityPreset: "heading 1", textAlignToken: "text-alignment/center", children: title }), _jsx(SpacerVertical, { spaceToken: "space/50" }), _jsx(Typography, { textToken: "text-style/text/paragraphs/body/regular", accessibilityPreset: "paragraph", textAlignToken: "text-alignment/center", children: summary }), _jsx(SpacerVertical, { spaceToken: "space/100" })] }), definition && (_jsxs(View, { style: styles.definitionStyle, children: [_jsx(Typography, { textToken: "text-style/text/labels/default/regular", colorToken: "colors/text/neutral", textAlignToken: "text-alignment/center", children: definition.title }), _jsx(SpacerVertical, { spaceToken: "space/50" }), _jsx(Typography, { textToken: "text-style/text/paragraphs/body/regular", textAlignToken: "text-alignment/center", children: definition.content }), _jsx(SpacerVertical, { spaceToken: "space/100" })] })), children && _jsx(View, { style: styles.contentStyle, children: children }), footnote && (_jsx(View, { style: styles.footnoteWrapperStyle, children: _jsx(Typography, { textToken: "text-style/text/paragraphs/body/regular", textAlignToken: "text-alignment/center", children: footnote }) })), buttons &&
                buttons.map((button, index) => (_jsx(View, { style: styles.buttonStyle, children: getButton(button, index) }, index)))] }));
}
CenteredContent.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        type: PropTypes.string,
        props: PropTypes.object
    })),
    definition: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string
    }),
    footnote: PropTypes.node,
    illustration: PropTypes.node,
    summary: PropTypes.node,
    title: PropTypes.string,
    children: PropTypes.node
};
export default CenteredContent;
