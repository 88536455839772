import React from 'react'
import { BotNodeId, HelpbotFlow } from '../../../types'
import { INeedMoreHelpThatHelped } from '../../common'
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage'

const payoutScheduleFlow: HelpbotFlow = [
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT_SCHEDULE,
    botMessage: null,
    options: () => [
      {
        value: 'helpbot.settlements.payoutSchedule.whyDelay.question',
        next: BotNodeId.SETTLEMENTS_PAYOUT_SCHEDULE_WHY_DELAY
      },
      {
        value: 'helpbot.settlements.payoutSchedule.changePayoutDelay.question',
        next: BotNodeId.SETTLEMENTS_PAYOUT_SCHEDULE_CHANGE_PAYOUT_DELAY
      }
    ]
  },
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT_SCHEDULE_WHY_DELAY,
    botMessage: () => <DefaultBotMessage translationKey='helpbot.settlements.payoutSchedule.whyDelay.answer' />,
    options: () => INeedMoreHelpThatHelped
  },
  {
    id: BotNodeId.SETTLEMENTS_PAYOUT_SCHEDULE_CHANGE_PAYOUT_DELAY,
    botMessage: () => <DefaultBotMessage translationKey='helpbot.settlements.payoutSchedule.changePayoutDelay.answer' />,
    options: () => INeedMoreHelpThatHelped
  }
]

export default payoutScheduleFlow
