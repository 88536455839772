import { Field, Link, SpacerVertical } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
import useOrigins from '../useOrigins';
const Container = styled.div `
  position: relative;
`;
const TooltipContent = ({ t }) => (React.createElement(React.Fragment, null,
    t('credentials.originField.tooltipMainText'),
    React.createElement(SpacerVertical, { spaceToken: 'space/100' }),
    React.createElement(Link, { colorToken: 'colors/text/inverse', isUnderlined: true, href: '/settings/credentials/client-keys', rel: 'noopener noreferrer' }, t('credentials.originField.tooltipLinkLabel'))));
function Core({ origin, error = null }) {
    var _a;
    const t = i18nHooks.useTranslator();
    const LABEL = t('credentials.originField.label');
    const DEFAULT_ERROR_MESSAGE = t('credentials.originField.defaultErrorMessage');
    return (React.createElement(Container, null,
        React.createElement(Field, { label: error ? `${LABEL} - ${(_a = error.message) !== null && _a !== void 0 ? _a : DEFAULT_ERROR_MESSAGE}` : LABEL, arrow: 'top-right', value: origin, fieldTooltip: React.createElement(TooltipContent, { t: t }), error: !!error })));
}
function WithOrigin({ origin }) {
    return React.createElement(Core, { origin: origin });
}
function WithMid({ mid, region }) {
    const { origins, error } = useOrigins({ mid, region });
    return React.createElement(Core, { origin: origins[0], error: error });
}
// this is a wrapper component around Core to avoid using hooks inside conditionals
export default function OriginField({ mid, region, origin: passedOrigin }) {
    if (passedOrigin) {
        return React.createElement(WithOrigin, { origin: passedOrigin });
    }
    else if (mid) {
        return React.createElement(WithMid, { mid: mid, region: region });
    }
    return React.createElement(Core, { origin: '' });
}
