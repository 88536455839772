import { createStorage } from 'mage-common';
import { useEffect } from 'react';
export default function useLocalStorage(key, value, setValue) {
    const localStorage = createStorage();
    useEffect(() => {
        const item = localStorage.get(key);
        if (item) {
            setValue(item);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        localStorage.set(key, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
}
