import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as s from '../selectors';
const Currency = ({ format, currency, value, id, hideDecimals }) => (_jsx("span", { id: id, "data-value": value, children: format(currency, hideDecimals).format(value) }));
Currency.propTypes = {
    currency: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired,
    hideDecimals: PropTypes.bool,
    id: PropTypes.string
};
export default connect(state => ({ format: s.formatters.currency(state) }))(Currency);
