import { useAnalytics } from 'mage-insights';
import { useEffect } from 'react';
export default function useShouldShowTabs(items) {
    const shouldShowTabs = Boolean(items.length > 1);
    const trackTabsHiddenImpression = useAnalytics({
        category: 'v3/framework/tabs-section-hidden',
        action: 'impression'
    });
    useEffect(() => {
        if (shouldShowTabs)
            return;
        trackTabsHiddenImpression();
    }, [shouldShowTabs, trackTabsHiddenImpression]);
    return { shouldShowTabs };
}
