import { useHelpContext } from './'
import { initiateChat } from 'mage-conversation'
import { StartConversationContext } from 'mage-conversation/src/types'
import { useChannelDataState } from '../context/ChannelDataStateProvider'
import { HelpHook } from '../types'
import { useChannelSelectorState } from '../context/ChannelSelectorState'
import { gatherCallbacks } from '../utilities/callback'
import { generateSidebarController } from '../utilities/sidebarController'
import { useChatContext } from './useChatContext'
import { useRefreshClientGenerator } from './useRefreshClientGenerator'
import useIsChatActive from './useIsChatActive'
import { hooks as i18nHooks } from 'mage-i18n'

const useContactChannels = () => {
  useRefreshClientGenerator()
  // @ts-ignore
  const helpHook: HelpHook = useHelpContext()
  // @ts-ignore
  const { toggleWidget } = useHelpContext()
  const { data: channelData } = useChannelDataState()
  const { resetIsChatLoading } = useChannelSelectorState()
  const chatFeatures = useIsChatActive()
  const navigateToCreateTicketPage = () => {
    helpHook.navigation.push('support')
  }
  const sidebarController = generateSidebarController(toggleWidget)
  const context = useChatContext()
  // @ts-ignore
  const language = i18nHooks.useLanguage()

  const closeViewport = () => sidebarController(false)

  const getChatContext = (marketCode: string): StartConversationContext => {
    const region = chatFeatures.getWorkingHoursRegion(marketCode)
    const restoreIdByRegion = channelData.restoreId
    const onChatWidgetOpens = gatherCallbacks([closeViewport, resetIsChatLoading])
    const tag = channelData.markets.find((market) => market.countryCode === marketCode)?.tag

    return {
      ...context,
      region,
      market: marketCode,
      restoreIdByRegion,
      onChatWidgetOpens,
      language,
      tag,
      chatProvider: 'FRESHCHAT'
    }
  }

  const startChatConversation = (marketCode: string) =>
    initiateChat(getChatContext(marketCode))

  return {
    navigateToCreateTicketPage,
    startChatConversation
  }
}

export default useContactChannels
