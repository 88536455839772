import React, { CSSProperties, ReactNode } from 'react'

interface ChatWidgetContainerProps {
    children: ReactNode
}

const styles: { chatWidgetContainer: CSSProperties } = {
  chatWidgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '20vw',
    minWidth: '400px',
    height: '30vw',
    minHeight: '600px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '7%',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    overflow: 'auto'
  }
}

const ChatWidgetContainer: React.FC<ChatWidgetContainerProps> = ({ children }) => {
  return <div style={styles.chatWidgetContainer}>{children}</div>
}

export default ChatWidgetContainer
