import React from 'react'
import { ChannelDataStateProvider } from '../../../../context/ChannelDataStateProvider'
import { ChannelSelectorProvider } from '../../../../context/ChannelSelectorState'
import MarketSelectorContainer from './MarketSelectorContainer'

const MarketSelector = () => (
  <ChannelDataStateProvider>
    <ChannelSelectorProvider>
      <MarketSelectorContainer />
    </ChannelSelectorProvider>
  </ChannelDataStateProvider>
)

export default MarketSelector
