import { BotNodeId, HelpbotFlow } from '../../../types'
import disputesHandling from './disputesHandling'
import disputesLifecycle from './disputesLifecycle'
import merchantDisputesApp from './merchantDisputesApp'

const disputesFlow: HelpbotFlow = [
  {
    id: BotNodeId.DISPUTES,
    botMessage: null,
    options: () => [
      {
        value: 'helpbot.disputes.merchantDisputesApp.title',
        next: BotNodeId.MERCHANT_DISPUTES_APP
      },
      {
        value: 'helpbot.disputes.disputesLifecycle.title',
        next: BotNodeId.DISPUTES_LIFECYCLE
      },
      {
        value: 'helpbot.disputes.disputesHandling.title',
        next: BotNodeId.DISPUTES_HANDLING
      }
    ]
  },
  ...merchantDisputesApp,
  ...disputesLifecycle,
  ...disputesHandling
]

export default disputesFlow
