import React from 'react'
import { ChatBubble } from '@klarna/bubble-ui'

interface HelpbotQuestionProps {
  Message: React.ElementType
}

type Styles = {
  chatBubble: any,
  chatAuthor: any
}

const styles: Styles = {
  chatBubble: {
    marginBottom: '10px'
  },
  chatAuthor: {
    margin: '0px',
    marginBottom: '5px',
    fontFamily: 'Klarna Text',
    fontStyle: 'normal',
    color: '#787573',
    fontSize: '12px',
    fontWeight: 400
  }
}

const HelpbotQuestion: React.FC<HelpbotQuestionProps> = ({ Message }: HelpbotQuestionProps) => {
  return (
    <div style={styles.chatBubble}>
      <p style={styles.chatAuthor}>Klarna</p>
      <ChatBubble
        direction='left'
        color='gray'
        maxWidth={270}
      >
        <Message />
      </ChatBubble>

    </div>

  )
}

export { HelpbotQuestion }
