import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import EnvironmentIdentifier from '../EnvironmentIdentifier';
const Public = ({ App, history, location, footerConfig, t, clientId, Framework }) => {
    useEffect(() => {
        const title = t(`core.apps.${clientId}.title`);
        if (title) {
            document.title = `${title} | Klarna Merchant Portal`;
        }
    }, [t, clientId]);
    return (_jsxs(ConnectedRouter, { history: history, children: [_jsx(EnvironmentIdentifier, {}), _jsx(Framework, { footerConfig: Object.assign(Object.assign({}, footerConfig), { text: footerConfig.text }), children: _jsx(App, { location: location }) })] }));
};
Public.propTypes = {
    App: PropTypes.any,
    history: PropTypes.object,
    location: PropTypes.object,
    footerConfig: PropTypes.object,
    t: PropTypes.func.isRequired,
    clientId: PropTypes.string,
    Framework: PropTypes.any
};
export default Public;
