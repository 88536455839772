import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionIconClose, ButtonRoundQuaternary } from '@klarna/bubble-ui';
import { Portal, useGetToken } from '@klarna/mp-ui';
import { CloseButtonContainer, PopoverContainer } from './PopoverStyled';
export const Popover = ({ componentRef, onClose, children, padding, position }) => {
    var _a;
    const refCoordinates = ((_a = componentRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || { top: 0, left: 0 };
    const backgroundColor = useGetToken('colors/backgrounds/default').toString();
    return (_jsx(Portal, { children: _jsxs(PopoverContainer, { backgroundColor: backgroundColor, refCoordinates: refCoordinates, "data-testid": "popover-container", padding: padding, position: position, children: [children, _jsx(CloseButtonContainer, { children: _jsx(ButtonRoundQuaternary, { onClick: onClose, size: "small", UNSAFE_touchableProps: { 'data-testid': 'popover-close-button' }, children: _jsx(ActionIconClose, {}) }) })] }) }));
};
