import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@klarna/bubble-ui';
import { useResponsiveContext } from '@klarna/mp-ui';
import { useCMSEnvironment } from 'mage-cms';
import { cmsActions } from 'mage-state';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
const options = [
    {
        value: '',
        hidden: true
    },
    {
        value: 'master'
    },
    {
        value: 'editor'
    },
    {
        value: 'dev'
    },
    {
        value: 'test'
    }
];
function CMSEnvironmentSelector() {
    const dispatch = useDispatch();
    const cmsEnvironment = useCMSEnvironment();
    const { isMobile } = useResponsiveContext();
    if (!cmsEnvironment)
        return null;
    const label = isMobile ? 'CMS' : 'CMS Environment';
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { textToken: "text-style/text/paragraphs/body/regular", children: label }), _jsx(Select, { value: cmsEnvironment, options: options, onChange: onChange })] }));
    function onChange(value) {
        dispatch(cmsActions.setCMSEnvironment(value));
    }
}
export default CMSEnvironmentSelector;
Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};
function Select({ options, value, onChange }) {
    return (_jsx("select", { id: "cms-environment-selector", style: { height: 30 }, value: value, onChange: event => onChange(event.target.value), children: options.map(option => (_jsx("option", { value: option.value, hidden: option.hidden, children: option.value }, option.value))) }));
}
